import styled, {css} from 'styled-components'

/**
 * Horizontal divider.
 *
 * If the Divider's parent has a horizontal padding and you want the divider to
 * cover the padding, provide the padding value to the `compensatePadding` prop.
 *
 * @example
 * <Parent padding={spacing.large}>
 *   <SomeContent />
 *   <Divider compensatePadding={spacing.large} />
 *   <SomeOtherContent />
 * </Parent>
 *
 */
const Divider = styled.hr<{compensatePadding?: string; color?: string}>`
  background-color: ${({theme, color}) => color || theme.color.background.mediumSecondary};
  border: none;
  height: 1px;
  margin: 0;
  ${({compensatePadding}) =>
    compensatePadding &&
    css`
      margin-left: -${compensatePadding};
      margin-right: -${compensatePadding};
    `}
`

export default Divider
