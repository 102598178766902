import {Text} from '@s-group/design-system-components'
import styled from 'styled-components'

export const WelcomeRecommendationsWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.small};
  padding: ${({theme}) => theme.spacingCustom.xxsmall};
`

export const WelcomeRecommendationsHeading = styled(Text)`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.small};
`
