import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {useGetCommaSeparatedDietTagsAbbreviations} from 'Components/Product/productCardUtils'
import i18n from 'i18n'
import {defineLabelExtraInfo} from 'utils/portionPriceUtils'
import CheckBoxButton from './CheckBoxButton'
import RadioButton from './RadioButton'
import {CheckboxGroupWrapper, RadioButtonGroupWrapper} from './SelectionBoxStyles'
import {SelectionBoxGroupProps} from './SelectionBoxTypes'

const SelectionBoxGroup = ({
  options,
  optionSectionId,
  selectedIds,
  onChange,
  portionId,
  isSize = false,
  buttonType,
}: SelectionBoxGroupProps) => {
  const locale = i18n.language
  const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()

  if (buttonType === 'radio') {
    return (
      <RadioButtonGroupWrapper>
        {options.map(({id: idArg, label: labelArg, price, diets, disabled}) => {
          const {normal, type: priceType} = price || {}
          const label = getLocalized(labelArg, locale)
          const labelExtraInfo = defineLabelExtraInfo(isSize, normal, priceType, getDietTags(diets))
          return (
            <RadioButton
              key={idArg}
              id={idArg}
              name={optionSectionId}
              value={idArg}
              portionId={portionId}
              label={label}
              labelExtraInfo={labelExtraInfo}
              onChange={onChange}
              checked={!!selectedIds?.find((id) => id === idArg)}
              disabled={disabled}
              coopMemberPrice={price?.coopMember}
              priceType={priceType}
            />
          )
        })}
      </RadioButtonGroupWrapper>
    )
  }

  return (
    <CheckboxGroupWrapper>
      {options.map(({id: idArg, label: labelArg, price, diets, disabled}) => {
        const {normal, type: priceType} = price || {}
        const label = getLocalized(labelArg, locale)
        const labelExtraInfo = defineLabelExtraInfo(isSize, normal, priceType, getDietTags(diets))
        return (
          <CheckBoxButton
            key={idArg}
            id={idArg}
            name={idArg}
            value={idArg}
            portionId={portionId}
            label={label}
            labelExtraInfo={labelExtraInfo}
            onChange={onChange}
            checked={!!selectedIds?.find((id) => id === idArg)}
            disabled={disabled}
            coopMemberPrice={price?.coopMember}
            priceType={priceType}
          />
        )
      })}
    </CheckboxGroupWrapper>
  )
}

export default SelectionBoxGroup
