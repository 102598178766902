import {useGetTableByOpenTokenLazyQuery} from '@marketplace/shared-lib/graphql/graphql'
import {areGlobalTableActionsEnabled} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Button, FormRow, Notification, Text} from '@s-group/design-system-components'
import Divider from 'Components/Divider/Divider'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import {SiteInfo} from 'Components/SiteInfo'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import type {RestaurantContextProps} from 'state/RestaurantContext'
import {spacingCustom} from 'theme'
import {remoteOrderRoomNumber} from 'utils/constants'
import {ContentWrapper, PromptContent} from './RestaurantMainContentStyles'
import {PromptForm, RoomNumberHelperText, RoomNumberInput, RoomNumberLabel} from './RoomStyles'

type RoomNumberPromptProps = {
  /** token for open room; type OPEN_ROOM */
  openToken: string
  restaurantFeatures: NonNullable<RestaurantContextProps['restaurantFeatures']>
}

/**
 * Prompt to enter room number.
 *
 * This should be shown when the table type is OPEN_ROOM, that is, after user
 * has scanned a generic room QR code
 */
const RoomNumberPrompt = ({openToken, restaurantFeatures}: RoomNumberPromptProps) => {
  const {t} = useTranslation('remotePage')
  const navigate = useNavigate()
  const {tableActions, tableActionsAutomatic, roomService, lastCallBuffer} = restaurantFeatures
  const {restaurantData, restaurantLoading} = useRestaurantContext()

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions,
    tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer,
  })

  const orderingEnabled = tableActionsEnabled && (roomService.deliveryEnabled || roomService.pickupEnabled)

  const savedRoomNumber = (sessionStorage.getItem(remoteOrderRoomNumber) || '').trim()
  const [roomNumber, setRoomNumber] = useState<string>(savedRoomNumber)

  const [getTableByOpenTokenFunction, {data, error, loading}] = useGetTableByOpenTokenLazyQuery()

  // use the retrieved token to navigate to the correct screen
  useEffect(() => {
    if (openToken && savedRoomNumber) {
      getTableByOpenTokenFunction({
        variables: {
          token: openToken,
          tableID: savedRoomNumber,
        },
      })
    }

    if (data?.getTableByOpenToken.token) {
      navigate(`/${data.getTableByOpenToken.token}`, {replace: true})
    }
  }, [openToken, getTableByOpenTokenFunction, savedRoomNumber, navigate, data?.getTableByOpenToken.token])

  // restaurantData and openToken should be defined here, but let's check for completeness and type-safety
  if (loading || restaurantLoading || !restaurantData || !openToken) {
    return <LoadingScreen />
  }

  // get table token when user submits room number
  const onSubmit = async () => {
    await getTableByOpenTokenFunction({
      variables: {
        token: openToken,
        tableID: roomNumber.trim(),
      },
    })
    sessionStorage.setItem(remoteOrderRoomNumber, roomNumber.trim())
  }

  return (
    <ContentWrapper>
      <SiteInfo restaurantData={restaurantData} subheaderOverride={t('roomServiceSubheader')} />
      <PromptContent>
        {orderingEnabled && (
          <>
            <Text variant='body' sizing='small'>
              {t('roomServiceDescription')}
            </Text>
            <Divider compensatePadding={spacingCustom.medium} />
            <PromptForm>
              <FormRow sizing='small'>
                <RoomNumberLabel sizing='small'>{t('roomNumberLabel')} </RoomNumberLabel>
                <RoomNumberInput
                  data-testid='room-number-input'
                  required
                  sizing='small'
                  value={roomNumber}
                  onChange={(e) => setRoomNumber(e.target.value)}
                />
                {error && (
                  <RoomNumberHelperText sizing='small' error>
                    {t('roomNotFoundError')}
                  </RoomNumberHelperText>
                )}
              </FormRow>
              <Button
                color='neutral'
                variant='filled'
                sizing='small'
                rounding='small'
                onClick={onSubmit}
                data-testid='room-number-submit-button'
                disabled={loading}
              >
                {t('continue')}
              </Button>
            </PromptForm>
          </>
        )}
        {!orderingEnabled && (
          <Notification status='warning' variant='tonal' sizing='small' alignment='left' close={false}>
            {t('roomServiceDisabled')}
          </Notification>
        )}
      </PromptContent>
    </ContentWrapper>
  )
}

export default RoomNumberPrompt
