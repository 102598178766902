import {Text} from '@s-group/design-system-components'
import {useTranslation} from 'react-i18next'
import {Main} from 'styles/GeneralStyles'
import {color} from 'theme'
import {IconOuterWrapper, IconWrapper, NoMarginList, TextWrapper} from './404Styles'

export const view404TestId = 'current-page-not-found'

const View404 = (): JSX.Element => {
  const {t} = useTranslation('remotePage')

  return (
    <Main backgroundColor={color.background.strongerGrey} centerContent>
      <IconOuterWrapper>
        <IconWrapper>🤷</IconWrapper>
      </IconOuterWrapper>
      <TextWrapper>
        <Text variant='display' sizing='xxxxsmall' weight='semibold' data-testid={view404TestId}>
          {t('currentPageNotFound')}
        </Text>
        <Text variant='body'>
          {t('causeMightBe')}
          <NoMarginList>
            <li>{t('qrCodeFailure')}</li>
            <li>{t('qrCodeMissing')}</li>
          </NoMarginList>
        </Text>
      </TextWrapper>
    </Main>
  )
}

export default View404
