import {large} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Form, HelperText, Label, TextInput} from '@s-group/design-system-components'
import styled from 'styled-components'

export const PromptForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${large};
  display: flex;
  width: inherit;
`

export const RoomNumberLabel = styled(Label)`
  text-align: left;
`

export const RoomNumberInput = styled(TextInput)`
  width: -webkit-fill-available;
  margin: ${({theme}) => theme.spacing.xxxsmall} 0 0;
`

export const RoomNumberHelperText = styled(HelperText)`
  text-align: left;
`
