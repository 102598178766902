import {ToastContainer} from 'react-toastify'
import styled from 'styled-components'

export const MainWrapper = styled.div`
  background-color: ${({theme}) => theme.color.black};
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
  text-align: center;
`

export const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
`

export const StyledToast = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    top: auto;
    bottom: 6rem;
    left: 0;
    width: 100vw;
    padding: 0;
    margin: 0;

    .Toastify__toast--warning {
      padding: 0;
      margin: 0 ${({theme}) => theme.spacing.xsmall};
    }
    .Toastify__toast--error {
      padding: 0;
      margin: 0 ${({theme}) => theme.spacing.xsmall};

      > * {
        padding: 0;
      }

      button {
        align-items: baseline;
        padding-top: ${({theme}) => theme.spacing.medium};
        border: 1px solid ${({theme}) => theme.color.border.error};
        border-left: none;
      }
    }
    .Toastify__toast--success {
      padding: 0;
      margin: 0 ${({theme}) => theme.spacing.xsmall};

      > * {
        padding: 0;
      }

      button {
        align-items: baseline;
        padding-top: ${({theme}) => theme.spacing.medium};
        border: 1px solid ${({theme}) => theme.color.border.success};
        border-left: none;
      }
    }
  }
`
