import {makeVar} from '@apollo/client'
import {convertLocalStorageStringToBoolean, readLocalStorage} from '@marketplace/shared-lib/src/utils/localStorage'
import {remoteCartID, remoteCustomerFullAge} from 'utils/constants'

interface cartID {
  [key: string]: string | null
}

const cartID = makeVar<string | null>(localStorage?.getItem(remoteCartID) || null)
const previousCartIDsVar = makeVar<cartID[]>([])
const restaurantSelectionTableTokens = makeVar<string[]>([])
const restaurantID = makeVar<string>('')
const customerFullAge = makeVar<boolean | undefined>(
  convertLocalStorageStringToBoolean(readLocalStorage(remoteCustomerFullAge))
)
const previewMode = makeVar<boolean | undefined>(false)
const topNotificationsHeight = makeVar<number | undefined>(0)
const menuID = makeVar<string>('')
const modalInitiatorRefVar = makeVar<React.RefObject<HTMLElement> | undefined>(undefined)

const setModalInitiatorRefVar = (ref: React.RefObject<HTMLElement>) => modalInitiatorRefVar(ref)

export {
  cartID,
  customerFullAge,
  menuID,
  modalInitiatorRefVar,
  previewMode,
  previousCartIDsVar,
  restaurantID,
  restaurantSelectionTableTokens,
  setModalInitiatorRefVar,
  topNotificationsHeight,
}
