import {Checkbox, CheckboxGroup, Label, RadioButton, RadioButtonGroup} from '@s-group/design-system-components'
import styled from 'styled-components'

const RadioButtonGroupWrapper = styled(RadioButtonGroup)``
const CheckboxGroupWrapper = styled(CheckboxGroup)``
const RecommendedCheckboxLabel = styled(Label)``

const RadioButtonWrapper = styled(RadioButton)`
  line-height: ${({theme}) => theme.spacing.medium};
  display: flex;

  svg {
    padding-top: ${({theme}) => theme.spacing.xxxxsmall};
  }
`

const RadioButtonLabel = styled.div``

const RadioButtonLabelExtraInfo = styled.div`
  display: float;
  float: right;
`

const CheckboxButtonWrapper = styled(Checkbox)`
  line-height: ${({theme}) => theme.spacing.medium};
  display: flex;

  svg {
    padding-top: ${({theme}) => theme.spacing.xxxxsmall};
  }
`

const CheckboxLabel = styled.div``

const CheckboxLabelExtraInfo = styled.div`
  display: float;
  float: right;
`

export {
  CheckboxButtonWrapper,
  CheckboxGroupWrapper,
  CheckboxLabel,
  CheckboxLabelExtraInfo,
  RadioButtonGroupWrapper,
  RadioButtonLabel,
  RadioButtonLabelExtraInfo,
  RadioButtonWrapper,
  RecommendedCheckboxLabel,
}
