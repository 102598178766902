import {Button} from '@s-group/design-system-components'
import styled from 'styled-components'

const Wrapper = styled.section`
  align-items: center;
  background-color: ${({theme}) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({theme}) => theme.spacingCustom.medium} ${({theme}) => theme.spacing.xsmall};
`

const Ul = styled.ul`
  align-items: flex-start;
  display: flex;
  list-style: none;
  justify-content: space-around;
  margin: 0;
  padding: ${({theme}) => theme.spacing.small} 0;
  padding-bottom: 0;
`

const EmojiButton = styled(Button)`
  font-size: ${({theme}) => theme.spacing.xlarge};
  padding: 0 ${({theme}) => theme.spacing.xxsmall};
  margin: 0;
`

export {EmojiButton, Ul, Wrapper}
