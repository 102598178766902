import {useReactiveVar} from '@apollo/client'
import {useGetTableQuery} from '@marketplace/shared-lib/graphql/graphql'
import {useTable} from '@marketplace/shared-lib/src/hooks'
import {useEffect, useRef} from 'react'
import * as Sentry from '@sentry/react'
import {previewMode, restaurantID} from 'state/reactiveVariables'
import {useToken} from 'utils/cookies'
import {SentryTagKeys} from 'utils/sentryUtils'

// TODO: subscription is not always properly updating tableData / cart after setOrder mutation has been sent
const useTableData = (givenToken?: string) => {
  const useTableDataByToken = (token: string) => {
    const {data, loading, error} = useGetTableQuery({
      variables: {input: {token}},
      skip: !token,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    })
    return {data, loading, error}
  }

  const currentRestaurantID = useReactiveVar(restaurantID)
  const token = useToken()
  const isInvalidToken = !(givenToken || token)
  const isPreviewMode = !!useReactiveVar(previewMode)

  const {data, loading, error} = useTable({
    token: givenToken || token,
    skip: isInvalidToken || isPreviewMode,
  })

  // use ref to keep track of previous restaurant ID and prevent unnecessary updates
  const prevRestaurantIDRef = useRef(currentRestaurantID)

  useEffect(() => {
    if (data?.getTable.restaurantID && data.getTable.restaurantID !== prevRestaurantIDRef.current) {
      restaurantID(data.getTable.restaurantID)
      prevRestaurantIDRef.current = data.getTable.restaurantID
    }

    if (data?.getTable.tableType) {
      Sentry.setTag(SentryTagKeys.TableType, data.getTable.tableType)
    }
  }, [data])

  return {data, loading, error, useTableDataByToken}
}

export default useTableData
