import {useReactiveVar} from '@apollo/client'
import MenuLayout from 'Components/Layout/MenuLayout'
import {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useNavigate, useParams} from 'react-router'
import {customerFullAge, menuID, previewMode, restaurantID} from 'state/reactiveVariables'
import {tokenCookieKey} from 'utils/constants'

/** Component to handle the restaurant and menu id in the URL. */
const RestaurantIDHandling = (): JSX.Element | null => {
  const {restaurantId, menuId} = useParams() || {}
  const [, , removeCookie] = useCookies([tokenCookieKey])
  const navigate = useNavigate()
  const previewModeOn = useReactiveVar(previewMode)

  useEffect(() => {
    if (!restaurantId || !menuId) {
      navigate('/', {replace: true})
    } else {
      previewMode(true)
      customerFullAge(true)
      // remove token cookie incase there is one from the past. this will prevent running getCart query
      removeCookie(tokenCookieKey, {path: '/'})
      restaurantID(restaurantId)
      menuID(menuId)
    }
  }, [navigate, removeCookie, restaurantId, menuId])

  // wait for the reactive variables to update before rendering preview mode view
  if (!previewModeOn) return null

  return <MenuLayout />
}

export default RestaurantIDHandling
