import {useReactiveVar} from '@apollo/client'
import {TableStatusType} from '@marketplace/shared-lib/graphql/graphql'
import {useCallback} from 'react'
import {DataLayerEvent, EventInfo} from 'types/analyticsTypesNew'
import triggerEventNew from 'utils/triggerEventNew'
import {cartID} from '../state/reactiveVariables'
import useRestaurantContext from './useRestaurantContext'

// Hook to be used for sending data to dataLayer
// Automatically enriches the data with relevant information
const useTriggerEvent = () => {
  const {tableData, restaurantData, restaurantFeatures} = useRestaurantContext()
  const currentCartID = useReactiveVar(cartID)
  const isTestTable = tableData?.getTable.isTestTable
  const isTestRestaurant = restaurantData?.getRestaurant.isTestRestaurant

  const callback = useCallback(
    (eventData: EventInfo) => {
      const event: DataLayerEvent = {
        ...eventData,
        restaurant_id: restaurantData?.getRestaurant.id ?? '',
        restaurant_name: restaurantData?.getRestaurant.name.fi ?? '',
        cost_center_id: restaurantData?.getRestaurant.costCenterID ?? '',
        cart_id: currentCartID ?? '',
        table_id: tableData?.getTable.tableID ?? '',
        table_type: tableData?.getTable.tableType ?? '',
        table_status: tableData?.getTable.state.status ?? TableStatusType.IDLE,
        restaurant_features_bill_request: restaurantFeatures?.billRequest ?? true,
        restaurant_features_kitchen_closed: restaurantFeatures?.kitchenClosed ?? false,
        restaurant_features_quick_order: restaurantFeatures?.quickOrder ?? true,
        restaurant_features_room_service_delivery: restaurantFeatures?.roomService.deliveryEnabled ?? false,
        restaurant_features_room_service_pickup: restaurantFeatures?.roomService.pickupEnabled ?? false,
        restaurant_features_rush_hour: restaurantFeatures?.rushHour.enabled ?? false,
        restaurant_features_table_actions: restaurantFeatures?.tableActions ?? true,
        restaurant_features_waiter_call: restaurantFeatures?.waiterCall ?? true,
      }

      if (!isTestTable && !isTestRestaurant) {
        triggerEventNew(event)
      }
    },
    [
      isTestTable,
      isTestRestaurant,
      currentCartID,
      restaurantData?.getRestaurant.costCenterID,
      restaurantData?.getRestaurant.id,
      restaurantData?.getRestaurant.name.fi,
      restaurantFeatures?.billRequest,
      restaurantFeatures?.kitchenClosed,
      restaurantFeatures?.quickOrder,
      restaurantFeatures?.roomService.deliveryEnabled,
      restaurantFeatures?.roomService.pickupEnabled,
      restaurantFeatures?.rushHour.enabled,
      restaurantFeatures?.tableActions,
      restaurantFeatures?.waiterCall,
      tableData?.getTable.tableID,
      tableData?.getTable.tableType,
      tableData?.getTable.state.status,
    ]
  )

  // undefined callback indicates loading state
  if (!tableData || !restaurantData || !currentCartID) {
    return undefined
  }

  return callback
}

export default useTriggerEvent
