import {useReactiveVar} from '@apollo/client'
import {GetCartError, useGetCartQuery} from '@marketplace/shared-lib/graphql/graphql'
import {useNetworkConnection, useVisibilityChange} from '@marketplace/shared-lib/src/hooks'
import {useEffect} from 'react'
import {cartID, previewMode, previousCartIDsVar, restaurantID} from 'state/reactiveVariables'
import {removeCartID, setCartId} from 'utils/cart'

const useCart = () => {
  const id = useReactiveVar(cartID)
  const currentRestaurantID = useReactiveVar(restaurantID)
  const isPreviewMode = useReactiveVar(previewMode)
  const {visibleAfterHidden} = useVisibilityChange(100)
  const {reconnected} = useNetworkConnection(100)

  const previousCartIDs = useReactiveVar(previousCartIDsVar)
  const savedCartID = previousCartIDs.find((cart) => cart[currentRestaurantID])?.[currentRestaurantID]

  const {data, loading, error, refetch} = useGetCartQuery({
    variables: {input: {id: savedCartID || id, restaurantID: currentRestaurantID}},
    skip: !currentRestaurantID || isPreviewMode,
    fetchPolicy: 'cache-and-network',
    onCompleted: (completedData) => {
      if (completedData.getCart.id !== savedCartID || completedData.getCart.id !== id) {
        setCartId(completedData.getCart.id)
      }
      // reset cartID and fetch new if restaurant has changed in between
      if (completedData.getCart.restaurantID !== currentRestaurantID) {
        removeCartID()
      }
    },
    onError: (e) => {
      if (e.message.includes(GetCartError.CART_DOES_NOT_EXIST)) {
        removeCartID()
      }
    },
  })

  //  refetch
  // Initiate when view hidden and visible again or network connection is restored
  useEffect(() => {
    const triggerRefetch = async () => {
      try {
        await refetch()
      } catch {
        // swallow refetch error, handled already
      }
    }
    if (visibleAfterHidden || reconnected) {
      triggerRefetch()
    }
  }, [visibleAfterHidden, refetch, reconnected])

  return {cartData: data, cartLoading: loading, cartError: error}
}

export default useCart
