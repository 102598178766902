import {useGTM} from 'hooks/useGTM'
import {FC, ReactElement} from 'react'
import DefaultHeader from './DefaultHeader/DefaultHeader'
import {HeaderWrapper} from './HeaderStyles'
import OrderNavigation from './OrderNavigation/OrderNavigation'

interface Props {
  isOrderFlow?: boolean
}

const Header: FC<Props> = ({isOrderFlow = false}): ReactElement => {
  useGTM()

  return <HeaderWrapper>{isOrderFlow ? <OrderNavigation /> : <DefaultHeader />}</HeaderWrapper>
}

export default Header
