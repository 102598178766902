import styled from 'styled-components'

export const Main = styled.main<{backgroundColor?: string; centerContent?: boolean}>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: ${({centerContent}) => centerContent && 'center'};
  max-width: ${({theme}) => theme.currentPageTheme.contentMaxWidth};
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding-bottom: 2rem;
`

export const MainContent = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: calc(-1 * ${({theme}) => theme.spacing.xlarge}) ${({theme}) => theme.spacingCustom.medium}
    ${({theme}) => theme.spacingCustom.medium};
  z-index: 5;

  div:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  div:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`
