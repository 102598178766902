import {medium} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Heading, Notification} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ActionsWrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
  display: flex;
  flex-direction: column;
  > * {
    padding: ${({theme}) => theme.spacingCustom.medium} ${({theme}) => theme.spacing.large};
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({theme}) => theme.spacing.small};
`

export const TableNumber = styled(Heading)`
  display: inline;
  justify-content: center;
  margin: 0 0 ${({theme}) => theme.spacing.xxsmall};
`

export const ActionsDisabledInfo = styled(Notification)`
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacing.xsmall};
`

export const DisabledP = styled.p`
  background-color: rgba(255, 241, 216, 0.5);
  border: 1px solid ${({theme}) => theme.color.background.weakGrey};
  padding: ${medium};
`
