export const AppRoute = {
  Home: '/',
  Login: '/:token',
  OrderHistory: 'tilaushistoria',
  OrderId: ':id',
  OrderFlow: 'tilaa',
  Menu: 'ruokalista',
  MenuPreview: 'ruokalista/:restaurantId/:menuId',
  Summary: 'yhteenveto',
  OrderConfirmation: 'kiitos-tilauksesta',
} as const

export const NavigationRoute = {
  OrderHistory: `/${AppRoute.OrderHistory}`,
  Menu: `/${AppRoute.OrderFlow}/${AppRoute.Menu}`,
  Summary: `/${AppRoute.OrderFlow}/${AppRoute.Summary}`,
  OrderConfirmation: `/${AppRoute.OrderFlow}/${AppRoute.OrderConfirmation}`,
  Home: AppRoute.Home,
} as const
