import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useTranslation} from 'react-i18next'
import {TitleWrapper} from './HeaderStyles'

export const RestaurantTitle = () => {
  const {
    i18n: {language},
  } = useTranslation()
  const {restaurantData} = useRestaurantContext()
  const {name, address} = restaurantData?.getRestaurant || {}
  const restaurantName = getLocalized(name, language)
  const municipalityString = getLocalized(address?.municipality, language)

  return (
    <TitleWrapper>
      <Text variant='body' sizing='small' weight='medium'>
        {restaurantName}
      </Text>
      <Text variant='body' sizing='xsmall' transform='caption'>
        {municipalityString}
      </Text>
    </TitleWrapper>
  )
}
