import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import Divider from 'Components/Divider/Divider'
import {useTranslation} from 'react-i18next'
import {remoteTheme} from 'theme'
import {FeedbackLink, FeedbackWrapper, FooterActionsWrapper} from './FeedbackStyles'

const Feedback = ({tableType}: {tableType?: TableType}) => {
  const {t} = useTranslation('remotePage')
  const feedbackHref = tableType === TableType.PICKUP ? t('takeawayFeedbackUrl') : t('feedbackUrl')

  return (
    <FooterActionsWrapper>
      <Divider color={remoteTheme.color.background.mediumSecondary} />
      <FeedbackWrapper data-testid='feedback-container'>
        <Text variant='body' sizing='small'>
          {t('feedbackDescription')}
        </Text>
        <FeedbackLink href={feedbackHref} external>
          {t('feedbackLink')}
        </FeedbackLink>
      </FeedbackWrapper>
    </FooterActionsWrapper>
  )
}

export default Feedback
