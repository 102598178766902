/**
 * Converts a string to kebab case.
 *
 * Examples:
 * - 'Hello World' -> 'hello-world'
 * - 'helloWorld' -> 'hello-world'
 * - 'hello_world' -> 'hello-world'
 */

export const toKebabCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}
