import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {IconControlSend, IconStatusInfo, IconStatusSuccess} from '@s-group/design-system-icons'
import {useTranslation} from 'react-i18next'
import {OrderHistoryItemProps, OrderStatusType} from 'utils/order'
import OrderConfirmation from '../OrderConfirmation'
import {OrderHistoryItemWrapper, OrderStatus} from './OrderHistoryStyles'

const OrderHistoryItem = ({order, tableType}: {order: OrderHistoryItemProps; tableType?: TableType}) => {
  const {t} = useTranslation('order')
  const orderCompletedText =
    tableType === TableType.PICKUP ? t('history.orderStatus.completedTakeaway') : t('history.orderStatus.completed')

  const orderStatus = () => {
    switch (order.status) {
      case OrderStatusType.COMPLETED:
        return {
          text: orderCompletedText,
          props: {
            status: 'success',
            variant: 'tonal',
            sizing: 'small',
            aligment: 'left',
            close: false,
            icon: <IconStatusSuccess />,
          },
        }
      case OrderStatusType.INPROGRESS:
        return {
          text: t('history.orderStatus.inProgress'),
          props: {
            status: 'info',
            variant: 'tonal',
            sizing: 'small',
            aligment: 'left',
            close: false,
            icon: <IconStatusInfo />,
          },
        }
      default:
        return {
          text: t('history.orderStatus.sent'),
          props: {
            variant: 'tonal',
            sizing: 'small',
            aligment: 'left',
            close: false,
            icon: <IconControlSend />,
          },
        }
    }
  }

  const {props, text} = orderStatus()
  const {status, variant, sizing, aligment, close, icon} = props

  return (
    <OrderHistoryItemWrapper data-testid={`order-history-group-${order.orderID}`}>
      <OrderStatus
        data-testid={`order-history-item-${order.orderID}`}
        status={status}
        variant={variant}
        sizing={sizing}
        aligment={aligment}
        close={close}
        icon={icon}
      >
        {text}
      </OrderStatus>
      <OrderConfirmation order={order} />
    </OrderHistoryItemWrapper>
  )
}

export default OrderHistoryItem
