import {useReactiveVar} from '@apollo/client'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {countCartItems} from '@marketplace/shared-lib/src/utils/cartUtils'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {Button} from '@s-group/design-system-components'
import {IconNavigationArrowRight} from '@s-group/design-system-icons'
import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import useCreateOrderMutation from 'hooks/useCreateOrderMutation'
import {triggerEvent} from 'hooks/useDataLayer'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {previousCartIDsVar, restaurantSelectionTableTokens} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {mapPortionToEventItem} from 'utils/analytics'
import {remoteRestaurantSelectionToken} from 'utils/constants'
import {getCustomerDetails, getLatestOrderItem, getOtherOrdersCustomerDetails, validateText} from 'utils/order'
import CartRecommendations from './CartRecommendations'
import {Description, Title} from './OrderStyles'
import SummaryItems from './SummaryItems'
import SummaryRoomService from './SummaryRoomService'
import {
  ButtonWrapper,
  InputField,
  InputFieldHelperText,
  InputFieldLabel,
  SendButtonWrapper,
  SummaryFormRow,
  SummaryTotalContainer,
  SummaryTotalRow,
  SummaryTotalText,
  SummaryWrapper,
} from './SummaryStyles'
import SummaryTakeawayService from './SummaryTakeawayService'

const {ROOM, PICKUP} = TableType

const Summary = () => {
  const {t} = useTranslation('order')
  const {tableData, cartData, restaurantData, restaurantFeatures, handledOrderData} = useRestaurantContext()
  const triggerEventNew = useTriggerEventNew()
  const viewCartEventTriggered = useRef(false)
  const {token, restaurantID, tableType, tableID} = tableData?.getTable || {}
  const products = cartData?.getCart.items
  const productTotals = cartData?.getCart.total
  const cartID = cartData?.getCart.id
  const restaurantName = restaurantData?.getRestaurant.name.fi

  const setOrderProps = {
    restaurantID,
    token,
    restaurantName,
    tableID,
    tableType,
  }

  const {submitOrder, loading: submitOrderLoading} = useCreateOrderMutation(setOrderProps)
  const navigate = useNavigate()

  const restaurantSelectionToken = localStorage.getItem(remoteRestaurantSelectionToken)
  const otherTableTokens = useReactiveVar(restaurantSelectionTableTokens).filter((tableToken) => tableToken !== token)
  const previousCartIDsVars = useReactiveVar(previousCartIDsVar)
  const previousCartIDs = previousCartIDsVars.flatMap((id) => Object.values(id)).filter((id) => id !== null)

  const customerDetailsFromCurrentCart = getCustomerDetails(getLatestOrderItem(tableData, handledOrderData, cartID))
  let customerDetails = customerDetailsFromCurrentCart

  const customerDetailsFromPreviousCart = getOtherOrdersCustomerDetails(otherTableTokens, previousCartIDs)
  if (!customerDetails && restaurantSelectionToken && otherTableTokens.length) {
    // Get customer details from orders in other tables made by the same customer
    customerDetails = customerDetailsFromPreviousCart
  }

  const {firstName: initialNickName} = customerDetails ?? {}

  const [navigateToMenu, setNavigateToMenu] = useState(false)
  // use previous customer details as default values
  const [nickName, setNickName] = useState(initialNickName)
  const [nameValid, setNameValid] = useState(false)
  const [nameVisited, setNameVisited] = useState(false)

  const requiresNickName = restaurantID === '152'
  const minNameLength = 1

  const totalPrice = formatPrice({price: productTotals?.normal ?? 0})
  const orderTotalQuantity = countCartItems(products ?? [])

  const foodOrdersDisabled = restaurantFeatures?.kitchenClosed && products?.some((item) => item.portion.type === 'DISH')
  const submitIsDisabled = orderTotalQuantity === 0 || submitOrderLoading || !nameValid || foodOrdersDisabled

  useEffect(() => {
    setNameValid(!requiresNickName || validateText(minNameLength, nickName))
  }, [requiresNickName, minNameLength, nickName])

  const handleSubmitOrder = () => {
    submitOrder({
      nickName,
    })
  }

  useEffect(() => {
    if (restaurantName && tableID) {
      triggerEvent({
        event: AnalyticsEvents.MAKE_ORDER,
        action: AnalyticsActions.SUMMARY_OPEN,
        restaurant_name: restaurantName,
        table: tableID,
        table_type: tableType,
      })
    }
  }, [tableID, restaurantName, tableType])

  useEffect(() => {
    if (productTotals && products && triggerEventNew && !viewCartEventTriggered.current) {
      triggerEventNew({
        event: 'view_cart',
        ecommerce: {
          value: productTotals.normal / 100,
          currency: 'EUR',
          items: products.map((product) => ({...mapPortionToEventItem(product.portion), quantity: product.quantity})),
        },
      })
      // Make sure we don't trigger a new view_cart event when mutating the cart
      viewCartEventTriggered.current = true
    }
  }, [productTotals, products, triggerEventNew])

  useEffect(() => {
    if (navigateToMenu) {
      navigate(NavigationRoute.Menu)
    }

    return () => {
      setNavigateToMenu(false)
    }
  }, [navigateToMenu, navigate])

  if (!products || products?.length === 0) {
    return (
      <SummaryWrapper>
        <Title variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase' data-testid='summary-title'>
          {t('summary.title')}
        </Title>
        <Description variant='body' sizing='small' data-testid='summary-description-cart-empty'>
          {t('summary.cartEmpty')}
        </Description>
        <CartRecommendations />
        <ButtonWrapper>
          <Button
            color='neutral'
            variant='filled'
            sizing='medium'
            rounding='small'
            data-testid='button-back'
            onClick={() => setNavigateToMenu(true)}
          >
            {t('summary.back')}
          </Button>
        </ButtonWrapper>
      </SummaryWrapper>
    )
  }

  switch (tableType) {
    case ROOM:
      return <SummaryRoomService customerDetails={customerDetails} />
    case PICKUP:
      return <SummaryTakeawayService customerDetails={customerDetails} />
    default:
      return (
        <SummaryWrapper>
          <Title data-testid='summary-title' variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
            {t('summary.title')}
          </Title>
          <Description variant='body' sizing='small' data-testid='summary-description'>
            {t('summary.description')}
          </Description>
          <SummaryItems products={products} />
          <SummaryTotalContainer>
            <SummaryTotalRow>
              <SummaryTotalText data-testid='summary-order-total' variant='body' sizing='small' weight='bold'>
                {t('summary.orderTotal')}
              </SummaryTotalText>
              <SummaryTotalText data-testid='summary-total-price' variant='body' sizing='small' weight='bold'>
                {totalPrice}
              </SummaryTotalText>
            </SummaryTotalRow>
          </SummaryTotalContainer>
          <CartRecommendations />
          {!initialNickName && (
            <SummaryFormRow>
              <InputFieldLabel sizing='small' htmlFor='nickNameContainer'>
                {t('summary.nickNameLabel')}
              </InputFieldLabel>
              <InputField
                sizing='small'
                id='nickNameContainer'
                onChange={(e) => setNickName(e.target.value)}
                data-testid='nick-name-input'
                onBlur={() => setNameVisited(true)}
                error={nameVisited && !nameValid}
                autoFocus
              />
              {nameVisited && !nameValid ? (
                <InputFieldHelperText
                  data-testid='nick-name-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.nameError', {length: minNameLength})}
                </InputFieldHelperText>
              ) : (
                <InputFieldHelperText sizing='small'>{t('summary.nickNameHelpText')}</InputFieldHelperText>
              )}
            </SummaryFormRow>
          )}
          <SendButtonWrapper>
            <Button
              disabled={submitIsDisabled}
              data-testid='send-order-button'
              color='neutral'
              variant='filled'
              sizing='medium'
              rounding='small'
              onClick={handleSubmitOrder}
              icon={IconNavigationArrowRight}
              iconPos='after'
            >
              {t('summary.sendOrder')}
            </Button>
          </SendButtonWrapper>
        </SummaryWrapper>
      )
  }
}

export default Summary
