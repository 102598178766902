import {OpeningTime, TimeRange, WeekOpeningTime} from '@marketplace/shared-lib/graphql/graphql'
import {resolveThisWeekday, resolveTimeFromHoursAndMinutes} from '@marketplace/shared-lib/src/utils/openingTimes'
import {addMinutes, format, subMinutes} from 'date-fns'
import useRestaurantData from 'hooks/useRestaurantData'
import {useTranslation} from 'react-i18next'
import {ExtendedCustomerDetails} from 'utils/order'
import {PickupTimeWrapper} from './PickupTimePickerStyles'

interface PickupTimePickerProps {
  setSelectedTime: (key: keyof ExtendedCustomerDetails, time: string) => void
  disabled?: boolean
}

const resolveTimeOptions = (ranges?: TimeRange[]) =>
  ranges?.flatMap((range) => {
    const start = resolveTimeFromHoursAndMinutes({time: range.start})
    const end = resolveTimeFromHoursAndMinutes({time: range.end, isTomorrow: range.endNextDay})

    const times = []

    // first time is earliest 20 minutes after now
    const twentyMinutesFromNow = addMinutes(Date.now(), 20)

    // 15 minutes to midnight is the last time pickup is available for now
    const fifteenMinutesToMidnight = resolveTimeFromHoursAndMinutes({time: '23:45'})

    // first time is 15 minutes after the opening time
    // last time is 15 minutes before the closing time or 15 minutes before midnight if closing time is tomorrow
    // TODO: change this when pickup times can be tomorrow or later
    const firstTime = addMinutes(start, 15)
    const fifteenMinutesToClosing = subMinutes(end, 15)
    const lastTime = range.endNextDay ? fifteenMinutesToMidnight : fifteenMinutesToClosing
    for (let date = firstTime; date <= lastTime; date = addMinutes(date, 15)) {
      // push only times that are in the future
      if (date >= twentyMinutesFromNow) {
        const time = format(date, 'HH:mm')
        times.push(time)
      }
    }
    return times
  })

export const resolveCurrentOpeningTimes = (openingTimes?: WeekOpeningTime[]): TimeRange[] => {
  if (!openingTimes) {
    return []
  }
  const now = new Date()
  const thisWeekday = resolveThisWeekday()
  const formattedTodayDate = format(now, 'yyyy-MM-dd')

  const todaysOpeningTimes = openingTimes.find((time) => time.date.end && formattedTodayDate <= time.date.end)
  const todaysKitchen = todaysOpeningTimes?.kitchenOpeningTimes?.[thisWeekday]
  const todaysRestaurant = todaysOpeningTimes?.openingTimes?.[thisWeekday]
  const todays = todaysKitchen ?? todaysRestaurant

  if (!todays || todays?.type === OpeningTime.CLOSED || todays?.type === OpeningTime.OPEN_ONLY_BY_APPOINTMENT) {
    return []
  }

  if (todays.type === OpeningTime.TWENTYFOUR_HOURS) {
    return [{start: '00:00', end: '00:00', endNextDay: true}]
  }

  if (todays.type === OpeningTime.TIMES && !todays.ranges) {
    return []
  }

  const todaysRanges: TimeRange[] = (todays.ranges || []).map(({end, start, endNextDay}) => {
    return {end: endNextDay ? '00:00' : end, start, endNextDay}
  })

  return (
    todaysRanges.filter((range) => {
      const endTime = resolveTimeFromHoursAndMinutes({time: range.end, isTomorrow: range.endNextDay})
      return endTime >= now || range.end === '00:00'
    }) ?? []
  )
}

const PickupTimePicker = ({setSelectedTime, disabled}: PickupTimePickerProps) => {
  const {t} = useTranslation('order')

  const {data} = useRestaurantData()

  const openingTimes = data?.getRestaurant.weekOpeningTimes
  const timeOptions = resolveTimeOptions(resolveCurrentOpeningTimes(openingTimes)) ?? []
  timeOptions.push(t('summary.tomorrowOrLater'))

  return (
    <PickupTimeWrapper
      defaultValue={timeOptions[0]}
      data-testid='pickup-time-selector'
      sizing='xsmall'
      disabled={disabled}
      onChange={(event) => setSelectedTime('selectedTime', event.target.value)}
    >
      {timeOptions.map((time, i) => (
        <option data-testid={`pickup-time-option-${i}`} key={time} value={time}>
          {time}
        </option>
      ))}
    </PickupTimeWrapper>
  )
}

export default PickupTimePicker
