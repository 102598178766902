import {Link} from '@s-group/design-system-components'
import styled from 'styled-components'

const FooterElement = styled.footer`
  align-items: center;
  background-color: ${(props) => props.theme.color.background.inverseGrey};
  display: flex;
  justify-content: center;
  padding: ${({theme}) => theme.spacingCustom.medium};
`
const CookieSettingsLink = styled(Link)`
  font-size: ${(props) => props.theme.spacingCustom.small};
  color: ${(props) => props.theme.color.white};
`

export {CookieSettingsLink, FooterElement}
