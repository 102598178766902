import {Heading} from '@s-group/design-system-components'
import {AutoFocusInside} from 'react-focus-lock'
import styled from 'styled-components'

export const WelcomeMain = styled.div`
  background-color: ${({theme}) => theme.color.white};
  border-radius: 4px;
  display: block;
  width: 100%;
  text-align: center;
`

export const WelcomeTextWrapper = styled(AutoFocusInside)`
  padding: ${({theme}) => theme.spacing.large};
  text-align: center;
`

export const WelcomeHeader = styled(Heading)`
  margin: ${({theme}) => theme.spacing.xxsmall} 0 ${({theme}) => theme.spacing.small};
`
