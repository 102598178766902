import {IconStatusWarning} from '@s-group/design-system-icons'
import {remoteTheme} from 'theme'

export const Warning = () => (
  <IconStatusWarning
    height={remoteTheme.spacing.xxxlarge}
    width={remoteTheme.spacing.xxxlarge}
    color={remoteTheme.color.element.warning}
  />
)
