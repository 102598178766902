import {large} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Button, Heading} from '@s-group/design-system-components'
import styled from 'styled-components'

export const AgeDialogContent = styled.div<{paddingTop?: boolean}>`
  padding-top: ${({paddingTop}) => (paddingTop ? large : 0)};
`

export const AgeDialogHeader = styled(Heading)`
  margin: ${large} 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: ${({theme}) => theme.spacing.small};
`

export const YesNoButton = styled(Button)`
  width: 100%;
  max-width: 8rem;
`

export const ButtonWrapper = styled.div`
  width: 10rem;
  margin: 0 ${({theme}) => theme.spacing.xsmall};
`
