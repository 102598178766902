import {Link} from '@s-group/design-system-components'
import styled from 'styled-components'
import {spacingCustom} from 'theme'

export const FooterActionsWrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
  display: flex;
  flex-direction: column;
  padding: 0 0 ${spacingCustom.medium};
  gap: ${spacingCustom.medium};
`

export const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({theme}) => theme.spacing.small};
  white-space: pre-line;
  margin: 0 ${({theme}) => theme.spacing.small};
`

export const FeedbackLink = styled(Link)`
  font-weight: 500;
`
