import {PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import {CheckboxButtonWrapper, CheckboxLabel, CheckboxLabelExtraInfo} from './SelectionBoxStyles'
import {CheckBoxButtonProps} from './SelectionBoxTypes'

const CheckBoxButton = ({
  id,
  label,
  labelExtraInfo,
  name,
  value,
  onChange,
  checked,
  disabled = false,
  portionId,
  coopMemberPrice,
  priceType,
  children,
}: CheckBoxButtonProps) => {
  const buttonId = portionId ? `${portionId}-${id}` : id
  const formatSignDisplay = priceType === PriceType.ADDITIONAL

  return (
    <CheckboxButtonWrapper
      sizing='xsmall'
      id={buttonId}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-testid={buttonId}
      labelProps={{'data-testid': `${buttonId}-label`}}
    >
      <CheckboxLabel>
        <Text variant='body' sizing='small'>
          {label}
        </Text>
        {labelExtraInfo && (
          <CheckboxLabelExtraInfo>
            <Text variant='body' sizing='small'>
              {`, ${labelExtraInfo}`}
            </Text>
            <CoopMemberPrice
              alignItems='center'
              coopMemberPrice={coopMemberPrice}
              logoSize='1rem'
              formatSignDisplay={formatSignDisplay}
            />
          </CheckboxLabelExtraInfo>
        )}
      </CheckboxLabel>
      {children}
    </CheckboxButtonWrapper>
  )
}

export default CheckBoxButton
