import {useCallback, useEffect, useRef, useState} from 'react'

/**
 * Hook for detecting if browser goes to sleep, tab is changed, page is in background for X amount of time.
 * Can be only used on mobile devices or all devices
 * @param detectAfterMs - detectAfterMs initiates the use of detecting if app is in the background.default 180000ms (3min)
 * @param mobileOnly - if visibleAfterHidden gets updated on mobileOnly or all devices. default false.
 */

const useVisibilityChange = (detectAfterMs = 180000, mobileOnly = false) => {
  const updateValue = mobileOnly ? /Android|iPhone/i.test(navigator.userAgent) : true

  const [visibleAfterHidden, setVisibleAfterHidden] = useState(false)
  const [timeHiddenUntilVisible, setTimeHiddenUntilVisible] = useState(0)
  const timeHidden = useRef<number | undefined>(0)
  const stateResetTimer = useRef<NodeJS.Timeout | undefined>()

  const handleVisibilityChange = useCallback(
    async (e: Event) => {
      if (document.visibilityState === 'hidden') {
        timeHidden.current = e?.timeStamp
      }
      if (
        document.visibilityState === 'visible' &&
        timeHidden.current &&
        e.timeStamp - timeHidden.current > detectAfterMs
      ) {
        setVisibleAfterHidden(true)
        setTimeHiddenUntilVisible(e.timeStamp - timeHidden.current)
        timeHidden.current = undefined
      }
    },
    [detectAfterMs]
  )

  // reset state after visible
  useEffect(() => {
    if (visibleAfterHidden && updateValue) {
      // If we change the value to true and back to false too quickly, React
      // might batch the state updates and the value might remain false the
      // whole time.
      // Let's wait a bit before resetting the state.
      clearTimeout(stateResetTimer.current)
      stateResetTimer.current = setTimeout(() => {
        setVisibleAfterHidden(false)
        setTimeHiddenUntilVisible(0)
      }, 100)
    }
    return () => clearTimeout(stateResetTimer.current)
  }, [visibleAfterHidden, updateValue])

  useEffect(() => {
    // "The visibilitychange event is fired at the document when the contents
    // of its tab have become visible or have been hidden."
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  })

  return {visibleAfterHidden, timeHiddenUntilVisible}
}

export default useVisibilityChange
