import {Text} from '@s-group/design-system-components'
import {Fragment} from 'react'

type PortionOptionsProps = {
  portionOptionName: string
  portionOptionValues: string
}[]

type PortionOptionsStringProps = {
  portionOptions: PortionOptionsProps
  mainIndex?: number
  showPortionOptionName?: boolean
  size?: 'small' | 'medium' | 'large'
}

const defineKeyValue = (currentIndex: number, mainIndex?: number) => {
  return mainIndex ? `portion-${mainIndex}-option-${currentIndex}` : `portion-option-${currentIndex}`
}

const PortionOptionsString = ({
  portionOptions,
  mainIndex,
  showPortionOptionName = false,
  size = 'small',
}: PortionOptionsStringProps) => (
  <Text variant='body' sizing={size}>
    {portionOptions.map(({portionOptionName, portionOptionValues}, ind: number) => {
      if (portionOptionValues.length === 0) {
        return null
      }
      return (
        <Fragment key={defineKeyValue(ind, mainIndex)}>
          {ind !== 0 && portionOptionValues && ', '}
          {showPortionOptionName && portionOptionName && <span>{portionOptionName}: </span>}
          <span data-testid={`option-${ind}-selections`}>{portionOptionValues}</span>
        </Fragment>
      )
    })}
  </Text>
)

export default PortionOptionsString
