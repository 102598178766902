import {Text} from '@s-group/design-system-components'
import {ModalState} from 'Components/Modal/modalTypes'
import {Warning} from 'Icons'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import ModalContext from 'state/ModalContext'
import {PopupButton, PopupTitle, PopupWrapper} from './PopupStyles'

interface PopupProps {
  message: string
  testId?: string
}

const ErrorPopup = ({message, testId}: PopupProps): JSX.Element => {
  const {t} = useTranslation('remotePage')
  const {setModalState} = useContext(ModalContext)

  return (
    <PopupWrapper data-testid={testId} role='alert'>
      <Warning />
      <PopupTitle variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
        {t('errorHappened')}
      </PopupTitle>
      <Text variant='body' sizing='small'>
        {message}
      </Text>
      <PopupButton
        variant='plain'
        rounding='small'
        type='button'
        onClick={() => setModalState(ModalState.Closed)}
        data-testid='close-popup-button'
      >
        {t('close')}
      </PopupButton>
    </PopupWrapper>
  )
}

export default ErrorPopup
