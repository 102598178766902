import LanguageMenu from 'Components/LanguageMenu/LanguageMenu'
import {useLocation} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {HeaderLogo} from '../HeaderStyles'
import {RestaurantTitle} from '../RestaurantTitle'

const {Home} = NavigationRoute

const DefaultHeader = () => {
  const {pathname} = useLocation()
  const noRestaurantTitle = pathname === Home.toString()

  return (
    <>
      <HeaderLogo alt='Raflaamo icon' src='/raflaamo.svg' data-testid='header-logo' />
      {!noRestaurantTitle && <RestaurantTitle />}
      <LanguageMenu />
    </>
  )
}

export default DefaultHeader
