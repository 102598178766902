import {ContainsAlcohol, GetCartQuery, LoyaltyProgram, PortionType} from '@marketplace/shared-lib/graphql/graphql'
import {countCartItems} from '@marketplace/shared-lib/src/utils/cartUtils'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {TFunction} from 'i18next'
import {cartID} from 'state/reactiveVariables'
import {OrderAndCartFields} from 'types/tableTypes'
import {remoteCartID} from './constants'

export const setCartId = (id: string) => {
  localStorage?.setItem(remoteCartID, id)
  cartID(id)
}

export const removeCartID = () => {
  localStorage?.removeItem(remoteCartID)
  cartID(null)
}

export const getCartValues = (cartData?: GetCartQuery['getCart']) => {
  const cart = cartData
  const totalCount = countCartItems(cart?.items ?? [])
  const totalPrice = formatPrice({price: cart?.total?.normal ?? 0})
  return {totalCount, totalPrice}
}

export const deliveryPortion = (
  t: TFunction,
  type: '' | 'pickup' | 'room',
  deliveryFee?: number
): OrderAndCartFields['portion'] => ({
  id: `delivery-${type}`,
  name: {
    fi: t(`summary.deliveryProduct_${type}`, {lng: 'fi'}),
    en: t(`summary.deliveryProduct_${type}`, {lng: 'en'}),
    sv: t(`summary.deliveryProduct_${type}`, {lng: 'sv'}),
  },
  price: {normal: type === 'room' ? (deliveryFee ?? 0) : 0},
  containsAlcohol: ContainsAlcohol.NO,
  type: PortionType.DISH,
  diet: [],
  dishTags: [],
  drinkTags: [],
  description: {en: '', fi: '', sv: ''},
  portionOptionSections: [],
})

export const getLoyaltyProgramOptions = (restaurantId?: string) => {
  const defaultOptions = [
    LoyaltyProgram.NONE,
    LoyaltyProgram.COOP_MEMBER,
    LoyaltyProgram.S_CARD,
    LoyaltyProgram.S_CARD_PREMIUM,
  ]

  const RADISSON_IDS = [
    '135',
    '180',
    '181',
    '183',
    '184',
    '186',
    '187',
    '251',
    '381',
    '481',
    '482',
    '483',
    '484',
    '51353',
    '51354',
    '530014',
  ]

  const isRadisson = restaurantId ? RADISSON_IDS.includes(restaurantId) : false

  return isRadisson
    ? [
        ...defaultOptions,
        LoyaltyProgram.RADISSON_REWARDS,
        LoyaltyProgram.RADISSON_REWARDS_PREMIUM,
        LoyaltyProgram.RADISSON_REWARDS_VIP,
      ]
    : defaultOptions
}
