import styled from 'styled-components'
import {color} from 'theme'

const HeaderWrapper = styled.header`
  background-color: ${({theme}) => theme.color.background.inverseGrey};
  display: flex;
  min-height: 56px;
  position: relative;
`
const HeaderLogo = styled.img`
  left: 20px;
  position: absolute;
  top: 18px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0.5em auto;
  color: ${color.white};
`

export {HeaderLogo, HeaderWrapper, TitleWrapper}
