// Hooks that are used to fetch data from the server and keep the data
// up-to-date via subscriptions. Tries to keep the connection alive by
// refetching and resubscribing on network changes or tab visibility changes.

import {OperationVariables, QueryHookOptions, QueryResult} from '@apollo/client'
import {UpdateQueryFn} from '@apollo/client/core/watchQueryOptions'
import {MutableRefObject} from 'react'
import {
  GetRestaurantFeaturesQuery,
  GetRestaurantFeaturesQueryVariables,
  GetRestaurantFeaturesStaffQuery,
  GetRestaurantFeaturesStaffQueryVariables,
  GetStoredOrdersQuery,
  GetStoredOrdersQueryVariables,
  GetStoredOrdersType,
  GetTableQuery,
  GetTableQueryVariables,
  GetTablesQuery,
  GetTablesQueryVariables,
  GetTimeQuery,
  GetTimeQueryVariables,
  OnChangeRestaurantFeaturesDocument,
  OnChangeRestaurantFeaturesStaffDocument,
  OnChangeTableStateDocument,
  OnChangeTableStateStaffDocument,
  OnChangeTableStateStaffSubscription,
  OnChangeTimeDocument,
  OnChangeTimeSubscription,
  OnModifyOrdersDocument,
  OnModifyOrdersStaffDocument,
  OnModifyOrdersStaffSubscription,
  OnModifyOrdersSubscription,
  StoredOrderFieldsFragment,
  useGetRestaurantFeaturesQuery,
  useGetRestaurantFeaturesStaffQuery,
  useGetStoredOrdersQuery,
  useGetTableQuery,
  useGetTablesQuery,
  useGetTimeQuery,
} from '../../graphql/graphql'
import usePersistentSubscription from './usePersistentSubscription'

export const useRestaurantFeaturesStaff = ({restaurantID}: {restaurantID: string}) =>
  usePersistentSubscription(
    // some type issue here without the "as" cast
    useGetRestaurantFeaturesStaffQuery as (
      options: QueryHookOptions<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>
    ) => QueryResult<GetRestaurantFeaturesStaffQuery, GetRestaurantFeaturesStaffQueryVariables>,
    {
      variables: {input: {restaurantID}},
      skip: !restaurantID,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnChangeRestaurantFeaturesStaffDocument,
          variables: {restaurantID},
        },
      ],
      resubscribeThresholdInMs: 5000,
    }
  )

export const useRestaurantFeaturesRemote = ({restaurantID, skip}: {restaurantID: string; skip: boolean}) =>
  usePersistentSubscription(
    // some type issue here without the "as" cast
    useGetRestaurantFeaturesQuery as (
      options: QueryHookOptions<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>
    ) => QueryResult<GetRestaurantFeaturesQuery, GetRestaurantFeaturesQueryVariables>,
    {
      variables: {input: {restaurantID}},
      skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnChangeRestaurantFeaturesDocument,
          variables: {restaurantID},
        },
      ],
      resubscribeThresholdInMs: 100,
    }
  )

export const usePersistentSubscriptionDefibrillator = () =>
  usePersistentSubscription(
    useGetTimeQuery as (
      options: QueryHookOptions<GetTimeQuery, GetTimeQueryVariables>
    ) => QueryResult<GetTimeQuery, GetTimeQueryVariables>,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnChangeTimeDocument,
          updateQuery: ((_, {subscriptionData}) => {
            return {
              getTime: {
                __typename: 'TimeStamp',
                now: subscriptionData.data.onChangeTime?.now ?? '',
                builtOn: subscriptionData.data.onChangeTime?.builtOn ?? '',
              },
            }
          }) satisfies UpdateQueryFn<GetTimeQuery, OperationVariables, OnChangeTimeSubscription>,
        },
      ],
      resubscribeThresholdInMs: 5000,
    }
  )

export const useHandledOrders = ({
  cartID,
  tableToken,
  skip,
}: {
  cartID: string | null
  tableToken: string
  skip: boolean
}) =>
  usePersistentSubscription(
    useGetStoredOrdersQuery as (
      options: QueryHookOptions<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>
    ) => QueryResult<GetStoredOrdersQuery, GetStoredOrdersQueryVariables>,
    {
      variables: {input: {getType: GetStoredOrdersType.CART_ORDERS, cartID}},
      skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnModifyOrdersDocument,
          variables: {token: tableToken},
          updateQuery: ((prev, {subscriptionData}) => {
            // make sure subscription has data
            if (!subscriptionData?.data?.onModifyOrders || !prev.getStoredOrders.items) {
              return prev
            }

            const incomingDoneOrders: StoredOrderFieldsFragment[] =
              subscriptionData.data.onModifyOrders.doneOrders?.orderItems
                .filter((order) => order.cartID === cartID)
                .map((o) => ({
                  ...o,
                  requestedAt: o.requestedAt ?? '',
                  orderID: o.id,
                  __typename: 'StoredOrder',
                })) || []

            const newData = {
              getStoredOrders: {
                ...prev.getStoredOrders,
                items: [...prev.getStoredOrders.items, ...incomingDoneOrders],
              },
            }

            return newData
          }) satisfies UpdateQueryFn<GetStoredOrdersQuery, OperationVariables, OnModifyOrdersSubscription>,
        },
      ],
      resubscribeThresholdInMs: 100,
    }
  )

export const useTable = ({token, skip}: {token: string; skip: boolean}) =>
  usePersistentSubscription(
    useGetTableQuery as (
      options: QueryHookOptions<GetTableQuery, GetTableQueryVariables>
    ) => QueryResult<GetTableQuery, GetTableQueryVariables>,
    {
      variables: {input: {token}},
      skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnChangeTableStateDocument,
          variables: {token},
        },
        {
          document: OnModifyOrdersDocument,
          variables: {token},
          updateQuery: ((prev, {subscriptionData}) => {
            if (!subscriptionData?.data?.onModifyOrders) {
              return prev
            }
            const incomingOrders = subscriptionData?.data?.onModifyOrders?.orders
            const incomingBundledOrders = subscriptionData?.data?.onModifyOrders?.bundledOrders
            const newData = {
              getTable: {...prev.getTable, orders: incomingOrders, bundledOrders: incomingBundledOrders ?? []},
            }
            return newData
          }) satisfies UpdateQueryFn<GetTableQuery, OperationVariables, OnModifyOrdersSubscription>,
        },
      ],
      resubscribeThresholdInMs: 100,
    }
  )

export type IncomingSubscriptionDataProps =
  | {
      token: string
      requestType: 'order' | 'waiterRequest' | 'oldOrder'
    }
  | undefined

export const useTablesStaff = ({
  restaurantID,
  skip,
  incomingSubscriptionData,
}: {
  restaurantID: string
  skip: boolean
  incomingSubscriptionData: MutableRefObject<IncomingSubscriptionDataProps>
}) =>
  usePersistentSubscription(
    useGetTablesQuery as (
      options: QueryHookOptions<GetTablesQuery, GetTablesQueryVariables>
    ) => QueryResult<GetTablesQuery, GetTablesQueryVariables>,
    {
      variables: {input: {restaurantID}},
      skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      subscriptionOptions: [
        {
          document: OnChangeTableStateStaffDocument,
          variables: {restaurantID},
          updateQuery: ((prev, {subscriptionData}): GetTablesQuery => {
            const incomingData = subscriptionData?.data?.onChangeTableStateStaff
            if (!incomingData) return prev

            // eslint-disable-next-line no-param-reassign
            incomingSubscriptionData.current = {
              requestType: 'waiterRequest',
              token: incomingData.token,
            }
            return prev
          }) satisfies UpdateQueryFn<GetTablesQuery, OperationVariables, OnChangeTableStateStaffSubscription>,
        },
        {
          document: OnModifyOrdersStaffDocument,
          variables: {restaurantID},
          updateQuery: ((prev, {subscriptionData}): GetTablesQuery => {
            const incomingData = subscriptionData?.data?.onModifyOrdersStaff
            if (!incomingData) {
              return prev
            }
            // find table with current token and write incoming data into it
            const updatedTables = prev.getTables.map((table) => {
              if (table.token === incomingData.token) {
                if (incomingData.orders?.orderItems && incomingData.orders?.orderItems.length === 1) {
                  // eslint-disable-next-line no-param-reassign
                  incomingSubscriptionData.current = {
                    requestType: 'order',
                    token: incomingData.token,
                  }
                }
                return {...table, orders: incomingData.orders, bundledOrders: incomingData.bundledOrders ?? []}
              }
              return table
            })
            return {...prev, getTables: updatedTables}
          }) satisfies UpdateQueryFn<GetTablesQuery, OperationVariables, OnModifyOrdersStaffSubscription>,
        },
      ],
      resubscribeThresholdInMs: 100,
    }
  )
