import '@s-group/design-system-tokens/web/assets/fonts/SBonusDisplay.css'
import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css'
import '@s-group/design-system-tokens/web/tokens/font.css'
import {createGlobalStyle} from 'styled-components'
import {color} from 'theme'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'S Bonus UX';
    background-color: ${color.background.inverseGrey};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3, 
  h4 {
    font-family: 'S Bonus UX', 'S Bonus Display';
  }
  
  code {
    font-family: 'S Bonus UX', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /* Design system fonts */

  @font-face {
    font-family: 'S Bonus UX';
    font-weight: 400;
    src: url('/fonts/SBonusUX-Regular.woff2') format('woff2'), url('/fonts/SBonusUX-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'S Bonus UX';
    font-weight: 500;
    src: url('/fonts/SBonusUX-Medium.woff2') format('woff2'), url('/fonts/SBonusUX-Medium.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'S Bonus UX';
    font-weight: 700;
    src: url('/fonts/SBonusUX-Bold.woff2') format('woff2'), url('/fonts/SBonusUX-Bold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'S Bonus Display';
    font-weight: 600;
    src: url('/fonts/SBonusDisplay-SemiBold.woff2') format('woff2'),
      url('/fonts/SBonusDisplay-SemiBold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'S Bonus Display';
    font-weight: 700;
    src: url('/fonts/SBonusDisplay-Bold.woff2') format('woff2'), url('/fonts/SBonusDisplay-Bold.woff') format('woff');
    font-display: swap;
  }

`

export default GlobalStyle
