import {useReactiveVar} from '@apollo/client'
import {CallWaiterType, TableStatusType, useChangeTableStateMutation} from '@marketplace/shared-lib/graphql/graphql'
import {Button, Text} from '@s-group/design-system-components'
import {ActionButton} from 'Components/Buttons'
import {ModalState} from 'Components/Modal/modalTypes'
import {PopupTitle, PopupWrapper} from 'Components/Popup/PopupStyles'
import useTriggerEvent from 'hooks/useTriggerEvent'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import ModalContext from 'state/ModalContext'
import {modalInitiatorRefVar} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {useToken} from 'utils/cookies'

const CallWaiterConfirmation = () => {
  const {t} = useTranslation('remotePage')
  const {setModalState} = useContext(ModalContext)
  const token = useToken()
  const {triggerEvent, loading: triggerEventLoading} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()
  const [callWaiter, {loading: callWaiterLoading}] = useChangeTableStateMutation({
    onError: (error) => {
      if (error.message === 'TABLE_DISABLED') {
        setModalState(ModalState.TableDisabled)
      } else {
        setModalState(ModalState.Error)
      }
      triggerEvent({
        event: AnalyticsEvents.CALL_WAITER,
        action: AnalyticsActions.ERROR_CALL_WAITER,
      })
    },
    onCompleted: () => {
      triggerEventNew?.({event: 'call_waiter'})
      setModalState(ModalState.WaiterCalled)
      triggerEvent({
        event: AnalyticsEvents.CALL_WAITER,
        action: AnalyticsActions.CALL_WAITER,
      })
    },
  })

  const currentRefVar = useReactiveVar(modalInitiatorRefVar)

  const handleOnClose = () => {
    setModalState(ModalState.Closed)
    if (currentRefVar) {
      window.setTimeout(() => currentRefVar.current?.focus(), 300)
    }
  }

  const callWaiterFunction = () => {
    if (!token) return
    void callWaiter({
      variables: {
        input: {token, state: {callWaiterType: CallWaiterType.GENERIC, status: TableStatusType.REQUESTED}},
      },
    })
  }

  const loading = callWaiterLoading || triggerEventLoading || !triggerEventNew || !token

  return (
    <PopupWrapper data-testid='call-waiter-confirmation' role='alertdialog'>
      <PopupTitle tagName='h3' variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
        {t('callWaiter')}
      </PopupTitle>
      <Text variant='body' sizing='small' weight='regular'>
        {t('callWaiterDescription')}
      </Text>
      <ActionButton
        data-testid='call-waiter-confirmation-proceed'
        disabled={loading}
        onClick={callWaiterFunction}
        rounding='small'
        color='neutral'
        variant='filled'
      >
        {t('callWaiter')}
      </ActionButton>
      <Button onClick={handleOnClose} data-testid='call-waiter-confirmation-cancel' variant='plain' rounding='small'>
        {t('cancel')}
      </Button>
    </PopupWrapper>
  )
}

export default CallWaiterConfirmation
