import {useReactiveVar} from '@apollo/client'
import {
  CallWaiterType,
  ChangeTableStateMutationVariables,
  TableStatusType,
  useChangeTableStateMutation,
} from '@marketplace/shared-lib/graphql/graphql'
import {Button, Text} from '@s-group/design-system-components'
import {ActionButton} from 'Components/Buttons'
import {ModalState} from 'Components/Modal/modalTypes'
import {PopupText, PopupTitle, PopupWrapper} from 'Components/Popup/PopupStyles'
import useTriggerEvent from 'hooks/useTriggerEvent'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import ModalContext from 'state/ModalContext'
import {modalInitiatorRefVar} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {useToken} from 'utils/cookies'

const BillOptionDialog = (): JSX.Element => {
  const {t} = useTranslation('remotePage')
  const {setModalState} = useContext(ModalContext)
  const token = useToken()
  const {triggerEvent, loading: triggerEventLoading} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()

  const [callWaiter, {loading: callWaiterLoading}] = useChangeTableStateMutation({
    onError: (error, clientOptions) => {
      if (error.message === 'TABLE_DISABLED') {
        setModalState(ModalState.TableDisabled)
      } else {
        setModalState(ModalState.Error)
      }

      const {
        input: {
          state: {callWaiterType},
        },
      } = clientOptions?.variables as ChangeTableStateMutationVariables
      triggerEvent({
        event: AnalyticsEvents.REQUEST_BILL,
        action:
          callWaiterType === CallWaiterType.CALL_WAITER_WITH_BILL
            ? AnalyticsActions.ERROR_ONE_BILL
            : AnalyticsActions.ERROR_SPLIT_BILLS,
      })
    },
    onCompleted: (data) => {
      const {
        changeTableState: {
          state: {callWaiterType},
        },
      } = data

      setModalState(ModalState.BillRequested)
      triggerEvent({
        event: AnalyticsEvents.REQUEST_BILL,
        action:
          callWaiterType === CallWaiterType.CALL_WAITER_WITH_BILL
            ? AnalyticsActions.ONE_BILL
            : AnalyticsActions.SPLIT_BILLS,
      })
      triggerEventNew?.({
        event: 'request_bill',
        data: {
          request_bill_type: callWaiterType === CallWaiterType.CALL_WAITER_WITH_BILL ? 'one_bill' : 'split_bills',
        },
      })
    },
  })

  const callWaiterFunction = (callWaiterType: CallWaiterType) => {
    if (!token) return
    void callWaiter({
      variables: {
        input: {token, state: {callWaiterType, status: TableStatusType.REQUESTED}},
      },
    })
  }

  const currentRefVar = useReactiveVar(modalInitiatorRefVar)

  const handleOnClose = () => {
    setModalState(ModalState.Closed)
    if (currentRefVar) {
      window.setTimeout(() => currentRefVar.current?.focus(), 300)
    }
  }

  const loading = callWaiterLoading || triggerEventLoading || !triggerEventNew || !token

  return (
    <PopupWrapper data-testid='request-bill-popup' role='alertdialog'>
      <PopupTitle tagName='h3' variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
        {t('requestBill')}
      </PopupTitle>
      <Text variant='body' sizing='small' weight='regular'>
        {t('singleBillDescription')}
      </Text>
      <ActionButton
        disabled={loading}
        onClick={() => callWaiterFunction(CallWaiterType.CALL_WAITER_WITH_BILL)}
        data-testid='request-bill-single'
        rounding='small'
        color='neutral'
        variant='filled'
      >
        {t('singleBill')}
      </ActionButton>
      <PopupText variant='body' sizing='small' weight='regular'>
        {t('billDescription')}
      </PopupText>
      <ActionButton
        disabled={loading}
        onClick={() => callWaiterFunction(CallWaiterType.CALL_WAITER_WITH_BILL_SPLIT)}
        data-testid='request-bill-split'
        rounding='small'
        color='neutral'
        variant='filled'
      >
        {t('splitBill')}
      </ActionButton>
      <Button onClick={handleOnClose} data-testid='request-bill-cancel' variant='plain' rounding='small'>
        {t('cancel')}
      </Button>
    </PopupWrapper>
  )
}

export default BillOptionDialog
