import {RestaurantFeatures, TableStatusType, TableType, WeekOpeningTime} from '@marketplace/shared-lib/graphql/graphql'
import {Table} from '@marketplace/shared-lib/src/types/graphql'
import type {TableActions, TableActionsAutomatic} from '@marketplace/shared-lib/src/types/graphql.ts'
import {resolveRestaurantOpen} from '@marketplace/shared-lib/src/utils/openingTimes'

type AreGlobalTableActionsEnabledParams = {
  tableActions?: TableActions
  tableActionsAutomatic?: TableActionsAutomatic
  weekOpeningTimes?: WeekOpeningTime[]
  lastCallBuffer?: number
}

/**
 * Returns the state of global (restaurant level) table actions.
 * This can be overridden by the table-specific settings.
 */
export const areGlobalTableActionsEnabled = ({
  tableActions,
  tableActionsAutomatic,
  weekOpeningTimes,
  lastCallBuffer,
}: AreGlobalTableActionsEnabledParams): boolean => {
  return tableActionsAutomatic
    ? resolveRestaurantOpen({flagType: 'tableActions', openingTimes: weekOpeningTimes, lastCallBuffer})
    : (tableActions ?? true)
}

type IsOrderingPossibleParams = {
  table?: Table
  restaurantFeatures?: RestaurantFeatures
  weekOpeningTimes?: WeekOpeningTime[]
}

export const isOrderingPossible = ({
  table,
  restaurantFeatures,
  weekOpeningTimes,
}: IsOrderingPossibleParams): boolean => {
  const tableType = table?.tableType
  const tableStatus = table?.state.status
  const tableActions = restaurantFeatures?.tableActions
  const lastCallBuffer = restaurantFeatures?.lastCallBuffer
  const tableActionsAutomatic = restaurantFeatures?.tableActionsAutomatic

  const isOpen = areGlobalTableActionsEnabled({tableActions, tableActionsAutomatic, weekOpeningTimes, lastCallBuffer})

  return !!(
    tableStatus !== TableStatusType.DISABLED &&
    isOpen &&
    restaurantFeatures?.quickOrder &&
    // For the room table types we need to check if room service is enabled
    (tableType === TableType.ROOM || tableType === TableType.OPEN_ROOM
      ? restaurantFeatures?.roomService.deliveryEnabled || restaurantFeatures?.roomService.pickupEnabled
      : true)
  )
}

type IsRestaurantKitchenOpenParams = {
  kitchenOpenAutomatic?: boolean | null
  kitchenClosed?: boolean
  weekOpeningTimes?: WeekOpeningTime[]
}

export const isRestaurantKitchenOpen = ({
  kitchenOpenAutomatic,
  kitchenClosed,
  weekOpeningTimes,
}: IsRestaurantKitchenOpenParams): boolean =>
  kitchenOpenAutomatic
    ? resolveRestaurantOpen({flagType: 'kitchenClosed', openingTimes: weekOpeningTimes})
    : !kitchenClosed
