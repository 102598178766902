import {
  CartItemFieldsFragment,
  GetStoredOrdersQuery,
  GetTableQuery,
  LoyaltyProgram,
  OrderFieldsFragment,
  PaymentMethod,
  PickupTime,
  StoredOrderFieldsFragment,
  TotalPrice,
} from '@marketplace/shared-lib/graphql/graphql'
import {formatDateString} from '@marketplace/shared-lib/src/utils/timeUtils'
import useHandledOrdersData from 'hooks/useHandledOrdersData'
import useTableData from 'hooks/useTableData'

export enum OrderStatusType {
  SENT = 'SENT',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
}

export type OrderProps = {
  products?: CartItemFieldsFragment[]
  productTotals?: TotalPrice
  formattedDateString?: string
  requestedAt: string
  additionalInformation?: string | null
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  orderID?: string
  orderStatus?: string
  pickupTime?: PickupTime
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
}

export interface OrderHistoryItemProps extends OrderProps {
  orderID: string
  status: OrderStatusType
}

export interface OrderHistoryProps {
  pendingOrders?: OrderHistoryItemProps[]
  ordersInProgress?: OrderHistoryItemProps[]
  handledOrders?: OrderHistoryItemProps[]
}
export interface OrderDetails {
  nickName?: string | null
  lastName?: string | null
  phoneNumber?: string | null
  loyaltyProgram?: LoyaltyProgram | null
  paymentMethod?: PaymentMethod | null
}
export interface CustomerDetails {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  loyaltyProgram?: LoyaltyProgram
  paymentMethod?: PaymentMethod
}

export interface ExtendedCustomerDetails extends CustomerDetails {
  selectedPickupTime?: 'asap' | 'specific'
  selectedTime?: string | undefined
}

export const findOrder = (tableData?: GetTableQuery, id?: string): OrderFieldsFragment | undefined => {
  if (!id) {
    return undefined
  }
  const order = tableData?.getTable.orders?.orderItems?.find((orderItem) => orderItem.id === id)
  if (order) {
    return order
  }
  const orderItems = tableData?.getTable.bundledOrders?.map((bundledOrder) => bundledOrder.orderItems).flat()
  const bundledOrder = orderItems?.find((item) => item.id === id)
  if (bundledOrder) {
    return bundledOrder
  }
  return undefined
}

export const getOrderValues = (order?: OrderFieldsFragment | StoredOrderFieldsFragment): OrderProps | undefined => {
  if (!order) {
    return undefined
  }

  const products = order.cart
  const productTotals = order.cartTotal
  const dateString = order.requestedAt
  const formattedDateString = formatDateString({dateString})
  return {
    products,
    productTotals,
    formattedDateString,
    requestedAt: order.requestedAt,
    nickName: order.nickName,
    lastName: order.lastName,
    phoneNumber: order.phoneNumber,
    pickupTime: {
      asap: order.pickupTime?.asap,
      time: order.pickupTime?.time,
    },
    loyaltyProgram: order.loyaltyProgram,
    paymentMethod: order.paymentMethod,
  }
}

export const getPendingOrders = (tableData?: GetTableQuery, cartID?: string | null) => {
  if (!tableData || !cartID) {
    return {pendingOrders: undefined, bundledPendingOrders: undefined}
  }
  const pendingOrders = tableData?.getTable.orders?.orderItems?.filter((order) => order.cartID === cartID)
  const bundledPendingOrders = tableData?.getTable?.bundledOrders
    ?.map((bundledOrder) => {
      const ordersWithCartID = bundledOrder?.orderItems.filter((order) => order.cartID === cartID)
      return ordersWithCartID
    })
    .flat(2)

  return {pendingOrders, bundledPendingOrders}
}

export const sortByRequestDate = <T extends {requestedAt: string}>(orders: T[]) =>
  orders.sort((a, b) => b.requestedAt.localeCompare(a.requestedAt))

const getSortedPendingOrders = (status: OrderStatusType, orders?: OrderFieldsFragment[], tableData?: GetTableQuery) => {
  const unSortedOrders = orders || []
  const sortedOrders = sortByRequestDate([...unSortedOrders])

  return sortedOrders
    .map(({id}) => {
      const orderValues = getOrderValues(findOrder(tableData, id))
      return (
        orderValues && {
          ...orderValues,
          status,
          orderID: id,
        }
      )
    })
    .filter((order) => !!order)
}

const getSortedHandledOrders = (handledOrderData?: GetStoredOrdersQuery) => {
  const unSortedHandledOrders = handledOrderData?.getStoredOrders.items || []
  const newestFirstHandledOrders = sortByRequestDate([...unSortedHandledOrders])
  return newestFirstHandledOrders
    ?.map((order) => {
      const orderValues = getOrderValues(order)
      return (
        orderValues && {
          ...orderValues,
          status: OrderStatusType.COMPLETED,
          orderID: order.orderID,
        }
      )
    })
    .filter((order) => !!order)
}

export const getAllOrders = (
  tableData?: GetTableQuery,
  handledOrderData?: GetStoredOrdersQuery,
  cartID?: string | null
): OrderHistoryProps => {
  if (!cartID || cartID === null) {
    return {pendingOrders: undefined, ordersInProgress: undefined, handledOrders: undefined}
  }

  const orders = getPendingOrders(tableData, cartID)
  const pendingOrders = getSortedPendingOrders(OrderStatusType.SENT, orders?.pendingOrders, tableData)
  const ordersInProgress = getSortedPendingOrders(OrderStatusType.INPROGRESS, orders?.bundledPendingOrders, tableData)
  const handledOrders = getSortedHandledOrders(handledOrderData)
  return {pendingOrders, ordersInProgress, handledOrders}
}

export const getCustomerDetails = (order?: OrderDetails): CustomerDetails | undefined => {
  if (!order) {
    return undefined
  }
  return {
    firstName: order.nickName ?? undefined,
    lastName: order.lastName ?? undefined,
    phoneNumber: order.phoneNumber ?? undefined,
    loyaltyProgram: order.loyaltyProgram ?? undefined,
    paymentMethod: order.paymentMethod ?? undefined,
  }
}

export const getLatestOrderItem = (
  tableData?: GetTableQuery,
  handledOrderData?: GetStoredOrdersQuery,
  cartID?: string | null
) => {
  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)

  const latestOrders: OrderHistoryItemProps[] = []
  if (pendingOrders) {
    latestOrders.push(pendingOrders[0])
  }
  if (ordersInProgress) {
    latestOrders.push(ordersInProgress[0])
  }
  if (handledOrders) {
    latestOrders.push(handledOrders[0])
  }

  return latestOrders.sort((a, b) => b.requestedAt.localeCompare(a.requestedAt))[0]
}

export const getOtherOrdersCustomerDetails = (
  tableTokens: string[],
  cartIDs: string[]
): CustomerDetails | undefined => {
  const pendingOrders = tableTokens.map((tableToken) => {
    const table = useTableData().useTableDataByToken(tableToken)

    const orderItems = table.data?.getTable.orders?.orderItems.filter((item) => cartIDs.includes(item.cartID)) || []
    const bundledOrders =
      table.data?.getTable.bundledOrders
        ?.flatMap((orders) => orders.orderItems)
        .filter((item) => cartIDs.includes(item.cartID)) || []
    return {
      orders: orderItems,
      bundledOrders,
    }
  })

  const pendingOrderItems = pendingOrders.flatMap((order) => order.orders)
  const bundledOrderItems = pendingOrders.flatMap((order) => order.bundledOrders)
  const handledOrders = cartIDs
    .flatMap((cartID) => useHandledOrdersData().useHandledOrdersDataByToken(cartID).data?.getStoredOrders.items)
    .filter((order) => order !== undefined)
    .filter((order) => cartIDs.includes(order.cartID))

  const lastBundledOrder = sortByRequestDate(bundledOrderItems)[0]
  const lastPendingOrder = sortByRequestDate(pendingOrderItems)[0]
  const lastHandledOrder = sortByRequestDate(handledOrders)[0]

  const latestOrder = [lastBundledOrder, lastPendingOrder, lastHandledOrder].sort((a, b) =>
    b.requestedAt.localeCompare(a.requestedAt)
  )[0]

  return getCustomerDetails(latestOrder)
}

export const validateDigits = (length: number, text?: string | null) =>
  !!text && text.replace(/\D/g, '').length >= length

export const validateText = (length: number, text?: string | null) => !!text && text.trim().length >= length
