import {CartItemFieldsFragment, PortionType} from '@marketplace/shared-lib/graphql/graphql'
import PortionOptionsString from '@marketplace/shared-lib/src/components/PortionOptionsString'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {getPortionOptionsText} from '@marketplace/shared-lib/src/utils/portionOptions'
import {Quantity} from '@s-group/design-system-components'
import {
  IconCommunicationMessage,
  IconControlEdit,
  IconControlRemove,
  IconStatusWarning,
} from '@s-group/design-system-icons'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import config from 'config'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router'
import {remoteTheme} from 'theme'
import {AdditionalInfoText, AdditionInfo, AdditionInfoContent, RemoveButton} from 'views/order/ThankYouStyles'
import {
  ControlsAndPriceWrapper,
  EditButton,
  PortionOptions,
  Price,
  PriceWrapper,
  ProductDetails,
  ProductSummaryContainer,
  ProductTitle,
  ProductTitleRow,
} from './ProductStyles'

interface ProductSummaryCardProps {
  cartItem: CartItemFieldsFragment
  onAddItem: () => void
  onRemoveItem: () => void
  onRemoveItemComment: () => void
  kitchenClosed?: boolean
}

const ProductSummaryCard = ({
  cartItem,
  onAddItem,
  onRemoveItem,
  onRemoveItemComment,
  kitchenClosed,
}: ProductSummaryCardProps) => {
  const {i18n, t} = useTranslation('order')
  const currentLanguage = i18n.language
  const removeCommentLabel = t('summary.removeComment')
  const editProductLabel = t('summary.editProduct')
  const additionalInfoLabel = t('summary.additionalInformation')
  const {portion, quantity, additionalInformation, menuID} = cartItem
  const {
    unitPrice: {normal: normalUnitPrice, coopMember: coopMemberPrice},
    rowTotal: {normal: normalRowTotal},
    cartItemID,
  } = cartItem || {}
  const portionOptions = getPortionOptionsText(portion, currentLanguage)
  const normalPrice = formatPrice({price: normalUnitPrice})
  const rowTotalPrice = formatPrice({price: normalRowTotal})
  const forbiddenPortion = kitchenClosed && portion.type === PortionType.DISH
  const [, setSearchParams] = useSearchParams()

  const {color} = remoteTheme

  const handleEditProduct = () => {
    if (!menuID) return

    const params = new URLSearchParams([
      ['portion', portion.id],
      ['menu', menuID],
      ['edit', 'true'],
      ['cart-item-id', cartItemID],
    ])
    setSearchParams(params)
  }
  const handleChangedQuantity = (count: number) => {
    if (count > quantity) {
      return onAddItem()
    }

    return onRemoveItem()
  }

  return (
    <ProductSummaryContainer data-testid={portion.id}>
      <ProductTitleRow>
        <div>
          <ProductTitle data-testid='product-title' variant='body' sizing='small' weight='bold'>
            {forbiddenPortion && <IconStatusWarning color={color.element.warning} />}
            {getLocalized(portion.name, currentLanguage)}
          </ProductTitle>
          <ProductDetails variant='body' sizing='small'>
            {portionOptions && portionOptions.length > 0 && (
              <PortionOptions>
                <PortionOptionsString
                  portionOptions={portionOptions}
                  showPortionOptionName={config.SHOW_PORTION_OPTION_NAME}
                />
              </PortionOptions>
            )}
          </ProductDetails>
        </div>
        {menuID && (
          <EditButton
            variant='plain'
            sizing='xsmall'
            rounding='small'
            iconOnly
            aria-label={editProductLabel}
            data-testid={`edit-product-button-${portion.id}`}
            onClick={handleEditProduct}
            icon={<IconControlEdit color={color.text.strongPrimary} />}
          />
        )}
      </ProductTitleRow>
      {additionalInformation && (
        <AdditionInfo
          data-testid='additional-information'
          aria-label={additionalInfoLabel}
          variant='plain'
          sizing='small'
          status='warning'
          close={false}
          icon={<IconCommunicationMessage color='black' />}
          contentInitialWidth='40rem'
          backgroundColor={color.white}
        >
          <AdditionInfoContent>
            <AdditionalInfoText variant='body' sizing='small'>
              {additionalInformation}
            </AdditionalInfoText>
            <RemoveButton
              variant='plain'
              sizing='small'
              onClick={onRemoveItemComment}
              aria-label={removeCommentLabel}
              data-testid='remove-comment-button'
              icon={<IconControlRemove color={color.text.strongPrimary} />}
            />
          </AdditionInfoContent>
        </AdditionInfo>
      )}
      <PriceWrapper>
        <ControlsAndPriceWrapper>
          <span data-testid='product-quantity'>
            <Quantity
              aria-label='quantity'
              type='button'
              color='neutral'
              sizing='xsmall'
              variant='filled'
              variantCollapsed='filled'
              value={quantity}
              onChange={(e) => handleChangedQuantity(e)}
            />
          </span>
          {normalUnitPrice && (
            <Price data-testid='product-price' variant='body' sizing='small'>
              x&nbsp;&nbsp;{normalPrice}
              <CoopMemberPrice
                coopMemberPrice={coopMemberPrice}
                textSize='small'
                logoSize='1rem'
                testId='product-coop-price'
              />
            </Price>
          )}
        </ControlsAndPriceWrapper>
        {normalRowTotal && (
          <Price variant='body' sizing='small' weight='bold' data-testid='product-total-price'>
            {rowTotalPrice}
          </Price>
        )}
      </PriceWrapper>
    </ProductSummaryContainer>
  )
}

export default ProductSummaryCard
