import {SDS_COLOR_BLACK, SDS_COLOR_WHITE} from '@s-group/design-system-tokens/web/tokens/colors.es6'
import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import {
  SDS_SPACE_LARGE,
  SDS_SPACE_MEDIUM,
  SDS_SPACE_SMALL,
  SDS_SPACE_XLARGE,
  SDS_SPACE_XSMALL,
  SDS_SPACE_XXLARGE,
  SDS_SPACE_XXSMALL,
  SDS_SPACE_XXXLARGE,
  SDS_SPACE_XXXSMALL,
  SDS_SPACE_XXXXLARGE,
  SDS_SPACE_XXXXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'

export const color = {
  white: SDS_COLOR_WHITE,
  black: SDS_COLOR_BLACK,
  background: {
    strongPrimary: colors.SDS_BRAND_COLOR_BACKGROUND_STRONG_PRIMARY,
    mediumPrimary: colors.SDS_BRAND_COLOR_BACKGROUND_MEDIUM_PRIMARY,
    mediumSecondary: colors.SDS_BRAND_COLOR_BACKGROUND_MEDIUM_SECONDARY,
    weakSecondary: colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_SECONDARY,
    defaultGrey: colors.SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL,
    weakGrey: colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_NEUTRAL,
    strongerGrey: colors.SDS_BRAND_COLOR_BACKGROUND_STRONGER_NEUTRAL,
    inverseGrey: colors.SDS_BRAND_COLOR_BACKGROUND_INVERSE_NEUTRAL,
    success: colors.SDS_BRAND_COLOR_BACKGROUND_SUCCESS,
    info: colors.SDS_BRAND_COLOR_BACKGROUND_INFO,
    warning: colors.SDS_BRAND_COLOR_BACKGROUND_WARNING,
    error: colors.SDS_BRAND_COLOR_BACKGROUND_ERROR,
  },
  border: {
    strongPrimary: colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY,
    mediumPrimary: colors.SDS_BRAND_COLOR_BORDER_MEDIUM_PRIMARY,
    mediumSecondary: colors.SDS_BRAND_COLOR_BORDER_MEDIUM_SECONDARY,
    strongNeutral: colors.SDS_BRAND_COLOR_BORDER_STRONG_NEUTRAL,
    defaultGrey: colors.SDS_BRAND_COLOR_BORDER_DEFAULT_NEUTRAL,
    weakGrey: colors.SDS_BRAND_COLOR_BORDER_WEAK_NEUTRAL,
    inverseGrey: colors.SDS_BRAND_COLOR_BORDER_INVERSE_NEUTRAL,
    success: colors.SDS_BRAND_COLOR_BORDER_SUCCESS,
    info: colors.SDS_BRAND_COLOR_BORDER_INFO,
    warning: colors.SDS_BRAND_COLOR_BORDER_WARNING,
    error: colors.SDS_BRAND_COLOR_BORDER_ERROR,
  },
  button: {
    textPrimary: colors.SDS_BRAND_COLOR_TEXT_INVERSE_NEUTRAL,
    textDefault: colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
    textPlain: colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
    textAlternative: colors.SDS_BRAND_COLOR_TEXT_DEFAULT_NEUTRAL,
    iconPrimary: colors.SDS_BRAND_COLOR_ELEMENT_INVERSE_NEUTRAL,
    iconDefault: colors.SDS_BRAND_COLOR_ELEMENT_STRONG_PRIMARY,
    iconPlain: colors.SDS_BRAND_COLOR_ELEMENT_STRONG_PRIMARY,
    iconAlternative: colors.SDS_BRAND_COLOR_ELEMENT_DEFAULT_NEUTRAL,
    backgroundPrimary: colors.SDS_BRAND_COLOR_BACKGROUND_STRONG_PRIMARY,
    backgroundAlternative: colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_NEUTRAL,
    borderDefault: colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY,
  },
  element: {
    strongPrimary: colors.SDS_BRAND_COLOR_ELEMENT_STRONG_PRIMARY,
    mediumPrimary: colors.SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY,
    defaultGrey: colors.SDS_BRAND_COLOR_ELEMENT_DEFAULT_NEUTRAL,
    strongGrey: colors.SDS_BRAND_COLOR_ELEMENT_STRONG_NEUTRAL,
    mediumGrey: colors.SDS_BRAND_COLOR_ELEMENT_MEDIUM_NEUTRAL,
    inverseGrey: colors.SDS_BRAND_COLOR_ELEMENT_INVERSE_NEUTRAL,
    success: colors.SDS_BRAND_COLOR_ELEMENT_SUCCESS,
    info: colors.SDS_BRAND_COLOR_ELEMENT_INFO,
    warning: colors.SDS_BRAND_COLOR_ELEMENT_WARNING,
    error: colors.SDS_BRAND_COLOR_ELEMENT_ERROR,
  },
  text: {
    strongPrimary: colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
    mediumPrimary: colors.SDS_BRAND_COLOR_TEXT_MEDIUM_PRIMARY,
    defaultGrey: colors.SDS_BRAND_COLOR_TEXT_DEFAULT_NEUTRAL,
    strongGrey: colors.SDS_BRAND_COLOR_TEXT_STRONG_NEUTRAL,
    strongerGrey: colors.SDS_BRAND_COLOR_TEXT_STRONGER_NEUTRAL,
    inverseGrey: colors.SDS_BRAND_COLOR_TEXT_INVERSE_NEUTRAL,
    success: colors.SDS_BRAND_COLOR_TEXT_SUCCESS,
    info: colors.SDS_BRAND_COLOR_TEXT_INFO,
    warning: colors.SDS_BRAND_COLOR_TEXT_WARNING,
    error: colors.SDS_BRAND_COLOR_TEXT_ERROR,
  },
}

export const fontFamily = {
  sBonusUX: 'S Bonus UX',
  sBonusDisplay: 'S Bonus Display',
}

export const spacingCustom = {
  /* 6px */
  xxsmall: '0.375rem',
  /* 14px */
  small: '0.875rem',
  /* 24px */
  medium: '1.5rem',
  /* 32px */
  large: '2rem',
  /* 40px */
  xlarge: '2.5rem',
}

export const spacing = {
  /** 0.125rem / 2px */
  xxxxsmall: SDS_SPACE_XXXXSMALL,
  /** 0.25rem / 4px */
  xxxsmall: SDS_SPACE_XXXSMALL,
  /** 0.5rem / 8px */
  xxsmall: SDS_SPACE_XXSMALL,
  /** 0.75rem / 12px */
  xsmall: SDS_SPACE_XSMALL,
  /** 1rem / 16px */
  small: SDS_SPACE_SMALL,
  /** 1.25rem / 20px */
  medium: SDS_SPACE_MEDIUM,
  /** 1.75rem / 28px */
  large: SDS_SPACE_LARGE,
  /** 2.25rem / 36px */
  xlarge: SDS_SPACE_XLARGE,
  /** 3rem / 48px */
  xxlarge: SDS_SPACE_XXLARGE,
  /** 4rem / 64px */
  xxxlarge: SDS_SPACE_XXXLARGE,
  /** 5rem / 80px */
  xxxxlarge: SDS_SPACE_XXXXLARGE,
}

export const currentPageTheme = {
  bgImage: '',
  linkColor: colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
  breakpoint: '1116px' /* typically we are providing Information component in most pages, which has given width  */,
  brandFontFamily: '',
  brandFontColor: '',
  bgColor: '',
  bgColorInverse: '',
  bgColorDeep: '',
  bgColorDeepInverse: '',
  paragraphFontColor: '',
  paragraphFontColorInverse: '',
  darkMode: false,
  contentMaxWidth: '700px',
}

export const shape = {
  borderRadius: '2px',
  borderThin: '1px solid',
}

export const remoteTheme = {
  color,
  fontFamily,
  currentPageTheme,
  spacingCustom,
  spacing,
}
export type RemoteTheme = typeof remoteTheme
export const pageTheme: {[key in 'DARK' | 'LIGHT']: RemoteTheme['currentPageTheme']} = {
  DARK: {
    ...currentPageTheme,
    brandFontFamily: fontFamily.sBonusUX,
    brandFontColor: color.white,
    bgColor: color.background.strongerGrey,
    bgColorInverse: color.background.weakSecondary,
    bgColorDeep: color.black,
    bgColorDeepInverse: color.white,
    paragraphFontColor: color.white,
    paragraphFontColorInverse: color.text.strongerGrey,
    darkMode: true,
  },
  LIGHT: {
    ...currentPageTheme,
    brandFontFamily: fontFamily.sBonusUX,
    brandFontColor: color.text.strongerGrey,
    bgColor: color.background.weakSecondary,
    bgColorInverse: color.background.strongerGrey,
    bgColorDeep: color.white,
    bgColorDeepInverse: color.black,
    paragraphFontColor: color.text.strongerGrey,
    paragraphFontColorInverse: color.white,
    darkMode: false,
  },
}
