import {createContext, Dispatch, SetStateAction} from 'react'
import {ModalBottomState} from '../Components/ModalBottom/modalBottomTypes'
import {ModalState} from '../Components/Modal/modalTypes'

export type ModalContextProps = {
  modalState: ModalState
  setModalState: Dispatch<SetStateAction<ModalState>>
  modalBottomState: ModalBottomState
  setModalBottomState: Dispatch<SetStateAction<ModalBottomState>>
}

const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export default ModalContext
