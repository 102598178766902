import {ChipGroup, ToggleChip} from '@s-group/design-system-components'
import styled from 'styled-components'
import {remoteTheme, spacingCustom} from 'theme'

export const LanguageButtonsWrapper = styled(ChipGroup)`
  height: ${spacingCustom.medium};
  display: inline-flex;
  float: right;
`

export const LanguageButtonSelection = styled(ToggleChip)`
  label {
    color: ${remoteTheme.color.text.strongPrimary};
    text-transform: uppercase;
  }

  input + label {
    border-color: ${remoteTheme.color.border.weakGrey};
  }
`
