import type {BiasModifiers, ImageProps, ImageSizeMultiplier} from './types'

const fallbackSizes = [320, 375, 425, 568]

const getBaseBiasUrl = (url?: string) => {
  if (!url) return ''
  return url.split('/').slice(0, 3).join('/')
}

const getDamIdFromBiasUrl = (biasUrl?: string) => {
  if (!biasUrl) return ''
  const match = /dam-id\/([\w*-]+)/.exec(biasUrl)
  if (match && match[1]) {
    return match[1]
  }
  return ''
}

const getModifiers = (
  props: Partial<BiasModifiers>,
  screenSize: number,
  imageSizeMultiplier: ImageSizeMultiplier = 1
) => {
  const {biasWidth, biasHeight, quality, preserveAspectRatio} = props

  const widthModifier = biasWidth ? `w${biasWidth * imageSizeMultiplier}` : `w${screenSize * imageSizeMultiplier}`
  const heightModifier = biasHeight ? `h${biasHeight * imageSizeMultiplier}` : ''

  const preserveRatio = biasWidth && biasHeight && preserveAspectRatio
  const aspectRatioModifier = preserveRatio ? '@' : ''

  const modifiers = []
  const modifierSeparator = '_'
  modifiers.push(quality ? `q${quality}` : 'q85')

  return `${widthModifier}${heightModifier}${aspectRatioModifier}`.concat(
    modifiers.map((mod) => `${modifierSeparator}${mod}`).join('')
  )
}

interface SrcAttributesProps {
  src: string
  sizes?: number[]
  webpSet?: string
  pngSet?: string
  svgSet?: string
  jpgSet?: string
}

const getSrcAttributes = (props: ImageProps) => {
  const {url, damId, sizeSet = fallbackSizes, ext, biasWidth, imageSizeMultiplier} = props

  if (url && !url.includes('dam-id')) return {src: url}

  const baseUrl = url ? getBaseBiasUrl(url) : 'https://cdn.s-cloud.fi'
  const damIdParsed = url ? getDamIdFromBiasUrl(url) : damId
  const srcAttrs: SrcAttributesProps = {
    src: `${baseUrl}/v1/assets/dam-id/${damIdParsed}`,
  }

  if (biasWidth) {
    const modifiers = getModifiers(props, biasWidth, imageSizeMultiplier)
    const getExactImageSizeSet = (extension: string) =>
      `${baseUrl}/v1/${modifiers}/assets/dam-id/${damIdParsed}.${extension}`
    if (ext) {
      // no fallbacks with explicit extension
      srcAttrs.src = getExactImageSizeSet(ext)
    } else {
      srcAttrs.webpSet = getExactImageSizeSet('webp')
      srcAttrs.pngSet = getExactImageSizeSet('png')
      srcAttrs.svgSet = getExactImageSizeSet('svg')
      srcAttrs.jpgSet = getExactImageSizeSet('jpg')
    }
  } else {
    srcAttrs.sizes = sizeSet
    const getScreenSizeSet = (extension: string, sizes: number[]) =>
      sizes
        .map(
          (size) =>
            `${baseUrl}/v1/${getModifiers(
              props,
              size,
              imageSizeMultiplier
            )}/assets/dam-id/${damIdParsed}.${extension}  ${size}w`
        )
        .join(',')

    if (ext) {
      // no fallbacks with explicit extension
      srcAttrs.src = getScreenSizeSet(ext, sizeSet)
    } else {
      srcAttrs.webpSet = getScreenSizeSet('webp', sizeSet)
      srcAttrs.pngSet = getScreenSizeSet('png', sizeSet)
      srcAttrs.svgSet = getScreenSizeSet('svg', sizeSet)
      srcAttrs.jpgSet = getScreenSizeSet('jpg', sizeSet)
    }
  }

  return srcAttrs
}

export default getSrcAttributes
