import {
  GetRestaurantQuery,
  Portion,
  PortionFieldsFragment,
  PortionOptionSectionType,
} from '@marketplace/shared-lib/graphql/graphql'
import {useTranslation} from 'react-i18next'
import {PortionSelectedIds, SelectedIds} from 'types/productCardTypes'
import {ButtonType} from 'utils/menuUtils'

export type PortionWithMenuID = Portion & {menuID: string}

export const getSelectedIds = (
  portionOptionSections?: PortionFieldsFragment['portionOptionSections']
): SelectedIds | undefined => {
  if (!portionOptionSections?.length) {
    return undefined
  }
  return portionOptionSections.map((section) => ({
    selectedSectionId: section?.id,
    selectedOptionIds: section?.portionOptions?.map((option) => option?.id),
  }))
}

export const getDefaultPortionSelections = (portion: PortionFieldsFragment): SelectedIds => {
  return portion.portionOptionSections.map((portionOptionSection) => {
    const {id, portionOptions, type} = portionOptionSection || {}
    if (!portionOptions?.length) {
      return {selectedSectionId: undefined, selectedOptionIds: undefined}
    }

    const portionOptionSelectedIds =
      portionOptions.filter((portionOption) => portionOption.isDefault).map((portionOption) => portionOption.id) ?? []

    // Size & hidden types should be always selected by default if there are options
    const selectFirstAsFallback =
      (type === PortionOptionSectionType.SIZE || type === PortionOptionSectionType.HIDDEN) &&
      !portionOptionSelectedIds?.length
    return {
      selectedSectionId: id,
      selectedOptionIds: selectFirstAsFallback ? [portionOptions[0].id] : portionOptionSelectedIds,
    }
  })
}

export const getPortionsDefaultSelections = (portions: Portion[]) => {
  return portions.map((portion) => {
    const {id: portionId} = portion
    const defaultPortionSelections = getDefaultPortionSelections(portion)
    return {[portionId]: defaultPortionSelections}
  })
}

export const getSelectedPortionOptions = (
  portion: PortionWithMenuID,
  selectedPortions?: SelectedIds
): PortionWithMenuID => {
  const portionOptionsMapped = portion.portionOptionSections.map((portionOptionSection) => {
    const portionOptions = portionOptionSection?.portionOptions?.filter(({id}) =>
      selectedPortions?.some(({selectedOptionIds}) => selectedOptionIds?.includes(id))
    )
    return {...portionOptionSection, portionOptions}
  })
  return {
    ...portion,
    portionOptionSections: portionOptionsMapped as Portion['portionOptionSections'],
  }
}

export const findPortionFromMenus = (
  portionId: string,
  menus: GetRestaurantQuery['getRestaurant']['menus'],
  menuID: string
) => {
  let selectedPortion: PortionWithMenuID | undefined
  menus?.find((menu) =>
    menu.menuSections?.find((section) =>
      section.portions.find((portion) => {
        if (portion.id === portionId && menu.id === menuID) {
          selectedPortion = {...portion, menuID: menu.id}
          return true
        }
        return false
      })
    )
  )
  return selectedPortion
}

/**
 * Get button type (radio or checkbox) based on portion option type and min/max
 * count.
 *
 * - with minCount and maxCount explicitly set to 1 means there's always one selection = radio button
 * - with sectionType SIZE it's always one selection
 * - everything else is a checkbox
 */
export const getButtonType = (
  type: PortionOptionSectionType,
  minCount?: number | null,
  maxCount?: number | null
): ButtonType => {
  if (type === PortionOptionSectionType.SIZE) return 'radio'
  if (minCount === 1 && maxCount === 1) {
    return 'radio'
  }
  return 'checkbox'
}

/**
 * Utility hook to get comma separated diet tags abbreviations
 *
 * Returns undefined for empty or undefined input. Otherwise, string with
 * comma-separated tags, e.g. "V, G, L"
 *
 * @example
 * const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()
 * // ...
 * <span>{getDietTags(portion.diet)}</span>
 */
export const useGetCommaSeparatedDietTagsAbbreviations = () => {
  const {t} = useTranslation('order')
  return (dietTags?: string[]): string | undefined => {
    if (!dietTags || dietTags.length === 0) return undefined
    return dietTags
      .map((dietTag) => {
        return t(`menu.dietTags.abbreviations.${dietTag}`)
      })
      .filter((dietTagText) => dietTagText) // filter out empty strings
      .join(', ')
  }
}

export const getPortionSelections = (portionId: string, portionsSelections: PortionSelectedIds[]) => {
  return portionsSelections.find((selection) => selection[portionId])?.[portionId]
}

export const getSelectedSizeLabel = (
  portionOptionSections: PortionFieldsFragment['portionOptionSections'],
  portionSelections: SelectedIds | undefined
) => {
  return portionOptionSections
    .filter((optionSection) => optionSection?.type === PortionOptionSectionType.SIZE)
    .map((section) => {
      const {selectedOptionIds} =
        portionSelections?.find(({selectedSectionId}) => selectedSectionId === section?.id) || {}
      return section?.portionOptions.find((option) => selectedOptionIds?.includes(option.id))?.label
    })[0]
}

export const getPortionMenuSection = (
  portionId: string,
  menus: GetRestaurantQuery['getRestaurant']['menus'],
  menuID?: string
) => {
  if (!menus) {
    return undefined
  }

  for (const menu of menus) {
    if (menuID && menuID !== menu.id) {
      continue
    }
    for (const menuSection of menu.menuSections) {
      const found = menuSection.portions.some((portion) => portion.id === portionId)
      if (found) {
        return menuSection
      }
    }
  }

  return undefined
}

export const getRecommendationsWithDefaultOptions = (portions: PortionWithMenuID[]): PortionWithMenuID[] =>
  portions.map((portion) => ({
    ...portion,
    portionOptionSections: portion.portionOptionSections.map((section) => {
      const defaults = getDefaultPortionSelections(portion)
      const optionIds = defaults.find((def) => def.selectedSectionId === section?.id)

      if (!section || !optionIds?.selectedSectionId) {
        return null
      }

      const portionOptions = section?.portionOptions.filter((option) =>
        optionIds?.selectedOptionIds?.includes(option.id)
      )

      return {
        ...section,
        id: optionIds?.selectedSectionId,
        portionOptions,
      }
    }),
  }))
