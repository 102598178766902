import {CallWaiterType} from '@marketplace/shared-lib/graphql/graphql'
import {AnalyticsEvents} from 'types/analyticsTypes'

export const mapCallWaiterEvent = (callWaiterType: CallWaiterType) => {
  if (callWaiterType === CallWaiterType.CALL_WAITER_WITH_BILL) {
    return AnalyticsEvents.REQUEST_BILL
  }
  if (callWaiterType === CallWaiterType.CALL_WAITER_WITH_BILL_SPLIT) {
    return AnalyticsEvents.REQUEST_BILL
  }
  return AnalyticsEvents.CALL_WAITER
}
