import {customerFullAge} from 'state/reactiveVariables'
import {
  remoteCustomerFullAge,
  remoteOrderNickName,
  remoteRestaurantSelectionToken,
  remoteWelcomeRecommationsShown,
} from './constants'

const removePersonalDetails = () =>
  [
    'remoteOrderNickName',
    'remoteOrderLastName',
    'remoteOrderPhoneNumber',
    'remoteOrderLoyaltyProgram',
    'remoteOrderPaymentMethod',
    'remoteOrderTermsChecked',
  ].forEach((key) => {
    localStorage.removeItem(key)
  })

/**  "Customer full age" and "welcome recommendations shown" reset on login */
export const resetCustomerDataWithoutSelectionToken = () => {
  const tableSelectionToken = localStorage.getItem(remoteRestaurantSelectionToken)
  if (!tableSelectionToken) {
    customerFullAge(undefined)
    localStorage?.removeItem(remoteCustomerFullAge)
    localStorage?.removeItem(remoteWelcomeRecommationsShown)
  }

  /** TODO: this can be removed when not needed anymore.
   * Personal details were stored in local storage previously and
   * we want to remove them from any device that has stored them.
   */
  const orderNickName = localStorage.getItem(remoteOrderNickName)
  if (orderNickName) {
    removePersonalDetails()
  }
}
