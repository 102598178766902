import {useReactiveVar} from '@apollo/client'
import {GetStoredOrdersType, useGetStoredOrdersQuery} from '@marketplace/shared-lib/graphql/graphql'
import {useHandledOrders} from '@marketplace/shared-lib/src/hooks'
import {cartID, previewMode} from 'state/reactiveVariables'
import {useToken} from 'utils/cookies'

const useHandledOrdersData = () => {
  const useHandledOrdersDataByToken = (cartToken: string) => {
    const {data, loading, error} = useGetStoredOrdersQuery({
      variables: {input: {getType: GetStoredOrdersType.CART_ORDERS, cartID: cartToken}},
      skip: !cartToken,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    })
    return {data, loading, error}
  }

  const id = useReactiveVar(cartID)
  const isPreviewMode = !!useReactiveVar(previewMode)
  const token = useToken()

  const {data, loading, error} = useHandledOrders({
    cartID: id,
    tableToken: token,
    skip: !id || isPreviewMode,
  })

  return {handledOrderData: data, handledOrderLoading: loading, handledOrderError: error, useHandledOrdersDataByToken}
}

export default useHandledOrdersData
