import {useEffect} from 'react'
import config from 'config'

/** This hook adds UserCentrix script, that provides the cookie consent features. */
export const useUCScript = () => {
  useEffect(() => {
    if (document.getElementById('usercentrics-cmp') || !config.COOKIE_CONSENT) {
      return
    }
    const script = document.createElement('script')
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'
    script.id = 'usercentrics-cmp'
    script.async = true
    document.head.appendChild(script)
    document.getElementById('usercentrics-cmp')?.setAttribute('data-settings-id', config.COOKIE_CONSENT)
  }, [])
}
