import {useGetHotelQuery} from '@marketplace/shared-lib/graphql/graphql'
import {parseSiteId} from '@marketplace/shared-lib/src/utils/idParser'

/**
 * Conditionally fetches hotel data if the provided ID is actually a hotel ID.
 *
 * Hotel IDs are prefixed with 'hotel#', e.g. 'hotel#123'.
 */
const useHotelData = (id?: string | null) => {
  const {isHotelId, parsedHotelId} = parseSiteId(id)
  const {data, loading} = useGetHotelQuery({
    variables: {input: {hotelID: parsedHotelId ?? ''}},
    skip: !isHotelId,
    fetchPolicy: 'cache-and-network',
  })

  return {
    hotelData: data?.getHotel,
    hotelLoading: loading,
  }
}

export default useHotelData
