// GQL types in staff and remote should be identical, so it doesn't matter from where we import them.
import {PortionRequest} from '@marketplace/staff/src/types/tableRequestTypes'
import {CartItem, CartItemFragment} from '../../graphql/graphql'

// Let's pick only the quantity property from the CartItem.
// Using the full CartItem type could cause issues when some GQL requests are
// missing some CartItem properties.
type CartItemForCount = Pick<CartItem, 'quantity'>

/**
 * Counts the total number of items in the cart of the given order.
 *
 * I.e. an order with 3 Heinekens and 2 Happy Joes will return 5.
 */
export const countCartItems = (cart: CartItemForCount[]): number => {
  return cart.reduce((total, item) => total + item.quantity, 0) ?? 0
}

export const deliveryPortionPickup = 'delivery-pickup'
export const deliveryPortionRoom = 'delivery-room'
export const isDeliveryPortion = (portion: PortionRequest) =>
  portion.id === deliveryPortionRoom || portion.id === deliveryPortionPickup
export const sortDeliveryLast = (cart?: CartItemFragment[]): CartItemFragment[] => {
  if (!cart) {
    return []
  }
  return [...cart].sort((a, b) => {
    if (isDeliveryPortion(a.portion)) {
      return 1
    }
    if (isDeliveryPortion(b.portion)) {
      return -1
    }
    return 0
  })
}
