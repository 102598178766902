import {IconStatusSuccess} from '@s-group/design-system-icons'
import {remoteTheme} from 'theme'

export const Success = () => (
  <IconStatusSuccess
    height={remoteTheme.spacing.xxxlarge}
    width={remoteTheme.spacing.xxxlarge}
    color={remoteTheme.color.element.success}
  />
)
