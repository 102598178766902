import {PortionFieldsFragment, PortionOptionSectionType, PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {formatPricePlainText} from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'

export const joinPortionOptions = (accumulator: string, item: string, index: number) => {
  if (!item) {
    return accumulator
  }
  if (index === 0) {
    return item
  }
  return `${accumulator}, ${item}`
}

export const getPortionOptionsText = (
  portion: Omit<PortionFieldsFragment, 'images' | 'recommendations'>,
  currentLanguage: string
) =>
  portion?.portionOptionSections?.map((section) => {
    if (section?.type === PortionOptionSectionType.HIDDEN) {
      return {portionOptionName: '', portionOptionValues: ''}
    }
    const portionOptionValues = section?.portionOptions?.flatMap((option) => {
      if (option.price && option.price.type === PriceType.ADDITIONAL) {
        return `${getLocalized(option.label, currentLanguage)} (${formatPricePlainText(
          option.price.normal ?? 0,
          true
        )})`
      }
      return getLocalized(option.label, currentLanguage)
    })
    const joinedValues =
      portionOptionValues && portionOptionValues.length > 0 ? portionOptionValues.reduce(joinPortionOptions) : ''

    const portionOptionName = getLocalized(section?.name, currentLanguage)
    return {portionOptionName, portionOptionValues: joinedValues}
  })
