import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import * as Sentry from '@sentry/react'
import {writeLocalStorage} from '@marketplace/shared-lib/src/utils/localStorage'
import useTriggerEvent from 'hooks/useTriggerEvent'
import ModalContext from 'state/ModalContext'
import {customerFullAge} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {remoteCustomerFullAge} from 'utils/constants'
import {SentryTagKeys} from 'utils/sentryUtils'
import useTriggerEventNew from '../../hooks/useTriggerEventNew'
import {ModalState} from '../Modal/modalTypes'
import {AgeDialogContent, AgeDialogHeader, ButtonsWrapper, YesNoButton} from './AgeDialogStyles'

const AgeDialog = ({paddingTop}: {paddingTop?: boolean}) => {
  const {t} = useTranslation('welcome')
  const {setModalState} = useContext(ModalContext)
  const {triggerEvent, loading} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()

  const handleCustomerFullAge = (fullAge: boolean) => {
    customerFullAge(fullAge)
    writeLocalStorage(remoteCustomerFullAge, fullAge)
    setModalState(ModalState.Closed)

    triggerEvent({
      event: AnalyticsEvents.CUSTOMER_FULL_AGE,
      action: fullAge ? AnalyticsActions.CUSTOMER_FULL_AGE_TRUE : AnalyticsActions.CUSTOMER_FULL_AGE_FALSE,
    })

    triggerEventNew?.({
      event: 'customer_full_age',
      data: {
        customer_full_age_boolean: fullAge,
      },
    })

    Sentry.setTag(SentryTagKeys.AgeConsent, fullAge)
  }

  return (
    <AgeDialogContent paddingTop={paddingTop} aria-modal role='dialog'>
      <AgeDialogHeader sizing='xxsmall' weight='medium' id='age-dialog-header'>
        {t('ageDialog.header')}
      </AgeDialogHeader>
      <ButtonsWrapper aria-labelledby='age-dialog-header'>
        <YesNoButton
          color='neutral'
          variant='outlined'
          sizing='medium'
          rounding='small'
          data-testid='set-full-age-no-button'
          disabled={loading || !triggerEventNew}
          onClick={() => handleCustomerFullAge(false)}
        >
          {t('ageDialog.no')}
        </YesNoButton>
        <YesNoButton
          color='neutral'
          variant='filled'
          sizing='medium'
          rounding='small'
          data-testid='set-full-age-yes-button'
          disabled={loading || !triggerEventNew}
          onClick={() => {
            handleCustomerFullAge(true)
          }}
        >
          {t('ageDialog.yes')}
        </YesNoButton>
      </ButtonsWrapper>
    </AgeDialogContent>
  )
}

export default AgeDialog
