import {Notification, RadioButtonGroup, Select} from '@s-group/design-system-components'
import styled from 'styled-components'

const PickupTimeWrapper = styled(Select)`
  max-width: 185px;
`

const PickupTimeDisclaimer = styled(Notification)`
  margin-top: ${({theme}) => theme.spacing.xsmall};
`

const PickupTimeSelector = styled(RadioButtonGroup)``

const OptionAsap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacingCustom.xxsmall};
`

const SpecificTimeSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export {OptionAsap, PickupTimeDisclaimer, PickupTimeSelector, PickupTimeWrapper, SpecificTimeSelector}
