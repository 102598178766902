// Tags in Sentry are key-value pairs used for filtering, searching,
// and grouping events in the dashboard.
export enum SentryTagKeys {
  /** remote/staff/backend */
  Component = 'component',
  /** true/false */
  AgeConsent = 'age_consent',
  /** fi/sv/en */
  Language = 'language',
  TableType = 'table_type',
  RestaurantId = 'restaurant_id',
  RestaurantName = 'restaurant_name',
}

const sensitiveFields = [
  'nickName',
  'lastName',
  'phoneNumber',
  // the following fields don't exist in the codebase but are included for future-proofing
  'email',
  'firstName',
] as const

/**
 * Some sanity limit to prevent ridiculously deep objects and infinite recursion.
 *
 * See also normalizeDepth config in Sentry.init().
 */
export const MAX_DEPTH = 25

/**
 * Takes an object and returns a new object with sensitive fields replaced by '*****'.
 *
 * Output type is unknown because it might be a truncated version of the input
 * data if the depth exceeds MAX_DEPTH. In practice, it's usually the same type
 * as the input data though.
 */
export const sanitizeData = <T>(data: T, depth: number = 1): unknown => {
  if (depth > MAX_DEPTH) {
    console.warn('sanitizeData: max depth reached')
    return '[TRUNCATED]'
  }
  if (Array.isArray(data)) {
    return data.map((item) => sanitizeData(item, depth + 1))
  }
  if (data && typeof data === 'object') {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        // sanitize if value is truthy (no need to sanitize empty strings, nulls, etc)
        // and key is in sensitiveFields (case-insensitive)
        sensitiveFields.some((field) => !!value && field.toLowerCase() === key.toLowerCase())
          ? '*****'
          : sanitizeData(value, depth + 1),
      ])
    )
  }
  return data
}
