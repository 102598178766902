import {medium} from '@marketplace/shared-lib/src/utils/themeVariables'
import {ChipGroup, Text, ToggleChip} from '@s-group/design-system-components'
import {Element, Link} from 'react-scroll'
import styled from 'styled-components'

const MenuHeaderWrapper = styled.div`
  display: grid;
  gap: ${({theme}) => theme.spacing.small};
`

const MenuHeader = styled.div`
  display: grid;
  gap: ${({theme}) => theme.spacing.small};
`

const MenuCategoriesWrapper = styled.div`
  display: grid;
  gap: ${({theme}) => theme.spacing.xsmall};
  padding-bottom: ${({theme}) => theme.spacing.small};
`

const Title = styled(Text)<{noMarginTop?: boolean}>`
  margin: ${({theme, noMarginTop}) => (noMarginTop ? '0' : theme.spacingCustom.large)}
    ${({theme}) => theme.spacingCustom.large} 0;
`

const Description = styled(Text)<{paddingBottom: string}>`
  padding: 0 ${({theme}) => theme.spacing.xxlarge} ${({paddingBottom}) => paddingBottom ?? 0};
`

const DietTagsDefinitionsContainer = styled(Text)`
  background-color: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.border.mediumSecondary};
  margin: ${({theme}) => theme.spacing.small};
  padding: ${({theme}) => theme.spacingCustom.medium};
  white-space: pre-line;
`

const SectionTitle = styled(Text)`
  border-top: ${({theme}) => `1px solid ${theme.color.border.mediumSecondary}`};
  padding: ${({theme}) => theme.spacingCustom.medium};
  text-transform: uppercase;
  line-height: ${({theme}) => theme.spacingCustom.medium};
`

const SectionDescription = styled(Text)`
  text-align: center;
  padding: 0 ${({theme}) => theme.spacingCustom.medium} ${({theme}) => theme.spacingCustom.medium};
`

const CategoryTitle = styled(Text)`
  padding: 0 ${({theme}) => theme.spacing.small};
  margin: 0;
  font-size: 1rem;
`

const ButtonText = styled(Text)`
  margin: 0;
  padding: 4px 0;
`

const CategoryNavigation = styled.nav`
  align-self: flex-start;
  top: 0;
  margin: 0 ${medium};
`

const CategoryList = styled(ChipGroup)`
  justify-content: center;
`

const CategoryPill = styled(ToggleChip)``

const CategoryLink = styled(Link)<{hide?: string}>`
  display: ${({hide}) => hide === 'true' && 'none'};
  :hover {
    cursor: pointer;
  }
`

const Scroller = styled.div<{top?: number}>`
  width: 100%;
  position: fixed;
  left: 0;
  top: ${({top}) => top && top}px;
  z-index: 30;
  background-color: white;
`

const CategoryNavigationFixed = styled.nav<{hide: boolean}>`
  display: ${({hide}) => (hide ? 'none' : 'block')};
  z-index: 30;
  flex: 0;
  padding: ${medium};
  overflow: auto;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  ::-webkit-scrollbar {
    display: none;
  }
  ${CategoryList} {
    flex-wrap: nowrap;
    flex: 1 0;
    width: min-content;
    overflow: auto;
    margin: 0 auto;
  }
  ${CategoryLink} {
    white-space: nowrap;
  }
`

const SectionContainerElement = styled(Element)<{name?: string}>`
  display: flex;
  flex-direction: column;
  white-space: normal;
  :first-of-type {
    ${SectionTitle} {
      border-top: none;
    }
  }
`

const OrderConfirmationWrapper = styled.div`
  display: grid;
  gap: ${({theme}) => theme.spacingCustom.medium};
  margin: 0 ${({theme}) => theme.spacingCustom.medium};
`

export {
  ButtonText,
  CategoryLink,
  CategoryList,
  CategoryNavigation,
  CategoryNavigationFixed,
  CategoryPill,
  CategoryTitle,
  Description,
  DietTagsDefinitionsContainer,
  MenuCategoriesWrapper,
  MenuHeader,
  MenuHeaderWrapper,
  OrderConfirmationWrapper,
  Scroller,
  SectionContainerElement,
  SectionDescription,
  SectionTitle,
  Title,
}
