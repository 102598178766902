import {Button} from '@s-group/design-system-components'
import {IconNavigationClose} from '@s-group/design-system-icons'
import FloatingCart from 'Components/Cart/FloatingCart'
import Footer from 'Components/Footer/Footer'
import Modal from 'Components/Modal/Modal'
import useModalToggler from 'hooks/useModalToggler'
import {MouseEventHandler} from 'react'
import {Outlet} from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import ModalBottom from '../ModalBottom/ModalBottom'
import {MainContent, MainWrapper, StyledToast} from './LayoutStyles'

const CloseButton = ({closeToast}: {closeToast: MouseEventHandler<HTMLSpanElement>}) => (
  <Button variant='plain' color='neutral' onClick={closeToast}>
    <IconNavigationClose />
  </Button>
)

const Layout = () => {
  // Global listener that opens modals when certain criteria is met
  useModalToggler()

  return (
    <MainWrapper>
      <StyledToast closeButton={CloseButton} draggable={false} icon={false} closeOnClick={false} hideProgressBar />
      <MainContent>
        <Outlet />
      </MainContent>
      <Footer />
      <FloatingCart />
      <Modal />
      <ModalBottom />
    </MainWrapper>
  )
}

export default Layout
