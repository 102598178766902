import {PortionFieldsFragment} from '@marketplace/shared-lib/graphql/graphql'
import Image from '@marketplace/shared-lib/src/UI/Image/Image'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import {IconControlPlus} from '@s-group/design-system-icons'
import {useTranslation} from 'react-i18next'
import {getLowestPortionPrice} from '../../utils/portionPriceUtils'
import CoopMemberPrice from '../Price/CoopMemberPrice'
import {
  AddToCartButton,
  ProductRecommendationBottomRow,
  ProductRecommendationCardWrapper,
  ProductRecommendationDietTags,
  ProductRecommendationInfoWrapper,
  ProductRecommendationPictureWrapper,
  ProductRecommendationPrice,
  ProductRecommendationTitle,
} from './ProductRecommendationCardStyles'

interface ProductRecommendationCardProps {
  portion: PortionFieldsFragment
  onAddItem: () => void
  loading: boolean
  ctaText?: string
}

const ProductRecommendationCard = ({portion, onAddItem, loading, ctaText}: ProductRecommendationCardProps) => {
  const {i18n, t} = useTranslation('order')
  const locale = i18n.language
  const title = getLocalized(portion.name, locale)
  const buttonLabel: string = ctaText ?? t('menu.add')
  const buttonAriaLabel: string = ctaText ?? t('menu.addToOrder')
  const dietTags: string = portion.diet
    .map((dietTag) => t(`menu.dietTags.abbreviations.${dietTag}`))
    .filter((dietTagText) => dietTagText) // filter out empty strings
    .join(', ')
  const {lowestNormal, lowestCoop} = getLowestPortionPrice(portion)
  const {url: imageUrl, text: imageAlt} = portion.images[0] || {}

  return (
    <ProductRecommendationCardWrapper data-testid={`product-recommendation-card-${portion.id}`}>
      {imageUrl && (
        <ProductRecommendationPictureWrapper>
          <Image biasWidth={360} url={imageUrl} alt={getLocalized(imageAlt, locale)} />
        </ProductRecommendationPictureWrapper>
      )}
      <ProductRecommendationInfoWrapper>
        <ProductRecommendationTitle
          data-testid='product-recommendation-card-title'
          variant='body'
          sizing='small'
          weight='medium'
        >
          {title}
          {dietTags !== '' && <ProductRecommendationDietTags>{` (${dietTags})`}</ProductRecommendationDietTags>}
        </ProductRecommendationTitle>
        <ProductRecommendationBottomRow>
          <ProductRecommendationPrice>
            <Text
              variant='body'
              sizing='medium'
              weight={!lowestCoop ? 'bold' : 'regular'}
              data-testid='product-recommendation-card-normal-price'
            >
              {formatPrice({price: lowestNormal})}
            </Text>
            <CoopMemberPrice
              testId='product-recommendation-card-coop-price'
              logoSize='1rem'
              coopMemberPrice={lowestCoop}
              textSize='medium'
            />
          </ProductRecommendationPrice>
          <AddToCartButton
            rounding='small'
            color='neutral'
            variant='tonal'
            sizing='xsmall'
            onClick={onAddItem}
            disabled={loading}
            aria-label={buttonAriaLabel}
            icon={<IconControlPlus size='16' />}
          >
            {buttonLabel}
          </AddToCartButton>
        </ProductRecommendationBottomRow>
      </ProductRecommendationInfoWrapper>
    </ProductRecommendationCardWrapper>
  )
}

export default ProductRecommendationCard
