import {css} from 'styled-components'

/** 0.1rem 1.6px ≈ 2px */
const xxsmall = css`
  ${({theme}) => theme.spacing.xxxxsmall}
`

/** 0.5rem 8px */
const xsmall = css`
  ${({theme}) => theme.spacing.xxsmall}
`

/** 0.75rem 12px */
const small = css`
  ${({theme}) => theme.spacing.xsmall}
`

/** 0.875rem 14px */
const smedium = css`
  ${({theme}) => theme.spacingCustom.small}
`

/** 1rem 16px  */
const medium = css`
  ${({theme}) => theme.spacing.small}
`

/** 1.25rem 20px  */
const xmedium = css`
  ${({theme}) => theme.spacing.medium}
`

/** 1.5rem 24px  */
const large = css`
  ${({theme}) => theme.spacingCustom.medium}
`

/** 2rem 32px  */
const xlarge = css`
  ${({theme}) => theme.spacingCustom.large}
`

/** 2.5rem 40px  */
const xxlarge = css`
  ${({theme}) => theme.spacingCustom.xlarge}
`

export {large, medium, small, smedium, xlarge, xmedium, xsmall, xxlarge, xxsmall}
