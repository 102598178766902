import {Button, Notification, Text} from '@s-group/design-system-components'
import styled from 'styled-components'

const getBorderWidth = (size: string | undefined) => {
  if (size === 'small') {
    return '1px'
  }
  if (size === 'large') {
    return '2px'
  }
  return '0'
}

export const OrderSummaryWrapper = styled.section<{marginTop?: boolean}>`
  margin-top: ${({marginTop, theme}) => (marginTop ? theme.spacingCustom.medium : 0)};
  background-color: ${({theme}) => theme.color.white};
  letter-spacing: 1px;
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacingCustom.medium}
    ${({theme}) => theme.spacingCustom.medium};
`

export const OrderSummaryRow = styled.div<{borderTop?: string}>`
  border-top: ${({borderTop}) => getBorderWidth(borderTop)} solid ${({theme}) => theme.color.black};
  padding: ${({theme, borderTop}) => {
    return borderTop === undefined ? `0 0 ${theme.spacing.xsmall}` : `${theme.spacing.xsmall} 0`
  }};
  text-align: left;
`

export const OrderConfirmationDetailsRow = styled.div<{firstRow?: boolean}>`
  display: flex;
  flex-direction: column;
  border-top: ${({firstRow}) => (firstRow ? '1px' : '0')} solid ${({theme}) => theme.color.black};
  padding: ${({theme, firstRow}) => {
    return !firstRow ? `0 0 ${theme.spacing.xsmall}` : `${theme.spacing.xsmall} 0`
  }};
  text-align: left;
`

export const OrderHeading = styled.span`
  padding-bottom: ${({theme}) => theme.spacing.small};
  display: flex;
  justify-content: space-between;
`

export const AdditionInfo = styled(Notification)<{backgroundColor: string}>`
  display: flex;
  align-items: flex-start;
  margin-top: ${({theme}) => theme.spacing.small};
  background-color: ${({backgroundColor}) => backgroundColor || undefined};
  padding: ${({theme}) => theme.spacing.xxsmall} ${({theme}) => theme.spacingCustom.xxsmall};
`

export const AdditionInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RemoveButton = styled(Button)`
  padding: 0;
  display: contents;
`

export const AdditionalInfoText = styled(Text)`
  padding-top: ${({theme}) => theme.spacing.xxxxsmall};
`

export const OrderInfoHeading = styled(Text)``
export const OrderInfoText = styled(Text)`
  margin: 0;
`

export const FirstRowBiggerFont = styled(Text)`
  display: block;
  padding-bottom: ${({theme}) => theme.spacing.small};
  text-align: left;
`

export const PriceRow = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TotalRowFont = styled(Text)`
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
`

export const QuantityAndUnitPriceContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  align-items: center;
`

export const Multiplier = styled(Text)`
  margin: 0 ${({theme}) => theme.spacing.xxsmall};
`

export const PortionOptionWrapper = styled.div`
  padding-bottom: ${({theme}) => theme.spacing.xxsmall};
`
