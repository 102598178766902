import type {GetHotelQuery, GetRestaurantQuery} from '@marketplace/shared-lib/graphql/graphql'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {useTranslation} from 'react-i18next'
import {SiteLocation, SiteNameHeading, SiteNameWrapper} from './SiteInfoStyles'

type SiteInfoProps = {
  restaurantData?: GetRestaurantQuery
  hotelData?: GetHotelQuery['getHotel']
  /**
   * By default, location is used as the subheader.
   *
   * This can be overridden with this prop.
   */
  subheaderOverride?: string
}

export const SiteInfo = ({restaurantData, hotelData, subheaderOverride}: SiteInfoProps) => {
  const {i18n} = useTranslation()
  const locale = i18n.language

  let name = ''
  let location = ''
  if (restaurantData) {
    const {name: restaurantName, address} = restaurantData.getRestaurant
    name = getLocalized(restaurantName, locale)
    location = getLocalized(address?.municipality, locale)
  }
  if (hotelData) {
    name = getLocalized(hotelData.name, locale)
  }

  return (
    <SiteNameWrapper data-testid='site-info'>
      <SiteNameHeading data-testid='site-name' translate='no' variant='display' sizing='xxxxsmall' weight='semibold'>
        {name}
      </SiteNameHeading>
      <SiteLocation data-testid='site-location' translate='no' variant='body' sizing='small' transform='caption'>
        {subheaderOverride ?? location}
      </SiteLocation>
    </SiteNameWrapper>
  )
}
