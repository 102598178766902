import {MainWrapper} from 'Components/Layout'
import {MainContent} from 'Components/Layout/LayoutStyles'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useNavigate, useParams} from 'react-router'
import {previewMode} from 'state/reactiveVariables'
import {tokenCookieKey} from 'utils/constants'
import {getCookieExpiryTime} from 'utils/cookies'
import {resetCustomerDataWithoutSelectionToken} from 'utils/customer'
import View404 from 'views/404/404'
import useTableData from '../../hooks/useTableData'

/**
 * Component to handle the UUID token in the URL. Basically, user lands here
 * after scanning the QR code. A cookie is set with the token and user is
 * redirected to the home page.
 * If token does not return any data, a 404 page is shown.
 */
const TokenHandling = (): JSX.Element => {
  // Set preview mode to false always if cookie is read
  previewMode(false)
  const [, setCookie] = useCookies([tokenCookieKey])
  const navigate = useNavigate()
  const {token} = useParams() || {}
  const {data, loading, error} = useTableData(token)

  useEffect(() => {
    if (token && data) {
      resetCustomerDataWithoutSelectionToken()
      // Store the table data token in cookie, not the URL param token
      // as that could be a redirect table token
      const storedToken = data.getTable.token
      const expiryTime = getCookieExpiryTime(data.getTable.tableType)
      setCookie(tokenCookieKey, storedToken, {expires: expiryTime})
      // Keep the query params for analytics
      navigate(`/${window.location.search || ''}`, {replace: true})
    }
  }, [navigate, setCookie, token, data])

  return (
    <MainWrapper>
      <MainContent>
        {loading && <LoadingScreen />}
        {error && <View404 />}
      </MainContent>
    </MainWrapper>
  )
}

export default TokenHandling
