import {useReactiveVar} from '@apollo/client'
import {triggerEvent} from 'hooks/useDataLayer'
import {cartID} from 'state/reactiveVariables'
import {DataLayerModel} from 'types/analyticsTypes'
import useRestaurantContext from './useRestaurantContext'

export type StrippedDataLayerModel = Omit<DataLayerModel, 'restaurant_name' | 'cart_id' | 'table' | 'table_type'>

// Hook to be used for sending data to dataLayer
// Automatically enriches the data with 'restaurant_name', 'cart_id', 'table' and 'table_type'
const useTriggerEvent = () => {
  const {tableData, tableLoading, restaurantData, restaurantLoading} = useRestaurantContext()
  const {tableType, tableID} = tableData?.getTable || {}
  const currentCartID = useReactiveVar(cartID) ?? undefined

  const onTriggerEvent = (eventData: StrippedDataLayerModel) => {
    const event: DataLayerModel = {
      event: eventData.event,
      action: eventData.action,
      restaurant_name: restaurantData?.getRestaurant.name.fi,
      cart_id: currentCartID,
      table: tableID,
      table_type: tableType,
      items: eventData.items,
    }

    const isTestTable = tableData?.getTable.isTestTable
    const isTestRestaurant = restaurantData?.getRestaurant.isTestRestaurant

    if (!isTestTable && !isTestRestaurant) {
      triggerEvent(event)
    }
  }

  const loading = !tableData || !restaurantData || tableLoading || restaurantLoading

  return {triggerEvent: onTriggerEvent, loading}
}

export default useTriggerEvent
