import {Layout} from 'Components/Layout'
import RestaurantIDHandling from 'Components/RestaurantIdHandling/RestaurantIdHandling'
import TokenHandling from 'Components/TokenHandling/TokenHandling'
import {useUCScript} from 'hooks/useUCScript'
import {Navigate, Route, Routes} from 'react-router'
import {Remote} from 'views/Remote'
import {Menu, Order, Summary, ThankYou} from 'views/order'
import OrderHistory from 'views/order/orderHistoryView/OrderHistory'
import View404 from 'views/404/404'
import {AppRoute} from './routeConstants'

const RemoteRoutes = () => {
  useUCScript()
  return (
    <Routes>
      <Route path={AppRoute.Login} element={<TokenHandling />} />
      <Route path={AppRoute.MenuPreview} element={<RestaurantIDHandling />} />
      <Route element={<Layout />}>
        <Route index element={<Remote />} />
        <Route path={AppRoute.OrderHistory} element={<OrderHistory />} />
        <Route path={AppRoute.OrderFlow} element={<Order />}>
          <Route path='' element={<Navigate to={AppRoute.Menu} replace />} />
          <Route path={AppRoute.Menu} element={<Menu />} />
          <Route path={AppRoute.Summary} element={<Summary />} />
          <Route path={`${AppRoute.OrderConfirmation}/${AppRoute.OrderId}`} element={<ThankYou />} />
        </Route>
        <Route path='*' element={<View404 />} />
      </Route>
    </Routes>
  )
}

export default RemoteRoutes
