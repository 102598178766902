import {Loader} from 'Icons'
import {LoaderWrapper} from './LoadingScreenStyles'

export const loadingScreenTestId = 'loading-screen'

const LoadingScreen = (): JSX.Element => {
  return (
    <LoaderWrapper data-testid={loadingScreenTestId}>
      <Loader />
    </LoaderWrapper>
  )
}
export default LoadingScreen
