/**
 * restaurantID field in table and the restaurantIDs field in Cognito user
 * attributes can contain a hotel ID or a restaurant ID.
 *
 * Restaurant IDs are strings with just a number, e.g. '123'.
 *
 * Hotel IDs are prefixed with 'hotel#', e.g. 'hotel#123'.
 */
export const parseSiteId = (id?: string | null) => {
  const isHotelId = id?.startsWith('hotel#') ?? false

  return {
    isHotelId,
    parsedHotelId: isHotelId ? id?.replace('hotel#', '') : undefined,
    isRestaurantId: !isHotelId && !!id,
    restaurantID: isHotelId || !id ? undefined : id,
  }
}
