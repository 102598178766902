import styled from 'styled-components'

export const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 258px;
`

export const HeroArea = styled.picture`
  width: 150%;
  display: block;
  margin-left: -25%;
  img {
    width: 100%;
  }
`

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  left: 50%;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  top: calc(50% - 25px);
  z-index: 2;
  width: 100%;
`

/** Container for a logo in a white circle */
export const LogoContainerWithBackground = styled(LogoContainer)`
  background: ${({theme}) => theme.color.white};
  border-radius: 50%;
  height: 140px;
  width: 140px;
`

export const Logo = styled.img`
  max-width: 70%;
`

export const HeroBlender = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, rgba(40, 40, 40, 0) 0%, ${({theme}) => theme.color.background.strongerGrey} 100%);
  mix-blend-mode: normal;
  z-index: 1;
`
