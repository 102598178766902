import {useReactiveVar} from '@apollo/client'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {IconNavigationClose} from '@s-group/design-system-icons'
import LanguageMenu from 'Components/LanguageMenu/LanguageMenu'
import {Chevron, ChevronOrientation} from 'Icons'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {previewMode, previousCartIDsVar} from 'state/reactiveVariables'
import {remoteTheme} from 'theme'
import {remoteCartID, remoteRestaurantSelectionToken} from 'utils/constants'
import {getAllOrders} from 'utils/order'
import DefaultHeader from '../DefaultHeader/DefaultHeader'
import {RestaurantTitle} from '../RestaurantTitle'
import {BackButton} from './OrderNavigationStyles'

const {Menu, Summary, Home} = NavigationRoute

const navigations = [
  {currentPath: Menu, navigationPath: Home, icon: 'back'},
  {currentPath: Home, navigationPath: Home, icon: 'back'},
  {currentPath: Summary, navigationPath: Menu, icon: 'back'},
]

const getNavigationPath = (currentPath: string) => {
  const navProps = navigations.find((navigation) => navigation.currentPath.toString() === currentPath)
  return navProps ?? {currentPath, icon: 'x', navigationPath: Home}
}

const OrderNavigation = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {icon, navigationPath} = getNavigationPath(pathname)
  const {restaurantData, tableData, cartData, handledOrderData} = useRestaurantContext()
  const {id: restaurantID} = restaurantData?.getRestaurant || {}
  const {tableType} = tableData?.getTable || {}

  const isPreviewMode = useReactiveVar(previewMode)
  const previousCartIDs = useReactiveVar(previousCartIDsVar)
  const savedRestaurantSelectionToken = localStorage.getItem(remoteRestaurantSelectionToken)
  const currentCartID = localStorage.getItem(remoteCartID)
  const previousRestaurantIDs = previousCartIDs.flatMap((id) => Object.keys(id))

  const cartID = cartData?.getCart.id
  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)
  const hasOrders = (pendingOrders?.length ?? 0) + (handledOrders?.length ?? 0) + (ordersInProgress?.length ?? 0) > 0

  // tableTypes PICKUP and ROOM should not have navigation option from Menu to Home page if there are no orders
  const noReturnPath =
    (tableType === TableType.PICKUP || tableType === TableType.ROOM) && !hasOrders && pathname === Menu.toString()

  if (noReturnPath) {
    return <DefaultHeader />
  }

  const handleNavigate = () => {
    if (pathname === Home.toString() && savedRestaurantSelectionToken && !isPreviewMode) {
      if (restaurantID && !previousRestaurantIDs.includes(restaurantID)) {
        previousCartIDsVar([...previousCartIDs, {[restaurantID]: currentCartID}])
      }
      navigate(`${navigationPath}${savedRestaurantSelectionToken}`)
    } else if (
      pathname === Menu.toString() &&
      !hasOrders &&
      savedRestaurantSelectionToken &&
      tableType === TableType.ROOM
    ) {
      navigate(`${navigationPath}${savedRestaurantSelectionToken}`)
    } else if (!isPreviewMode) {
      navigate(navigationPath)
    }
  }

  const displayIcon =
    icon === 'x' ? (
      <IconNavigationClose color={remoteTheme.color.white} size='24' />
    ) : (
      <Chevron orientation={ChevronOrientation.LEFT} size='24' color={remoteTheme.color.white} />
    )

  return (
    <>
      <BackButton
        variant='plain'
        color='neutral'
        sizing='xsmall'
        rounding='small'
        aria-label={t('backButtonAriaLabel')}
        onClick={handleNavigate}
        icon={displayIcon}
      />
      <RestaurantTitle />
      <LanguageMenu />
    </>
  )
}

export default OrderNavigation
