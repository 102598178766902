import {InMemoryCache} from '@apollo/client'
import generatedIntrospection from '@marketplace/shared-lib/graphql/graphql'

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
  typePolicies: {
    getTables: {
      keyFields: ['input'],
    },

    Table: {
      keyFields: ['token'],
      fields: {
        state: {
          merge(existing, incoming) {
            return {...existing, ...incoming}
          },
        },
      },
    },

    // cart in order should not update after it's submitted
    // still make sure it's always the incoming
    Order: {keyFields: false},
    BundledOrders: {keyFields: false},

    // Do not cache Portion or PortionOptionSection
    // portionOptions change in PortionOptionSection and lead to cache issues
    Portion: {keyFields: false},
    PortionOption: {keyFields: false},
    PortionOptionSection: {keyFields: false},

    RestaurantFeaturesOutput: {
      keyFields: ['restaurantID'],
    },
  },
})

export default cache
