import {formatDateString} from '@marketplace/shared-lib/src/utils/timeUtils'
import {Trans, useTranslation} from 'react-i18next'
import {OrderProps} from 'utils/order'
import {
  FirstRowBiggerFont,
  OrderConfirmationDetailsRow,
  OrderInfoHeading,
  OrderInfoText,
  OrderSummaryWrapper,
} from './ThankYouStyles'

export const OrderConfirmationPickup = ({order}: {order: OrderProps}) => {
  const {t} = useTranslation('order')
  const pickupTime = formatDateString({dateString: order.pickupTime?.time || '', onlyTime: true})

  return (
    <OrderSummaryWrapper>
      <FirstRowBiggerFont variant='heading' sizing='xxsmall' weight='bold'>
        {t('summary.orderInfo')}
      </FirstRowBiggerFont>
      <OrderConfirmationDetailsRow firstRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.ordererNameText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {order.nickName} {order.lastName}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.phoneNumberText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {order.phoneNumber}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.pickupTime')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {order.pickupTime?.asap ? (
            <Trans
              i18nKey='summary.asapTitle'
              ns='order'
              values={{delay: 20}}
              components={{br: <span style={{padding: '2px'}} />}}
            />
          ) : (
            pickupTime
          )}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
    </OrderSummaryWrapper>
  )
}
