import {createContext} from 'react'
import {
  GetCartQuery,
  GetRestaurantFeaturesQuery,
  GetRestaurantQuery,
  GetStoredOrdersQuery,
  GetTableQuery,
} from '@marketplace/shared-lib/graphql/graphql'
import {ApolloError} from '@apollo/client'

export type RestaurantContextProps = {
  cartData?: GetCartQuery
  cartLoading: boolean
  cartError?: ApolloError
  handledOrderData?: GetStoredOrdersQuery
  handledOrderLoading: boolean
  handledOrderError?: ApolloError
  restaurantFeatures?: GetRestaurantFeaturesQuery['getRestaurantFeatures']['features']
  restaurantFeaturesLoading: boolean
  restaurantFeaturesError?: ApolloError
  tableData?: GetTableQuery
  tableLoading: boolean
  restaurantData?: GetRestaurantQuery
  restaurantLoading: boolean
}

const RestaurantContext = createContext<RestaurantContextProps>({} as RestaurantContextProps)

export default RestaurantContext
