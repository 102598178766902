import {ReactNode, useMemo, useState} from 'react'
import {ModalProvider as StyledModalProvider} from 'styled-react-modal'
import {ModalBottomState} from '../Components/ModalBottom/modalBottomTypes'
import ModalContext from './ModalContext'
import {ModalState} from '../Components/Modal/modalTypes'

// Provides modal and modal bottom states and mutation functions as well as styled-react-modal
const ModalProvider = ({children}: {children: ReactNode}) => {
  const [modalState, setModalState] = useState<ModalState>(ModalState.Closed)
  const [modalBottomState, setModalBottomState] = useState<ModalBottomState>(ModalBottomState.Closed)

  const value = useMemo(
    () => ({
      modalState,
      setModalState,
      modalBottomState,
      setModalBottomState,
    }),
    [modalState, modalBottomState]
  )

  return (
    <ModalContext.Provider value={value}>
      <StyledModalProvider>{children}</StyledModalProvider>
    </ModalContext.Provider>
  )
}

export default ModalProvider
