import {Heading, Notification} from '@s-group/design-system-components'
import styled from 'styled-components'

const OrderHistoryTitleWrapper = styled(Heading)`
  line-height: ${({theme}) => theme.spacingCustom.medium};
  margin: ${({theme}) => theme.spacing.xlarge} ${({theme}) => theme.spacingCustom.medium}
    ${({theme}) => theme.spacingCustom.medium};
`

const OrderHistoryItemWrapper = styled.section`
  background-color: ${({theme}) => theme.color.white};
`
const OrderStatus = styled(Notification)`
  align-items: center;
  margin: 0 ${({theme}) => theme.spacing.xxxsmall};
  border-top: solid ${({theme}) => theme.color.white} ${({theme}) => theme.spacing.xxxsmall};
`

const ThankYouWrapper = styled.div`
  margin: 0 auto;
  gap: ${({theme}) => theme.spacing.xsmall};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${({theme}) => theme.currentPageTheme.contentMaxWidth};
  width: 100%;
  padding-bottom: 2rem;
`

export {OrderHistoryItemWrapper, OrderHistoryTitleWrapper, OrderStatus, ThankYouWrapper}
