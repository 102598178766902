import config from 'config'
import * as Sentry from '@sentry/react'
import {currentEnv} from '@marketplace/shared-lib/src/utils/envUtils'
import {sanitizeData, SentryTagKeys} from 'utils/sentryUtils'

const initSentry = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: currentEnv,
    integrations: [
      // By default React logs all errors to the console, even if you are using a
      // React error boundary. Therefore, error boundary is not needed to capture
      // those errors.
      // https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole/
      // https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
      Sentry.captureConsoleIntegration({levels: ['error', 'warn']}),
    ],

    // Default object depth is 3, but we want to see more of the objects since
    // we are dealing with complex objects.
    // Data beyond this depth will be truncated to something like "[Object]".
    // See also sanitizeData().
    normalizeDepth: 25,

    beforeSend: (event) => {
      // Discard errors with "Support for defaultProps will be removed" because
      // they are a known issue in React 18 with some third-party libraries.
      if (
        event.exception &&
        event.exception.values &&
        event.exception.values.some((exception) =>
          exception.value?.includes('Support for defaultProps will be removed')
        )
      ) {
        return null
      }

      const updatedTags = {
        ...(event.tags ?? {}),
        [SentryTagKeys.Component]: 'remote',
        [SentryTagKeys.AgeConsent]: event.tags?.[SentryTagKeys.AgeConsent] ?? false,
        [SentryTagKeys.Language]: event.tags?.[SentryTagKeys.Language] ?? 'unknown',
        [SentryTagKeys.TableType]: event.tags?.[SentryTagKeys.TableType] ?? 'unknown',
        [SentryTagKeys.RestaurantId]: event.tags?.[SentryTagKeys.RestaurantId] ?? 'unknown',
        [SentryTagKeys.RestaurantName]: event.tags?.[SentryTagKeys.RestaurantName] ?? 'unknown',
      }

      // sanitize sensitive data before sending it to Sentry
      const sanitizedData = event.request?.data ? sanitizeData(event.request.data) : event.request?.data

      return {
        ...{
          ...event,
          request: event.request && {
            ...event.request,
            data: sanitizedData,
          },
        },
        tags: updatedTags,
      }
    },
  })
}

// Initialize Sentry if it's enabled for the current environment.
if (config.SENTRY_ENABLED) {
  // Let's be extra cautious when we're globally initializing something and
  // catch any errors that might occur.
  try {
    initSentry()
  } catch (error) {
    console.error('Error initializing Sentry:', error)
  }
}
