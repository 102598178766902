import {CallWaiterType, GetTableQuery, TableStatusType} from '@marketplace/shared-lib/graphql/graphql'
import {Button, Notification, Text} from '@s-group/design-system-components'
import {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

type PendingRequestsProps = {
  tableActionsDisabled: boolean
  state: GetTableQuery['getTable']['state']
  cancel?: () => void
}

const PendingRequests = ({state, cancel, tableActionsDisabled}: PendingRequestsProps) => {
  const {t} = useTranslation('remotePage')
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (cancelButtonRef.current) {
      window.setTimeout(() => cancelButtonRef.current?.focus(), 300)
    }
  }, [])

  const resolveTranslations = (): {header: string; description: string; cancelText: string} => {
    switch (state.callWaiterType) {
      case CallWaiterType.CALL_WAITER_WITH_BILL_SPLIT:
        return {
          header: t('billRequested'),
          description: t('billRequestedDescriptionSplit'),
          cancelText: t('cancelRequestedBill'),
        }
      case CallWaiterType.CALL_WAITER_WITH_BILL:
        return {
          header: t('billRequested'),
          description: t('billRequestedDescriptionSingle'),
          cancelText: t('cancelRequestedBill'),
        }
      case CallWaiterType.GENERIC:
      default:
        return {
          header: t('waiterCallSent'),
          description: t('waiterCallSentDescription'),
          cancelText: t('cancel'),
        }
    }
  }

  const {header, description, cancelText} = resolveTranslations()
  const showCancel = !tableActionsDisabled && state.status !== TableStatusType.IN_PROGRESS

  return (
    <Notification
      status='info'
      variant='tonal'
      alignment='center'
      sizing='small'
      contentInitialWidth='15rem'
      close={false}
      icon={false}
      header={
        <Text variant='body' sizing='medium' weight='medium' tagName='h3'>
          {header}
        </Text>
      }
      trailer={
        showCancel && (
          <Button
            color='primary'
            rounding='small'
            variant='plain'
            sizing='small'
            onClick={cancel}
            data-testid='cancel-waiter-call'
            role='button'
          >
            {cancelText}
          </Button>
        )
      }
    >
      <Text>{description}</Text>
    </Notification>
  )
}

export default PendingRequests
