import useCart from 'hooks/useCart'
import useHandledOrdersData from 'hooks/useHandledOrdersData'
import useRestaurantData from 'hooks/useRestaurantData'
import useRestaurantFeatures from 'hooks/useRestaurantFeatures'
import useTableData from 'hooks/useTableData'
import {ReactNode, useMemo} from 'react'
import RestaurantContext from 'state/RestaurantContext'

const RestaurantProvider = ({children}: {children: ReactNode}) => {
  const {data: tableData, loading: tableLoading} = useTableData()
  const {restaurantFeatures, restaurantFeaturesLoading, restaurantFeaturesError} = useRestaurantFeatures()
  const {data: restaurantData, loading: restaurantLoading} = useRestaurantData()
  const {cartData, cartLoading, cartError} = useCart()
  const {handledOrderData, handledOrderLoading, handledOrderError} = useHandledOrdersData()

  const value = useMemo(
    () => ({
      cartData,
      cartLoading,
      cartError,
      handledOrderData,
      handledOrderLoading,
      handledOrderError,
      restaurantFeatures,
      restaurantFeaturesLoading,
      restaurantFeaturesError,
      tableData,
      tableLoading,
      restaurantData,
      restaurantLoading,
    }),
    [
      cartData,
      cartLoading,
      cartError,
      handledOrderData,
      handledOrderLoading,
      handledOrderError,
      restaurantFeatures,
      restaurantFeaturesLoading,
      restaurantFeaturesError,
      tableData,
      tableLoading,
      restaurantData,
      restaurantLoading,
    ]
  )

  return <RestaurantContext.Provider value={value}>{children}</RestaurantContext.Provider>
}

export default RestaurantProvider
