import {TableStatusType} from '@marketplace/shared-lib/graphql/graphql'
import {areGlobalTableActionsEnabled} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Button, Notification, Text} from '@s-group/design-system-components'
import {IconContentDocumentHistory} from '@s-group/design-system-icons'
import {SiteInfo} from 'Components/SiteInfo'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import type {RestaurantContextProps} from 'state/RestaurantContext'
import {createPhoneHref} from 'utils/misc'
import {getAllOrders} from 'utils/order'
import Feedback from '../../Components/Feedback/Feedback'
import useRestaurantContext from '../../hooks/useRestaurantContext'
import {ContentWrapper, PhoneNumberLink, PromptContent, RoomNumberText} from './RestaurantMainContentStyles'

type RoomMainContentProps = {
  restaurantData: NonNullable<RestaurantContextProps['restaurantData']>
  /** note that in the hotel room context, this is the room number, not a literal table id */
  tableID: string
  restaurantFeatures: NonNullable<RestaurantContextProps['restaurantFeatures']>
}
const RoomMainContent = ({restaurantData, tableID, restaurantFeatures}: RoomMainContentProps) => {
  const {t} = useTranslation('remotePage')
  const navigate = useNavigate()
  const {tableActions, tableActionsAutomatic, roomService, lastCallBuffer} = restaurantFeatures

  const {tableData, cartData, handledOrderData} = useRestaurantContext()
  const table = tableData?.getTable
  const cartID = cartData?.getCart.id

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions,
    tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer,
  })

  // TODO: refactor this to be part of a shared util func that checks if service is open, in the future
  const roomServiceDisabled = !roomService.deliveryEnabled && !roomService.pickupEnabled
  const actionsDisabled = roomServiceDisabled || !restaurantFeatures?.quickOrder
  // Table specific settings override only if they are explicitly switched off
  const tableDisabled = table?.state.status === TableStatusType.DISABLED || table?.state.quickOrder === false
  const orderingDisabled = !tableActionsEnabled || actionsDisabled || tableDisabled

  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)
  const hasOrders = (pendingOrders?.length ?? 0) + (handledOrders?.length ?? 0) + (ordersInProgress?.length ?? 0) > 0

  const navigateToMenu = () => navigate(NavigationRoute.Menu)

  const restaurantPhoneNumber = restaurantData.getRestaurant.contact?.primaryPhone?.number

  // Take user directly to menu after inputting room number if they don't have previous orders
  useEffect(() => {
    if (!hasOrders && !orderingDisabled) {
      navigate(NavigationRoute.Menu)
    }
  }, [hasOrders, orderingDisabled, navigate])

  const menuButtonText = orderingDisabled ? `🍔 ${t('seeTheMenus')}` : `${t('startOrdering')}`

  return (
    <ContentWrapper>
      <SiteInfo restaurantData={restaurantData} subheaderOverride={t('roomServiceSubheader')} />
      <PromptContent>
        <RoomNumberText variant='body' sizing='small' transform='caption'>
          {t('roomNumber')}: <strong>{tableID}</strong>
        </RoomNumberText>
        <Button
          rounding='small'
          color='neutral'
          variant='filled'
          sizing='small'
          data-testid='room-start-ordering-button'
          onClick={navigateToMenu}
        >
          {menuButtonText}
        </Button>
        {hasOrders && (
          <Button
            variant='outlined'
            rounding='small'
            sizing='small'
            icon={<IconContentDocumentHistory />}
            onClick={() => navigate(NavigationRoute.OrderHistory)}
            data-testid='order-history-button'
          >
            {t('orderHistoryButton')}
          </Button>
        )}
        {orderingDisabled && (
          <Notification status='warning' variant='tonal' sizing='small' alignment='left' close={false}>
            <Text>{t('roomServiceDisabled')}</Text>
          </Notification>
        )}
        {hasOrders && restaurantPhoneNumber && (
          <Text data-testid='restaurant-phone-number' variant='body' sizing='small'>
            {/* Fallback to i18n if call prices are not provided */}
            {t('phoneNumberDescription')}:{' '}
            <PhoneNumberLink href={createPhoneHref(restaurantPhoneNumber)}>{restaurantPhoneNumber}</PhoneNumberLink>{' '}
          </Text>
        )}
      </PromptContent>
      <Feedback />
    </ContentWrapper>
  )
}

export default RoomMainContent
