import {OpeningTime, type WeekOpeningTime} from '@marketplace/shared-lib/graphql/graphql'
import {parse, startOfDay, subDays, subMinutes} from 'date-fns'
import {TZDate} from '@date-fns/tz'
import {timezoneFinland} from './timeUtils'

export type WeekDay = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
export type FlagType = 'tableActions' | 'kitchenClosed'

export enum STATE {
  ON = 'ON',
  OFF = 'OFF',
  AUTOMATIC = 'AUTOMATIC',
}

export const weekdays: WeekDay[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

/**
 * Returns the current weekday in Finnish timezone.
 *
 * @example
 * // assume you call this on 2024-07-01T01:00:00+03:00 (Monday 1AM in Finnish timezone)
 * resolveThisWeekday() // returns 'monday'
 */
export const resolveThisWeekday = () => {
  const today = TZDate.tz(timezoneFinland)
  return weekdays[today.getDay()]
}

/**
 * Returns the weekday for yesterday in Finnish timezone.
 *
 * @example
 * // assume you call this on 2024-07-01T01:00:00+03:00 (Monday 1AM in Finnish timezone)
 * getYesterdaysWeekday() // returns 'sunday'
 */
export const resolveYesterdaysWeekday = () => {
  const today = TZDate.tz(timezoneFinland)
  return weekdays[(today.getDay() + 6) % 7]
}

/**
 * Returns Date for the given time using the current date (or the next day if
 * isTomorrow is true). Uses Finnish timezone.
 *
 * @example
 * // assume you call this on 2024-07-01
 * resolveTimeFromHoursAndMinutes({time: '12:00', isTomorrow: false})
 * // returns 2024-07-01T12:00:00+03:00
 */
export const resolveTimeFromHoursAndMinutes = ({time, isTomorrow}: {time: string; isTomorrow?: boolean}) => {
  const currentTime = TZDate.tz(timezoneFinland)
  const newTime = parse(time, 'HH:mm', currentTime)

  if (isTomorrow) {
    newTime.setDate(newTime.getDate() + 1)
  }

  return newTime
}

type ResolveRestaurantOpenParams = {
  flagType: FlagType
  openingTimes?: WeekOpeningTime[]
  lastCallBuffer?: number
}

/**
 * Returns true if the restaurant is open at the current time.
 *
 * Assumes that the opening times are in Finnish timezone.
 *
 * Returns true if no opening times are provided.
 */
export const resolveRestaurantOpen = ({
  flagType,
  openingTimes,
  lastCallBuffer,
}: ResolveRestaurantOpenParams): boolean => {
  // menu is available by default is no weeklyAvailabilities
  if (!openingTimes) return true

  const now = TZDate.tz(timezoneFinland)

  const todayWeekday = resolveThisWeekday()
  const yesterdayWeekday = resolveYesterdaysWeekday()
  // let's use start of day for date comparison
  const todayStartOfDay = startOfDay(now)
  const yesterdayStartOfDay = startOfDay(subDays(now, 1))

  const openingTimeType = flagType === 'tableActions' ? 'openingTimes' : 'kitchenOpeningTimes'

  // find data for yesterday's opening hours
  // note that yesterday is not always on the same week as today
  const yesterdaysOpeningTimes = openingTimes.find((time) => {
    if (!time.date.end) return false
    const rangeEndStartOfDay = startOfDay(parse(time.date.end, 'yyyy-MM-dd', TZDate.tz(timezoneFinland)))
    return yesterdayStartOfDay <= rangeEndStartOfDay
  })?.[openingTimeType]?.[yesterdayWeekday]

  // check if yesterday's opening time is still valid
  const lastOpeningTime = yesterdaysOpeningTimes?.ranges?.[yesterdaysOpeningTimes.ranges.length - 1]
  if (yesterdaysOpeningTimes?.type === OpeningTime.TIMES && lastOpeningTime?.endNextDay) {
    let endTime = resolveTimeFromHoursAndMinutes({time: lastOpeningTime.end})

    // Take last call buffer into account for table actions
    if (lastCallBuffer && flagType === 'tableActions') {
      endTime = subMinutes(endTime, lastCallBuffer)
    }

    if (now < endTime) return true
  }

  // yesterday's opening time is not valid anymore, check today's opening time
  const currentDay = openingTimes.find((time) => {
    if (!time.date.end) return false
    const rangeEndStartOfDay = startOfDay(parse(time.date.end, 'yyyy-MM-dd', TZDate.tz(timezoneFinland)))
    return todayStartOfDay <= rangeEndStartOfDay
  })?.[openingTimeType]?.[todayWeekday]

  if (currentDay && currentDay.type === OpeningTime.CLOSED) return false
  if (currentDay && currentDay.type === OpeningTime.TWENTYFOUR_HOURS) return true

  const currentOpeningTimes = currentDay?.ranges?.find(
    (range) => resolveTimeFromHoursAndMinutes({time: range.start}) <= now
  )

  if (currentOpeningTimes) {
    const {start, end, endNextDay} = currentOpeningTimes

    // show opening time if start or end time is missing
    if (!start || !end) return true

    const startTime = resolveTimeFromHoursAndMinutes({time: start})
    let endTime = resolveTimeFromHoursAndMinutes({time: end, isTomorrow: endNextDay})

    // Take last call buffer into account for table actions
    if (lastCallBuffer && flagType === 'tableActions') {
      endTime = subMinutes(endTime, lastCallBuffer)
    }

    // has started and is valid until tomorrow
    if (startTime <= now && endNextDay) return true

    // has started and is still valid
    if (startTime <= now && now < endTime && !endNextDay) return true

    return false
  }

  return false
}
