import {large, medium, xsmall, xxsmall} from '@marketplace/shared-lib/src/utils/themeVariables'
import {FormRow, HelperText, Label, Link, RadioButtonGroup, Text, TextInput} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ItemsContainer = styled.div`
  margin: 0 ${large};
`

export const SummaryFormRow = styled(FormRow)<{grid?: string}>`
  margin: ${medium} ${large} 0;
  text-align: left;
  display: grid;
  grid-gap: ${({grid}) => grid ?? 0};

  // Removing SDS default margin-top
  > :not(:first-child) {
    margin-top: 0;
  }
`

export const InputFieldLabel = styled(Label)`
  padding-bottom: ${(props) => props.theme.spacing.xxxsmall};
`

export const InputField = styled(TextInput)`
  margint-top: 0;
  width: -webkit-fill-available;
`

export const InputFieldHelperText = styled(HelperText)<{color?: string}>`
  padding-top: ${(props) => props.theme.spacing.xxxsmall};
  text-align: left;
  color: ${({color, theme}) => color || theme.color.text.strongGrey};
`

export const SectionContainer = styled.div`
  margin: ${large} ${large} 0 ${large};
`

export const ItemWrapper = styled.div`
  border-top: ${({theme: {color}}) => `1px solid ${color.black}`};
  padding: ${({theme}) => theme.spacing.small} 0;

  &:last-child {
    padding-bottom: ${({theme}) => theme.spacing.xxxsmall};
  }
`

export const SummaryTotalContainer = styled.div`
  border-top: ${({theme: {color}}) => `2px solid ${color.black}`};
  padding-top: ${({theme}) => theme.spacing.small};
  margin: 0 ${large};
`

export const SummaryTotalRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SummaryTotalText = styled(Text)`
  text-transform: uppercase;
`

export const ButtonWrapper = styled.div`
  margin: ${large};
`

export const SendButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  flex-direction: column;
`

export const PickupDisclaimer = styled(Text)`
  display: flex;
  padding-left: ${({theme}) => theme.spacing.medium};
  margin-top: -8px;
  padding-bottom: ${xxsmall};
  gap: ${xsmall};
  align-items: end;
  color: ${({theme}) => theme.color.text.strongPrimary};

  span svg {
    height: ${({theme}) => theme.spacing.small};
    width: ${({theme}) => theme.spacing.small};
  }
`

export const PickupTimeChoiceLabel = styled(Text)`
  text-align: left;
`

export const PreparationTimeDisclaimer = styled(Text)`
  margin: ${({theme}) => theme.spacing.xsmall} 0 0;
`

export const PickupPaymentInfo = styled(Text)`
  display: block;
  text-align: left;
  padding: ${({theme}) => theme.spacingCustom.medium} 0 0;
`

export const SummaryWrapper = styled.div`
  display: grid;
  gap: ${({theme: {spacing}}) => spacing.xsmall};
`

export const TermsLink = styled(Link)`
  text-decoration: underline;
`

export const DeliveryMethodSelector = styled(RadioButtonGroup)``
