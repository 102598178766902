import FloatingCart from 'Components/Cart/FloatingCart'
import Header from 'Components/Header/Header'
import Modal from 'Components/Modal/Modal'
import ModalBottom from 'Components/ModalBottom/ModalBottom'
import useModalToggler from 'hooks/useModalToggler'
import 'react-toastify/dist/ReactToastify.css'
import {Main} from 'styles/GeneralStyles'
import {color} from 'theme'
import {Menu} from 'views/order'
import {MainContent, MainWrapper} from './LayoutStyles'

const MenuLayout = () => {
  // Global listener that opens modals when certain criteria is met
  useModalToggler()

  return (
    <MainWrapper>
      <MainContent>
        <Main backgroundColor={color.background.weakSecondary}>
          <Header isOrderFlow />
          <Menu />
        </Main>
      </MainContent>
      <FloatingCart />
      <Modal />
      <ModalBottom />
    </MainWrapper>
  )
}

export default MenuLayout
