import {LoyaltyProgram} from '@marketplace/shared-lib/graphql/graphql'
import {toKebabCase} from '@marketplace/shared-lib/src/utils/stringUtils'
import {RadioButtonGroup, Text} from '@s-group/design-system-components'
import RadioButton from 'Components/SelectionBox/RadioButton'
import {useTranslation} from 'react-i18next'

type LoyaltyProgramSelectionProps = {
  availablePrograms: LoyaltyProgram[]
  selectedValue: LoyaltyProgram | undefined
  onChange: (loyaltyProgram: LoyaltyProgram) => void
}

const LoyaltyProgramSelection = ({availablePrograms, selectedValue, onChange}: LoyaltyProgramSelectionProps) => {
  const {t} = useTranslation('order')

  return (
    <RadioButtonGroup data-testid='loyalty-program-options'>
      <Text variant='heading' sizing='xxxsmall' weight='medium'>
        {t('summary.loyaltyProgramLabel')}
      </Text>
      {availablePrograms.map((program) => (
        <RadioButton
          key={`loyalty-program-${toKebabCase(program)}`}
          id={`loyalty-program-${toKebabCase(program)}`}
          name='loyalty-program-selector'
          type='radio'
          data-testid={`loyalty-program-${toKebabCase(program)}`}
          checked={selectedValue === program}
          onChange={() => onChange(program)}
          value={program}
          label={
            <Text variant='body' sizing='small' weight={selectedValue === program ? 'medium' : 'regular'}>
              {t(`summary.loyaltyProgram.${program}`)}
            </Text>
          }
        />
      ))}
    </RadioButtonGroup>
  )
}

export default LoyaltyProgramSelection
