/* eslint-disable @typescript-eslint/no-explicit-any */
import {Button, Modal as ModalNew} from '@s-group/design-system-components'
import {ComponentClass} from 'react'
import styled, {StyledComponent} from 'styled-components'
import Modal, {ModalProps} from 'styled-react-modal'

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledModalBottom = styled(ModalNew)<{
  small: boolean
  maxWidth?: string
  background?: string
  animate?: boolean
  noPadding?: boolean
}>`
  background: ${({background, theme}) => background ?? theme.color.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: ${({maxWidth}: {maxWidth?: string}) => maxWidth ?? '100vw'};
  ${({small}: {small: boolean}) => small && 'max-width: 450px'};
  overflow: hidden;

  // // Note: we must use svh (small viewport height) instead of vh.
  // // svh acknowledges any elements taking up vertical space (e.g. browser
  // // address bar)
  // fallback for old browsers (e.g. FF90)
  max-height: calc(90vh - 125px - 3rem);
  // modern browsers: height of the modal - height of the bottom action box
  max-height: calc(90svh - 125px);

  transform: ${({animate}) => (animate ? 'translate(0,0)' : 'translate(0, 90vh)')};
  transition: transform 0.14s ease-in-out;

  .modal-body {
    ${({noPadding}) => noPadding && `padding: 0;`}
    z-index: 7000;
  }
`

export const ModalBottomCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 9000;
`

// These belove are used only in staff UI
export const StyledModal: StyledComponent<
  ComponentClass<ModalProps>,
  any,
  {animate: boolean; small?: boolean; medium?: boolean; opacity?: number},
  never
> = Modal.styled`
  background: ${({theme}: {theme: any}) => theme.color.white};
  color: ${({theme}: {theme: any}) => theme.color.black};
  max-width: 840px;
  position:relative;
  width: calc(100vw - 2 * ${({theme}: {theme: any}) => theme.spacing.medium});
  ${({small}: {small: boolean}) => small && 'max-width: 450px'};
  ${({medium}: {medium: boolean}) => medium && 'max-width: 600px'};
  z-index: 200;
  // 100% - margins - close icon offset
  max-height: calc(100vh - 2rem - 1rem); // fallback for old browsers
  max-height: calc(100svh - 2rem - 1rem); // modern browsers
  overflow-x: auto;
  border-radius: 4px;
  overflow: visible;
  opacity: ${({opacity}: {opacity: number}) => opacity ?? 1};
  transform:${({animate}: {animate: boolean}) => (animate ? 'translate(0,-10px)' : 'translate(0)')};
  transition : all 0.3s ease-in-out;
`

export const ModalCloseButton = styled.div`
  position: absolute;
  margin: -1rem;
  right: 0;
  z-index: 200;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${({theme}) => theme.color.white};
`

export const StyledModalFullScreen: StyledComponent<
  ComponentClass<ModalProps>,
  any,
  {animate: boolean; opacity?: number},
  never
> = Modal.styled`
  background: ${({theme}: {theme: any}) => theme.color.black};
  width: 100vw;
  height: 100vh; // fallback for old browsers
  height: 100svh; // modern browsers
  position:relative;
  z-index: 200;
  overflow: auto;
  opacity: ${({opacity}: {opacity: number}) => opacity ?? 1};
  transition : all 0.3s ease-in-out;
`
export const FullScreenCloseButton = styled.div`
  position: absolute;
  margin: 0;
  top: ${({theme}) => theme.spacing.xxlarge};
  right: ${({theme}) => theme.spacing.xxlarge};
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  cursor: pointer;
`

export const ModalWrapper = styled.div<{whiteBackground?: boolean}>`
  color: ${({whiteBackground, theme}) => (whiteBackground ? theme.color.black : theme.color.white)};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const ModalTextWrapper = styled.div`
  margin-top: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ModalButtonWrapper = styled.div`
  padding: 2rem;
  background-color: ${({theme}) => theme.color.lightGrey};
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ModalBottomContentWrapper = styled.div`
  height: 100%;
`
