import {LocalizedString} from '@marketplace/shared-lib/graphql/graphql'
import {Language} from '../types/language'

export const isLocale = (locale: string): locale is Language => {
  return Object.values(Language).includes(locale as Language)
}

export type LocalizedStringFinnishRequired = Pick<LocalizedString, 'fi'> & Partial<LocalizedString>

export const getLocalized = (
  translationString: LocalizedStringFinnishRequired | undefined | null,
  currentLocale: string
): string => {
  if (!translationString) return ''
  if (isLocale(currentLocale)) {
    return translationString[currentLocale] ?? translationString.fi
  }
  return translationString.fi
}
