import {showFirstLayer} from '@s-group/react-usercentrics'
import {useTranslation} from 'react-i18next'
import {CookieSettingsLink, FooterElement} from './FooterStyles'

const Footer = (): JSX.Element => {
  const {t} = useTranslation('remotePage')
  return (
    <FooterElement data-testid='footer'>
      <CookieSettingsLink
        onClick={() => {
          showFirstLayer()
        }}
      >
        {t('showCookieConsentForm')}
      </CookieSettingsLink>
    </FooterElement>
  )
}
export default Footer
