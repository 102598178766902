import {Text} from '@s-group/design-system-components'
import {ModalState} from 'Components/Modal/modalTypes'
import {Success} from 'Icons'
import {useContext} from 'react'
import ModalContext from '../../state/ModalContext'
import {PopupButton, PopupTitle, PopupWrapper} from './PopupStyles'

interface PopupProps {
  message: string
  bodyText?: string
  testId?: string
  buttonText?: string | null
}

const SuccessfulPopup = ({message, bodyText, testId, buttonText}: PopupProps) => {
  const {setModalState} = useContext(ModalContext)
  return (
    <PopupWrapper data-testid={testId} role='alert'>
      <Success />
      <PopupTitle variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
        {message}
      </PopupTitle>
      {bodyText && (
        <Text variant='body' sizing='small'>
          {bodyText}
        </Text>
      )}
      {setModalState && buttonText && (
        <PopupButton
          color='neutral'
          variant='filled'
          rounding='small'
          data-testid={testId ? `${testId}-button` : undefined}
          onClick={() => setModalState(ModalState.Closed)}
        >
          {buttonText}
        </PopupButton>
      )}
    </PopupWrapper>
  )
}

export default SuccessfulPopup
