import {useGetRestaurantListItemLazyQuery} from '@marketplace/shared-lib/graphql/graphql'

const useRestaurantListItemLazy = () => {
  const [getRestaurantListItem] = useGetRestaurantListItemLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  return getRestaurantListItem
}

export default useRestaurantListItemLazy
