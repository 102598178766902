/**
 * Environment name. This is set by the CI environment.
 *
 * Default 'local' if not set.
 */
export const currentEnv: string = import.meta.env.VITE_APP_STAGE ?? 'local'

export const isProduction = currentEnv === 'prod'

/**
 * GraphQL endpoint URL. This is set by the CI environment.
 */
export const graphqlEndpoint = import.meta.env.VITE_APP_GQL_ENDPOINT
