import {
  CallWaiterType,
  GetRestaurantFeaturesQuery,
  GetTableQuery,
  TableStatusType,
  useChangeTableStateMutation,
} from '@marketplace/shared-lib/graphql/graphql'
import {areGlobalTableActionsEnabled} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Button} from '@s-group/design-system-components'
import {IconContentDocumentHistory} from '@s-group/design-system-icons'
import {ModalState} from 'Components/Modal/modalTypes'
import {PendingRequests} from 'Components/PendingRequest'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEvent from 'hooks/useTriggerEvent'
import {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import ModalContext from 'state/ModalContext'
import {AnalyticsActions} from 'types/analyticsTypes'
import {useToken} from 'utils/cookies'
import {mapCallWaiterEvent} from 'utils/eventUtils'
import {getAllOrders} from 'utils/order'
import {hasPendingStates} from 'utils/tableActions'
import {ActionsDisabledInfo, ActionsWrapper, ContentWrapper, TableNumber} from './TableActionsStyles'

export const quickOrderTestId = 'navigate-to-order-menu'
export const quickOrderDisabledTestId = 'order-disabled-text'
export const showMenuTestId = 'navigate-to-menu'
export const callWaiterTestId = 'call-waiter-generic'
export const requestBillTestId = 'request-bill'

type TableActionsProps = {
  restaurantFeatures?: GetRestaurantFeaturesQuery['getRestaurantFeatures']['features']
  table: GetTableQuery['getTable']
}

const TableActions = ({restaurantFeatures, table}: TableActionsProps) => {
  const token = useToken()
  const {t} = useTranslation('remotePage')
  const [navigateToMenu, setNavigateToMenu] = useState(false)
  const navigate = useNavigate()
  const {tableData, cartData, restaurantData, handledOrderData} = useRestaurantContext()
  const {setModalState} = useContext(ModalContext)
  const {triggerEvent} = useTriggerEvent()
  const cartID = cartData?.getCart.id
  const {pendingOrders, ordersInProgress, handledOrders} = getAllOrders(tableData, handledOrderData, cartID)
  const hasOrders = (pendingOrders?.length ?? 0) + (handledOrders?.length ?? 0) + (ordersInProgress?.length ?? 0) > 0
  const isTableDisabled =
    table.state.billRequest === false && table.state.waiterCall === false && table.state.quickOrder === false

  useEffect(() => {
    if (navigateToMenu) {
      navigate(NavigationRoute.Menu)
    }

    return () => {
      setNavigateToMenu(false)
    }
  }, [navigateToMenu, navigate])

  const [cancelCallWaiter, {loading: callWaiterLoading}] = useChangeTableStateMutation()

  const cancelWaiterCall = (callWaiterType: CallWaiterType) => {
    if (!callWaiterLoading) {
      void cancelCallWaiter({
        variables: {
          input: {
            token,
            state: {
              callWaiterType: CallWaiterType.GENERIC,
              status: isTableDisabled ? TableStatusType.DISABLED : TableStatusType.IDLE,
            },
          },
        },
        onCompleted: () => {
          setModalState(
            callWaiterType === CallWaiterType.GENERIC ? ModalState.WaiterCallCancelled : ModalState.RequestBillCancelled
          )
          triggerEvent({
            action: AnalyticsActions.CANCEL,
            event: mapCallWaiterEvent(callWaiterType),
          })
        },
        onError: () => {
          triggerEvent({
            action: AnalyticsActions.ERROR_CALL_WAITER,
            event: mapCallWaiterEvent(callWaiterType),
          })
        },
      })
    }
  }

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions: restaurantFeatures?.tableActions,
    tableActionsAutomatic: restaurantFeatures?.tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer: restaurantFeatures?.lastCallBuffer,
  })

  const actionsDisabled = !tableActionsEnabled || table.state.status === TableStatusType.DISABLED
  // Table specific settings override only if they are explicitly switched off
  const orderingDisabled = actionsDisabled || table.state.quickOrder === false || !restaurantFeatures?.quickOrder
  const waiterCallDisabled = table.state.waiterCall === false || !(restaurantFeatures?.waiterCall ?? true)
  const billRequestDisabled = table.state.billRequest === false || !(restaurantFeatures?.billRequest ?? true)

  const resolveMenuButtonText = orderingDisabled ? t('seeTheMenus') : t('orderMenuButton')
  const resolveMenuButtonTestId = orderingDisabled ? showMenuTestId : quickOrderTestId

  return (
    <ActionsWrapper>
      <ContentWrapper>
        <TableNumber variant='body' sizing='small' transform='caption'>
          {t('table')} <b data-testid='table-number'>{table.tableID}</b>
        </TableNumber>
        <Button
          data-testid={resolveMenuButtonTestId}
          onClick={() => setNavigateToMenu(true)}
          color='neutral'
          variant='filled'
          rounding='small'
          sizing='small'
        >
          {resolveMenuButtonText}
        </Button>
        {hasOrders && (
          <Button
            variant='outlined'
            rounding='small'
            sizing='small'
            icon={<IconContentDocumentHistory />}
            onClick={() => navigate(NavigationRoute.OrderHistory)}
            data-testid='order-history-button'
          >
            {t('orderHistoryButton')}
          </Button>
        )}
        {actionsDisabled ? (
          <>
            <ActionsDisabledInfo status='warning' close={false} sizing='small'>
              {t('tablesActionsAreDisabled')}
            </ActionsDisabledInfo>
            {hasPendingStates(table.state) && (
              <PendingRequests
                tableActionsDisabled={!tableActionsEnabled}
                state={table.state}
                cancel={() => cancelWaiterCall(table.state.callWaiterType)}
              />
            )}
          </>
        ) : (
          orderingDisabled && (
            <ActionsDisabledInfo status='warning' close={false} sizing='small' data-testid={quickOrderDisabledTestId}>
              {t('tablesOrderDisabled')}
            </ActionsDisabledInfo>
          )
        )}
        {!actionsDisabled &&
          (hasPendingStates(table.state) ? (
            <PendingRequests
              tableActionsDisabled={false}
              state={table.state}
              cancel={() => cancelWaiterCall(table.state.callWaiterType)}
            />
          ) : (
            <>
              {!waiterCallDisabled && (
                <Button
                  data-testid={callWaiterTestId}
                  onClick={() => setModalState(ModalState.CallWaiterConfirmationStep)}
                  color='neutral'
                  variant='outlined'
                  rounding='small'
                  sizing='small'
                >
                  {t('callWaiter')}
                </Button>
              )}
              {!billRequestDisabled && (
                <Button
                  data-testid={requestBillTestId}
                  onClick={() => setModalState(ModalState.BillOptions)}
                  color='neutral'
                  variant='outlined'
                  rounding='small'
                  sizing='small'
                >
                  {t('requestBill')}
                </Button>
              )}
            </>
          ))}
      </ContentWrapper>
    </ActionsWrapper>
  )
}

export default TableActions
