import {useTranslation} from 'react-i18next'

import {ActionsWrapper, ContentWrapper, DisabledP} from './TableActionsStyles'

const TableActionsExpired = () => {
  const {t} = useTranslation('remotePage')

  return (
    <ActionsWrapper>
      <ContentWrapper>
        <DisabledP>{t('sessionOverReadCodeAgain')}</DisabledP>
      </ContentWrapper>
    </ActionsWrapper>
  )
}

export default TableActionsExpired
