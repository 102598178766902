import {Select} from '@s-group/design-system-components'
import styled from 'styled-components'

const LanguageMenuWrapper = styled.nav`
  align-items: center;
  display: flex;
  height: 24px;
  right: 0;
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacing.xxsmall};
  position: absolute;
`

const LanguageMenuSelect = styled(Select)`
  background-image: url('./chevron-down.svg');
  background-color: ${(props) => props.theme.color.background.inverseGrey};
  background-position-x: right 16px;
  background-position-y: top 50%;
  background-repeat: no-repeat;
  border-color: ${(props) => props.theme.color.background.inverseGrey};
  color: ${(props) => props.theme.color.white};
`

export {LanguageMenuSelect, LanguageMenuWrapper}
