import {CartItemFieldsFragment, PortionOptionSectionType, PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {EventItem, EventItemContext} from '../types/analyticsTypesNew'

const findSelectedSizeOption = (portion: CartItemFieldsFragment['portion']) => {
  const sizeOptionSection = portion.portionOptionSections.find(
    (optionSection) => optionSection?.type === PortionOptionSectionType.SIZE
  )

  const sizeOptions = [...(sizeOptionSection?.portionOptions ?? [])]

  // If there are multiple, pick the lowest price option
  sizeOptions?.sort((a, b) => {
    if (!a.price?.normal || !b.price?.normal) {
      return 0
    }
    return a.price.normal - b.price.normal
  })

  return sizeOptions?.[0]
}

const getPortionAdditionalPrice = (portion: CartItemFieldsFragment['portion']): number => {
  let totalPrice = 0

  portion.portionOptionSections.forEach((optionSection) => {
    optionSection?.portionOptions.forEach((portionOption) => {
      if (portionOption.price?.type === PriceType.ADDITIONAL) {
        totalPrice += portionOption.price?.normal ?? 0
      }
    })
  })

  return totalPrice
}

// Calculates the portion price preferencing size option price
const getPortionPrice = (portion: CartItemFieldsFragment['portion']) => {
  const selectedSizeOption = findSelectedSizeOption(portion)

  if (selectedSizeOption?.price?.normal) {
    return selectedSizeOption.price.normal
  }
  if (portion.price?.normal) {
    return portion.price.normal
  }
  return 0
}

const getPortionSize = (portion: CartItemFieldsFragment['portion']) => {
  const selectedSizeOption = findSelectedSizeOption(portion)

  return selectedSizeOption?.label.fi
}

// Calculates total price consisting of portion base price and add-ons
export const getPortionValue = (portion: CartItemFieldsFragment['portion']) => {
  const totalPrice = getPortionPrice(portion) + getPortionAdditionalPrice(portion)
  return totalPrice / 100
}

export const mapPortionToEventItem = (portion: CartItemFieldsFragment['portion']): EventItem => ({
  item_id: portion.id,
  item_name: portion.name.fi,
  price: getPortionPrice(portion) / 100,
  quantity: 1,
  item_size: getPortionSize(portion),
  item_additional_price: getPortionAdditionalPrice(portion) / 100,
  item_category: portion.type,
  item_category2: portion.dishTags?.[0] ?? portion.drinkTags?.[0],
  item_category3: portion.dishTags?.[1] ?? portion.drinkTags?.[1],
  item_diet_tag: portion.diet?.[0],
  item_diet_tag2: portion.diet?.[1],
  item_diet_tag3: portion.diet?.[2],
})

export const mapCartItemAnalyticsToEventItemContext = (
  analytics: CartItemFieldsFragment['analytics']
): EventItemContext => ({
  index: analytics?.index ?? undefined,
  item_list_name: analytics?.itemListName ?? undefined,
  creative_name: analytics?.creativeName ?? undefined,
  promotion_name: analytics?.promotionName ?? undefined,
  related_item_id: analytics?.relatedItemId ?? undefined,
})

export const mapEventItemContextToCartItemAnalytics = (
  analytics: EventItemContext
): CartItemFieldsFragment['analytics'] => ({
  ...(analytics.index !== undefined && {index: analytics.index}),
  ...(analytics.item_list_name && {itemListName: analytics.item_list_name}),
  ...(analytics.creative_name && {creativeName: analytics.creative_name}),
  ...(analytics.promotion_name && {promotionName: analytics.promotion_name}),
  ...(analytics.related_item_id && {relatedItemId: analytics.related_item_id}),
})
