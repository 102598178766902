import {deliveryPortionPickup, deliveryPortionRoom} from '@marketplace/shared-lib/src/utils/cartUtils'
import {useTranslation} from 'react-i18next'
import {OrderProps} from 'utils/order'
import {
  FirstRowBiggerFont,
  OrderConfirmationDetailsRow,
  OrderInfoHeading,
  OrderInfoText,
  OrderSummaryWrapper,
} from './ThankYouStyles'

export const OrderConfirmationDetails = ({order, tableID}: {order: OrderProps; tableID: string}) => {
  const {products} = order
  const lastProduct = products?.[(products?.length ?? 1) - 1]
  const {t} = useTranslation('order')

  return (
    <OrderSummaryWrapper>
      <FirstRowBiggerFont variant='heading' sizing='xxsmall' weight='bold'>
        {t('summary.orderInfo')}
      </FirstRowBiggerFont>
      <OrderConfirmationDetailsRow firstRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.roomNumber')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {tableID}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.ordererNameText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {order.nickName} {order.lastName}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.phoneNumberText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {order.phoneNumber}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.deliveryType')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold'>
          {lastProduct?.portion.id === deliveryPortionRoom && t('summary.deliveryProduct_room')}
          {lastProduct?.portion.id === deliveryPortionPickup && t('summary.deliveryProduct_pickup')}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.loyaltyProgramText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold' data-testid='order-summary-loyalty-program'>
          {t(`summary.loyaltyProgram.${order.loyaltyProgram}`)}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
      <OrderConfirmationDetailsRow>
        <OrderInfoHeading variant='body' sizing='small'>
          {t('summary.paymentMethodText')}
        </OrderInfoHeading>
        <OrderInfoText variant='body' sizing='small' weight='bold' data-testid='order-summary-payment-method'>
          {t(`summary.paymentMethod.${order.paymentMethod}`)}
        </OrderInfoText>
      </OrderConfirmationDetailsRow>
    </OrderSummaryWrapper>
  )
}
