import {PortionType, TableType} from '@marketplace/shared-lib/graphql/graphql'

export enum AnalyticsEvents {
  CALL_WAITER = 'call_waiter',
  REQUEST_BILL = 'request_bill',
  SET_ORDER = 'set_order',
  SEE_MENU = 'see_menu',
  MAKE_ORDER = 'make_order',
  ADD_TO_CART = 'add_to_cart',
  UPDATE_CART = 'update_cart',
  CUSTOMER_FULL_AGE = 'customer_full_age',
}

export enum AnalyticsActions {
  CALL_WAITER = 'call_waiter',
  ERROR_CALL_WAITER = 'failed_call_waiter',
  CANCEL = 'cancel',
  ONE_BILL = 'one_bill',
  ERROR_ONE_BILL = 'failed_one_bill',
  SPLIT_BILLS = 'different_bills',
  ERROR_SPLIT_BILLS = 'failed_different_bills',
  CLICK = 'click',
  GIVE_SCORE = 'give_score',
  MODAL_OPEN = 'modal_open',
  SUMMARY_OPEN = 'summary_open',
  SEND_ORDER = 'send_order',
  ADD_TO_CART_RECOMMENDATIONS_CART = 'add_to_cart_recommendations_cart',
  ADD_TO_CART_RECOMMENDATIONS_WELCOME = 'add_to_cart_recommendations_welcome',
  ADD_TO_CART_RECOMMENDATIONS_PORTION_OVERLAY = 'add_to_cart_recommendations_portion_overlay',
  ADD_TO_CART_PORTION_DETAILS = 'add_to_cart_portion_details',
  UPDATE_CART_RECOMMENDATIONS_PORTION_OVERLAY = 'update_cart_recommendations_portion_overlay',
  UPDATE_CART_PORTION_DETAILS = 'update_cart_portion_details',
  CUSTOMER_FULL_AGE_TRUE = 'customer_full_age_true',
  CUSTOMER_FULL_AGE_FALSE = 'customer_full_age_false',
}

export type DataLayerOrderItem = {
  item_name: string
  price: string
  item_category?: PortionType
  quantity: number
}

export type DataLayerModel = {
  event: AnalyticsEvents
  action?: AnalyticsActions
  restaurant_name?: string
  cart_id?: string
  table?: string
  table_type?: TableType
  items?: DataLayerOrderItem[]
}

// https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
export interface DataLayerFlush {
  event: undefined
  action: undefined
  restaurant_name: undefined
  cart_id: undefined
  table: undefined
  table_type: undefined
  items: undefined
}
