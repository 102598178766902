import Header from 'Components/Header/Header'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {Outlet} from 'react-router'
import {Main} from 'styles/GeneralStyles'
import {color} from 'theme'

const Order = () => {
  const context = useRestaurantContext()
  const {cartData, tableData, tableLoading, cartLoading} = context

  return (
    <Main backgroundColor={color.background.weakSecondary}>
      <NotificationBanner />
      <Header isOrderFlow />
      {(!tableData && tableLoading) || (!cartData && cartLoading) ? <LoadingScreen /> : <Outlet />}
    </Main>
  )
}

export default Order
