import {Heading} from '@s-group/design-system-components'
import styled from 'styled-components'

export const SiteNameWrapper = styled.div`
  display: grid;
  background-color: ${({theme}) => theme.color.background.weakSecondary};
  padding: ${({theme}) => theme.spacingCustom.medium};
  gap: ${({theme}) => theme.spacing.xxxsmall};
`

export const SiteNameHeading = styled(Heading)`
  text-transform: uppercase;
  margin: 0;
`

export const SiteLocation = styled(Heading)`
  margin: 0;
`
