import {LocalizedString} from '@marketplace/shared-lib/graphql/graphql'
import {PortionWithMenuID, getRecommendationsWithDefaultOptions} from 'Components/Product/productCardUtils'
import {Menu} from 'types/restaurantTypes'
import useFilteredMenus from './useFilteredMenus'
import useRestaurantContext from './useRestaurantContext'

interface DynamicRecommendation {
  portions: PortionWithMenuID[]
  header?: LocalizedString
}

const allPortionsWithMenuIDs = (menus: Menu[]) =>
  menus.flatMap((menu) =>
    menu.menuSections
      .map((menuSection) => ({...menuSection, menuID: menu.id}))
      .flatMap((menuSection) =>
        menuSection.portions.map((portion) => ({...portion, menuID: menuSection.menuID ?? undefined}))
      )
  )

export const useCartRecommendations = (): DynamicRecommendation => {
  const {cartData} = useRestaurantContext()
  const menus = useFilteredMenus()

  const allPortions = allPortionsWithMenuIDs(menus)

  const cartRecommendations = menus
    .flatMap(({recommendations}) => recommendations.cart.portionIDs)
    .filter((id, index, self) => self.indexOf(id) === index) // unique IDs

  const filteredCartRecommendation = cartRecommendations
    .map((recommendationId) => allPortions.find(({id}) => id === recommendationId))
    .filter((portion): portion is PortionWithMenuID => {
      if (!portion) return false

      const productInCart = cartData?.getCart.items.find((cartItem) => cartItem.portion.id === portion.id)
      return !productInCart
    })

  if (filteredCartRecommendation.length === 0) {
    return {portions: []}
  }

  const dynamicHeader = menus.flatMap(({recommendations}) => recommendations.cart.header).find((header) => header.fi)

  return {portions: getRecommendationsWithDefaultOptions(filteredCartRecommendation), header: dynamicHeader}
}

export const useWelcomeRecommendations = (): DynamicRecommendation => {
  const menus = useFilteredMenus()

  const allPortions = allPortionsWithMenuIDs(menus)

  const welcomeRecommendations = menus
    .flatMap(({recommendations}) => recommendations.welcome.portionIDs)
    .filter((id, index, self) => self.indexOf(id) === index) // unique IDs

  const filteredWelcomeRecommendations = welcomeRecommendations
    .map((portionId) => allPortions.find((portion) => portion.id === portionId))
    .filter((portion): portion is PortionWithMenuID => !!portion)

  if (filteredWelcomeRecommendations.length === 0) {
    return {portions: []}
  }

  const dynamicHeader = menus.flatMap(({recommendations}) => recommendations.welcome.header).find((header) => header.fi)

  return {portions: getRecommendationsWithDefaultOptions(filteredWelcomeRecommendations), header: dynamicHeader}
}

interface PortionRecommendations {
  portionRecommendationsHeader?: LocalizedString
  recommendedPortions: PortionWithMenuID[]
}

// Returns portion recommendations header and portionIDs for the current restaurant and portion
export const usePortionRecommendations = (portionToRecommendId: string | null): PortionRecommendations => {
  const {restaurantData} = useRestaurantContext()
  const menus = useFilteredMenus()

  if (!restaurantData?.getRestaurant.id || !portionToRecommendId) {
    return {portionRecommendationsHeader: undefined, recommendedPortions: []}
  }

  const allPortions = allPortionsWithMenuIDs(menus)

  const {header: portionRecommendationsHeader, portionIDs: portionRecommendationIDs} =
    allPortions.find((portion) => portion.id === portionToRecommendId)?.recommendations || {}

  if (!portionRecommendationIDs) {
    return {portionRecommendationsHeader: undefined, recommendedPortions: []}
  }

  const recommendablePortions = portionRecommendationIDs.flatMap((portionId) =>
    allPortions.filter((portion) => portion.id === portionId)
  )

  return {
    portionRecommendationsHeader,
    recommendedPortions: recommendablePortions,
  }
}
