const isBrowser = typeof window !== 'undefined'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function writeLocalStorage(key: string, data: string | any): void {
  if (isBrowser) {
    if (typeof data !== 'string') localStorage.setItem(key, JSON.stringify(data))
    else localStorage.setItem(key, data)
  }
}

function readLocalStorage(key: string, isJson = false) {
  if (isBrowser) {
    if (isJson) {
      const stringData = localStorage.getItem(key)
      if (!stringData) return null
      try {
        return JSON.parse(stringData)
      } catch {
        localStorage.removeItem(key)
        return null
      }
    }
    return localStorage.getItem(key)
  }
  return null
}

function removeLocalStorage(key: string) {
  if (isBrowser) {
    localStorage.removeItem(key)
  }
}

/**
 * Booleans are saved as strings to localStorage.
 * This function can be used to convert the saved string values back to Booleans for FE to use.
 */
function convertLocalStorageStringToBoolean(string: string | undefined): boolean | undefined {
  if (string === 'false') return false
  if (string === 'true') return true
  return undefined
}

export {convertLocalStorageStringToBoolean, readLocalStorage, removeLocalStorage, writeLocalStorage}
