import {large} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Text} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${large};
  padding-bottom: ${large};
`

export const PhoneNumberLink = styled.a`
  color: ${({theme}) => theme.color.black};
  text-decoration: none;
  font-weight: 700;
  text-wrap: nowrap;
`

export const SubHeader = styled(Text)<{paddingtop?: string}>`
  padding-top: ${({paddingtop}) => paddingtop ?? '0'};
`

export const TextContent = styled.div`
  font-size: ${({theme}) => theme.spacingCustom.small};
  margin: 0;
`

export const PromptContent = styled.div`
  padding: 0 ${({theme}) => theme.spacingCustom.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({theme}) => theme.spacing.small};
`

export const RoomNumberText = styled(Text)`
  padding-bottom: ${({theme}) => theme.spacing.xxsmall};
`

export const RestaurantSelectionDescription = styled(Text)`
  padding-left: ${({theme}) => theme.spacing.small};
  padding-right: ${({theme}) => theme.spacing.small};
`
