import styled from 'styled-components'

const IconWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  display: flex;
  font-size: 64px;
  border-radius: 50%;
  height: 140px;
  justify-content: center;
  width: 140px;
`

const IconOuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 56px 0 40px;
  width: 100%;
`

const TextWrapper = styled.section`
  background-color: ${(props) => props.theme.color.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: ${({theme}) => theme.spacingCustom.medium};
  padding: ${({theme}) => theme.spacingCustom.medium};
  text-align: left;
`

const NoMarginList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${({theme}) => theme.spacing.small};
`

export {IconOuterWrapper, IconWrapper, NoMarginList, TextWrapper}
