import {Notification} from '@s-group/design-system-components'
import styled from 'styled-components'

export const NotificationBannerContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 30;
`

export const NotificationBannerWrapper = styled(Notification)`
  border-bottom: 1px solid ${({theme}) => theme.color.border.defaultGrey};
`
