import {ContainsAlcohol, PaymentMethod} from '@marketplace/shared-lib/graphql/graphql'
import {countCartItems} from '@marketplace/shared-lib/src/utils/cartUtils'
import formatPrice, {formatPricePlainText} from '@marketplace/shared-lib/src/utils/formatPrice'
import {Button, Form, Text} from '@s-group/design-system-components'
import {IconNavigationArrowRight, IconStatusWarning} from '@s-group/design-system-icons'
import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import RadioButton from 'Components/SelectionBox/RadioButton'
import useCreateOrderMutation from 'hooks/useCreateOrderMutation'
import {triggerEvent} from 'hooks/useDataLayer'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {useEffect, useMemo, useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {color, spacingCustom} from 'theme'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {mapPortionToEventItem} from 'utils/analytics'
import {deliveryPortion, getLoyaltyProgramOptions} from 'utils/cart'
import {CustomerDetails, ExtendedCustomerDetails, validateDigits, validateText} from 'utils/order'
import CartRecommendations from './CartRecommendations'
import LoyaltyProgramSelection from './LoyaltyProgramSelection'
import {Description, Title} from './OrderStyles'
import PaymentMethodSelection from './PaymentMethodSelection'
import SummaryItems from './SummaryItems'
import {
  DeliveryMethodSelector,
  InputField,
  InputFieldHelperText,
  InputFieldLabel,
  PickupDisclaimer,
  PreparationTimeDisclaimer,
  SectionContainer,
  SendButtonWrapper,
  SummaryFormRow,
  SummaryTotalContainer,
  SummaryTotalRow,
  SummaryTotalText,
  SummaryWrapper,
  TermsLink,
} from './SummaryStyles'

const minNameLen = 1
const minPhoneLen = 6

const SummaryRoomService = ({customerDetails}: {customerDetails?: CustomerDetails}) => {
  const {t} = useTranslation('order')
  const {tableData, cartData, restaurantData, restaurantFeatures} = useRestaurantContext()
  const triggerEventNew = useTriggerEventNew()
  const viewCartEventTriggered = useRef(false)
  const {token, restaurantID, tableType, tableID} = tableData?.getTable || {}
  const restaurantName = restaurantData?.getRestaurant.name.fi

  // use previous customer details as default values
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    phoneNumber: initialPhoneNumber,
    paymentMethod: initialPaymentMethod,
    loyaltyProgram: initialLoyaltyProgram,
  } = customerDetails || {}

  const setOrderProps = {
    restaurantID,
    token,
    restaurantName,
    tableID,
    tableType,
  }

  const {cartModify, loading: cartLoading} = useUpdateCartMutation()
  const {submitOrder, loading: submitOrderLoading} = useCreateOrderMutation(setOrderProps)
  const navigate = useNavigate()
  const [navigateToMenu, setNavigateToMenu] = useState(false)

  const products = cartData?.getCart.items
  const productTotals = cartData?.getCart.total
  const totalPrice = formatPrice({price: productTotals?.normal ?? 0})
  const orderTotalQuantity = countCartItems(products ?? [])

  // loyalty program value is defined for room service orders only
  const availableLoyaltyProgramOptions = getLoyaltyProgramOptions(restaurantID)
  // payment method is only defined for room service orders
  const availablePaymentMethods = useMemo(() => {
    return restaurantFeatures?.roomService?.paymentMethods ?? [PaymentMethod.CARD, PaymentMethod.ROOM_BILL]
  }, [restaurantFeatures?.roomService?.paymentMethods])

  const [nameValid, setNameValid] = useState(false)
  const [firstNameVisited, setFirstNameVisited] = useState(false)
  const [lastNameValid, setLastNameValid] = useState(false)
  const [lastNameVisited, setLastNameVisited] = useState(false)
  const [phoneVisited, setPhoneVisited] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const [roomServiceCustomerDetails, setRoomServiceCustomerDetails] = useState<ExtendedCustomerDetails>({
    firstName: initialFirstName,
    lastName: initialLastName,
    phoneNumber: initialPhoneNumber,
    paymentMethod: initialPaymentMethod,
    loyaltyProgram: initialLoyaltyProgram,
  })

  const {firstName, lastName, phoneNumber, paymentMethod, loyaltyProgram} = roomServiceCustomerDetails ?? {}

  const onEditCustomerDetails = (key: keyof ExtendedCustomerDetails, value: string | boolean) => {
    setRoomServiceCustomerDetails({...roomServiceCustomerDetails, [key]: value})
  }

  const {enabled: rushHourEnabled, delay: rushHourDelay} = restaurantFeatures?.rushHour ?? {}
  const {
    pickupEnabled,
    deliveryEnabled,
    deliveryFee,
    delay: roomServiceDelay = 15,
  } = restaurantFeatures?.roomService ?? {}
  const totalRoomServiceDelay = rushHourEnabled && rushHourDelay ? roomServiceDelay + rushHourDelay : roomServiceDelay

  const orderContainsAlcohol = products?.some(({portion}) => portion.containsAlcohol === ContainsAlcohol.YES)
  const roomServiceClosed = !pickupEnabled && !deliveryEnabled
  // because of legislation, alcohol pickup is not allowed for room service orders
  const pickupAllowed = pickupEnabled && !orderContainsAlcohol
  // delivery is allowed for room service orders even if alcohol is included
  const pickupOrDeliveryAllowed = pickupAllowed || deliveryEnabled

  let initialDelivery: '' | 'pickup' | 'room'
  const deliveryChoice = pickupEnabled && deliveryEnabled
  if (deliveryChoice) {
    initialDelivery = ''
  } else {
    initialDelivery = pickupEnabled ? 'pickup' : 'room'
  }
  const [deliveryType, setDeliveryType] = useState(initialDelivery)

  const missingRoomOrderDetails =
    roomServiceClosed || !phoneValid || !nameValid || !deliveryType || !loyaltyProgram || !paymentMethod
  const foodOrdersDisabled = restaurantFeatures?.kitchenClosed && products?.some((item) => item.portion.type === 'DISH')

  const submitIsDisabled =
    cartLoading ||
    orderTotalQuantity === 0 ||
    submitOrderLoading ||
    !pickupOrDeliveryAllowed ||
    missingRoomOrderDetails ||
    foodOrdersDisabled ||
    (!pickupAllowed && deliveryType === 'pickup')

  useEffect(() => {
    setNameValid(validateText(minNameLen, firstName))
    setLastNameValid(validateText(minNameLen, lastName))
    setPhoneValid(validateDigits(minPhoneLen, phoneNumber))

    if (!deliveryEnabled) {
      setDeliveryType('pickup')
    }
    if (!pickupEnabled) {
      setDeliveryType('room')
    }
  }, [firstName, lastName, phoneNumber, tableType, deliveryEnabled, pickupEnabled, setDeliveryType])

  useEffect(() => {
    if (availablePaymentMethods.length === 1) {
      setRoomServiceCustomerDetails({...roomServiceCustomerDetails, paymentMethod: availablePaymentMethods[0]})
    }
  }, [availablePaymentMethods, roomServiceCustomerDetails])

  const handleSubmitOrder = () => {
    const portion = deliveryPortion(t, deliveryType, deliveryFee)
    const items = [{portion}]
    cartModify({
      items,
      successCallback: () => submitOrder({nickName: firstName, lastName, phoneNumber, loyaltyProgram, paymentMethod}),
    })
  }

  useEffect(() => {
    if (restaurantName && tableID) {
      triggerEvent({
        event: AnalyticsEvents.MAKE_ORDER,
        action: AnalyticsActions.SUMMARY_OPEN,
        restaurant_name: restaurantName,
        table: tableID,
        table_type: tableType,
      })
    }
  }, [tableID, restaurantName, tableType])

  useEffect(() => {
    if (productTotals && products && triggerEventNew && !viewCartEventTriggered.current) {
      triggerEventNew({
        event: 'view_cart',
        ecommerce: {
          value: productTotals.normal / 100,
          currency: 'EUR',
          items: products.map((product) => ({...mapPortionToEventItem(product.portion), quantity: product.quantity})),
        },
      })
      // Make sure we don't trigger a new view_cart event when mutating the cart
      viewCartEventTriggered.current = true
    }
  }, [productTotals, products, triggerEventNew])

  useEffect(() => {
    if (navigateToMenu) {
      navigate(NavigationRoute.Menu)
    }

    return () => {
      setNavigateToMenu(false)
    }
  }, [navigateToMenu, navigate])

  return (
    <SummaryWrapper>
      <Title variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase' data-testid='summary-title'>
        {t('summary.title')}
      </Title>
      <Description variant='body' sizing='small' data-testid='summary-description-cart-empty'>
        {t('summary.descriptionPickupOrRoomservice')}
      </Description>
      <SummaryItems products={products} />
      <SummaryTotalContainer>
        <SummaryTotalRow>
          <SummaryTotalText data-testid='summary-order-total' variant='body' sizing='small' weight='bold'>
            {t('summary.orderTotal')}
          </SummaryTotalText>
          <SummaryTotalText data-testid='summary-total-price' variant='body' sizing='small' weight='bold'>
            {totalPrice}
          </SummaryTotalText>
        </SummaryTotalRow>
      </SummaryTotalContainer>
      {!roomServiceClosed && (
        <>
          <CartRecommendations />
          <SectionContainer>
            <Title data-testid='summary-title' variant='heading' sizing='xxsmall' weight='medium'>
              {t('summary.orderInfo')}
            </Title>
          </SectionContainer>
          <Form>
            <SummaryFormRow>
              <InputFieldLabel sizing='small' htmlFor='roomNumber'>
                {t('summary.roomNumber')}
              </InputFieldLabel>
              <Text variant='heading' sizing='xxxsmall' id='roomNumber'>
                {tableID}
              </Text>
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='first-name-input-label' sizing='small' htmlFor='firstNameContainer'>
                {t('summary.firstNameLabel')}
              </InputFieldLabel>
              <InputField
                value={firstName ?? ''}
                sizing='small'
                id='firstNameContainer'
                aria-required
                onChange={(e) => onEditCustomerDetails('firstName', e.target.value)}
                onBlur={() => setFirstNameVisited(true)}
                data-testid='first-name-input'
                autoFocus
              />
              {firstNameVisited && !nameValid && (
                <InputFieldHelperText
                  data-testid='first-name-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.firstNameError', {length: minNameLen})}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='last-name-input-label' sizing='small' htmlFor='lastNameContainer'>
                {t('summary.lastNameLabel')}
              </InputFieldLabel>
              <InputField
                value={lastName ?? ''}
                sizing='small'
                id='lastNameContainer'
                aria-required
                onChange={(e) => onEditCustomerDetails('lastName', e.target.value)}
                onBlur={() => setLastNameVisited(true)}
                data-testid='last-name-input'
                error={lastNameVisited && !lastNameValid}
              />
              {lastNameVisited && !lastNameValid && (
                <InputFieldHelperText
                  data-testid='last-name-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.lastNameError', {length: minNameLen})}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow>
              <InputFieldLabel data-testid='phone-number-input-label' sizing='small' htmlFor='phoneNumberContainer'>
                {t('summary.phoneNumberLabel')}
              </InputFieldLabel>
              <InputField
                value={phoneNumber ?? ''}
                sizing='small'
                id='phoneNumberContainer'
                aria-required
                onChange={(e) => onEditCustomerDetails('phoneNumber', e.target.value)}
                onBlur={() => setPhoneVisited(true)}
                data-testid='phone-number-input'
                error={phoneVisited && !phoneValid}
              />
              {phoneVisited && !phoneValid && (
                <InputFieldHelperText
                  data-testid='phone-number-input-error'
                  sizing='small'
                  color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
                >
                  {t('summary.phoneError', {length: minPhoneLen})}
                </InputFieldHelperText>
              )}
            </SummaryFormRow>
            <SummaryFormRow grid={spacingCustom.medium}>
              <DeliveryMethodSelector>
                <Text variant='heading' sizing='xxxsmall' weight='medium'>
                  <Trans i18nKey={deliveryChoice ? 'summary.deliveryChoice' : 'summary.deliveryNoChoice'} ns='order' />
                </Text>
                {pickupEnabled && (
                  <>
                    <RadioButton
                      value='asap'
                      label={
                        <Text variant='body' sizing='small' weight={deliveryType === 'pickup' ? 'medium' : 'regular'}>
                          {t('summary.deliveryPickup')}
                        </Text>
                      }
                      id='delivery-pickup'
                      name='delivery-pickup'
                      checked={deliveryType === 'pickup'}
                      onChange={() => setDeliveryType('pickup')}
                      data-testid='delivery-pickup'
                      disabled={!pickupAllowed}
                    />
                    {!pickupAllowed && (
                      <PickupDisclaimer
                        data-testid='alcohol-delivery-disclaimer'
                        variant='body'
                        sizing='xsmall'
                        weight='bold'
                      >
                        <IconStatusWarning color={color.element.error} />
                        {t('summary.alcoholDeliveryDisclaimer')}
                      </PickupDisclaimer>
                    )}
                  </>
                )}
                {deliveryEnabled && (
                  <RadioButton
                    id='delivery-room'
                    name='delivery-room'
                    type='radio'
                    data-testid='delivery-room'
                    checked={deliveryType === 'room'}
                    onChange={() => setDeliveryType('room')}
                    value='asap'
                    label={
                      <Text variant='body' sizing='small' weight={deliveryType === 'room' ? 'medium' : 'regular'}>
                        {t('summary.deliveryRoom', {price: formatPricePlainText(deliveryFee ?? 0)})}
                      </Text>
                    }
                  />
                )}
                <PreparationTimeDisclaimer variant='body' sizing='small' data-testid='delivery-room-total-delay'>
                  {t('summary.deliveryRoomDelayNotification', {delay: totalRoomServiceDelay})}
                </PreparationTimeDisclaimer>
              </DeliveryMethodSelector>
              <LoyaltyProgramSelection
                availablePrograms={availableLoyaltyProgramOptions}
                selectedValue={loyaltyProgram}
                onChange={(value) => onEditCustomerDetails('loyaltyProgram', value)}
              />
              <PaymentMethodSelection
                availableMethods={availablePaymentMethods}
                selectedValue={paymentMethod}
                onChange={(value) => onEditCustomerDetails('paymentMethod', value)}
              />
            </SummaryFormRow>
            <SendButtonWrapper>
              <Button
                disabled={submitIsDisabled}
                data-testid='send-order-button'
                color='neutral'
                variant='filled'
                sizing='medium'
                rounding='small'
                onClick={handleSubmitOrder}
                icon={IconNavigationArrowRight}
                iconPos='after'
              >
                {t('summary.sendOrder')}
              </Button>
            </SendButtonWrapper>
            <Text variant='body' sizing='small'>
              {t('summary.termsHeader')}{' '}
              <TermsLink href={t('summary.termsLink') ?? ''} target='_blank' rel='noreferrer'>
                {t('summary.termsLinkText')}
              </TermsLink>
            </Text>
          </Form>
        </>
      )}
    </SummaryWrapper>
  )
}

export default SummaryRoomService
