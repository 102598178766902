import {useReactiveVar} from '@apollo/client'

import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {useEffect, useRef} from 'react'
import {useInView} from 'react-intersection-observer'
import {topNotificationsHeight} from 'state/reactiveVariables'
import {MenuSections, Menus} from 'types/restaurantTypes'
import {mapPortionToEventItem} from '../../utils/analytics'
import {
  CategoryLink,
  CategoryList,
  CategoryNavigation,
  CategoryNavigationFixed,
  CategoryPill,
  Scroller,
} from './OrderStyles'

type CategoryNavigationsProps = {
  menus: Menus
  scrollFieldVisible?: boolean
  language: string
  handleSetActive: (activate: string) => void
  activeSection: string
  hidden?: boolean
  offset?: number
}

const ScrollField = ({categoryName, activeSection}: {categoryName: string; activeSection: string}) => {
  const fieldRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (activeSection === categoryName && fieldRef.current) {
      fieldRef.current?.scrollIntoView({inline: 'center'})
    }
  }, [activeSection, categoryName])
  return <div ref={fieldRef} />
}

const AllMenuItems = ({
  menus,
  activeSection,
  scrollFieldVisible,
  handleSetActive,
  language,
  hidden = false,
  offset,
}: CategoryNavigationsProps) => {
  const triggerEventNew = useTriggerEventNew()

  if (!menus) {
    return null
  }

  const triggerViewItemList = (menuSection: MenuSections[number]) => {
    triggerEventNew?.({
      event: 'view_item_list',
      ecommerce: {
        items: menuSection.portions.map((portion) => ({
          ...mapPortionToEventItem(portion),
          item_list_name: menuSection.name.fi,
        })),
      },
    })
  }

  const useOffset = offset ?? 0

  return (
    <CategoryList sizing='small'>
      {menus.map(({menuSections}, menuIndex: number) =>
        menuSections?.map((menuSection, menuSectionIndex: number) => {
          const categoryName = `menu-${menuIndex}-section-${menuSectionIndex}`
          return (
            <CategoryPill
              sizing='small'
              color='neutral'
              rounding='small'
              variant={categoryName === activeSection ? 'filled' : 'tonal'}
              key={`category-list-${menuSectionIndex}`}
              checked={categoryName === activeSection}
              showCheckmark={false}
            >
              <CategoryLink
                isDynamic
                to={categoryName}
                spy
                smooth
                duration={300}
                offset={-40 - useOffset}
                onSetActive={handleSetActive}
                role='link'
                aria-hidden={hidden}
                onClick={() => triggerViewItemList(menuSection)}
                selected={categoryName === activeSection}
              >
                {getLocalized(menuSection.name, language)}
                {!!scrollFieldVisible && <ScrollField categoryName={categoryName} activeSection={activeSection} />}
              </CategoryLink>
            </CategoryPill>
          )
        })
      )}
    </CategoryList>
  )
}

const OrderCategoryNavigation = ({
  menus,
  language,
  handleSetActive,
  activeSection,
}: Omit<CategoryNavigationsProps, 'scrollFieldVisible'>) => {
  const {ref, inView} = useInView({
    threshold: 0,
    initialInView: true,
    fallbackInView: true,
  })

  const notificationsHeight = useReactiveVar(topNotificationsHeight) ?? 0

  if (!menus || menus.length === 0) {
    return null
  }

  return (
    <>
      <CategoryNavigation ref={ref} data-testid='category-navigation-top'>
        <AllMenuItems
          menus={menus}
          language={language}
          activeSection={activeSection}
          handleSetActive={handleSetActive}
          offset={notificationsHeight}
        />
      </CategoryNavigation>
      <Scroller top={notificationsHeight}>
        <CategoryNavigationFixed hide={inView} data-testid='category-navigation-fixed'>
          <AllMenuItems
            menus={menus}
            language={language}
            activeSection={activeSection}
            handleSetActive={handleSetActive}
            scrollFieldVisible
            hidden={inView}
            offset={notificationsHeight}
          />
        </CategoryNavigationFixed>
      </Scroller>
    </>
  )
}

export default OrderCategoryNavigation
