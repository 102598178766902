import {Modal as ModalComponent} from '@s-group/design-system-components'
import {ModalContent} from './ModalStyles'
import {ModalProps} from './modalTypes'

const Modal = (props: ModalProps): JSX.Element => {
  const {isOpen, children, onClose, testId} = props

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <ModalComponent
      rounding='rounded'
      sizing='medium'
      width='medium'
      data-testid={testId}
      open={isOpen}
      closeOnOutsideClick={true}
      onClose={handleOnClose}
    >
      <ModalContent>{children}</ModalContent>
    </ModalComponent>
  )
}

export default Modal
