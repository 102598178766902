import {
  IconNavigationChevronDown,
  IconNavigationChevronLeft,
  IconNavigationChevronRight,
  IconNavigationChevronUp,
} from '@s-group/design-system-icons'
import {HTMLAttributes} from 'react'

export enum ChevronOrientation {
  UP = 'up',
  RIGHT = 'right',
  DOWN = 'down',
  LEFT = 'left',
}

interface ChevronProps extends HTMLAttributes<SVGElement> {
  orientation: ChevronOrientation
  color: string
  size: number | string
}

export const Chevron = ({orientation, color, size, ...props}: ChevronProps) => {
  switch (orientation) {
    case ChevronOrientation.RIGHT:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <IconNavigationChevronRight color={color} height={size} width={size} {...props} />
    case ChevronOrientation.DOWN:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <IconNavigationChevronDown color={color} height={size} width={size} {...props} />
    case ChevronOrientation.LEFT:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <IconNavigationChevronLeft color={color} height={size} width={size} {...props} />
    case ChevronOrientation.UP:
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <IconNavigationChevronUp color={color} height={size} width={size} {...props} />
  }
}
