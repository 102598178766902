import {Text} from '@s-group/design-system-components'
import styled from 'styled-components'

/**
 * Horizontal list of badges.
 *
 * @example
 * <BadgeList>
 *   <Badge>Gluten free</Badge>
 *   <Badge>Vegetarian</Badge>
 * </BadgeList>
 */
export const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${({theme}) => theme.spacing.small};
`

export const Badge = styled(Text)`
  background-color: ${({theme}) => theme.color.white};
  padding: ${({theme}) => theme.spacing.xxxxsmall} ${({theme}) => theme.spacingCustom.xxsmall};
`
