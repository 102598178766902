import {useReactiveVar} from '@apollo/client'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import Header from 'Components/Header/Header'
import {Hero} from 'Components/Hero'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner'
import useHotelData from 'hooks/useHotelData'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useState} from 'react'
import {previousCartIDsVar, restaurantSelectionTableTokens} from 'state/reactiveVariables'
import {Main, MainContent} from 'styles/GeneralStyles'
import {color} from 'theme'
import {setCartId} from 'utils/cart'
import {remoteCartID, remoteRestaurantSelectionToken} from 'utils/constants'
import View404 from 'views/404/404'
import {LoggedOut} from 'views/LoggedOut'
import RestaurantMainContent from './RestaurantMainContent'
import RestaurantSelectionMainContent from './RestaurantSelectionMainContent'
import RoomMainContent from './RoomMainContent'
import RoomNumberPrompt from './RoomNumberPrompt'
import TakeawayMainContent from './TakeawayMainContent'

/**
 * Main view of the app. This is the view that the user sees after scanning
 * the QR code.
 */
const Remote = (): JSX.Element => {
  const [qrCodeReadSuccessfully, setQrCodeReadSuccessfully] = useState<boolean>(false)
  const {
    restaurantFeatures,
    restaurantFeaturesLoading,
    tableData,
    tableLoading,
    restaurantData,
    restaurantLoading,
    cartLoading,
    cartData,
  } = useRestaurantContext()

  const {hotelData, hotelLoading} = useHotelData(tableData?.getTable.restaurantID)
  const previousCartIDs = useReactiveVar(previousCartIDsVar)

  if (tableData && !qrCodeReadSuccessfully) {
    setQrCodeReadSuccessfully(true)
  }

  if (
    (restaurantLoading && !restaurantData) ||
    (hotelLoading && !hotelData) ||
    (tableLoading && !tableData) ||
    (restaurantFeaturesLoading && !restaurantFeatures) ||
    (cartLoading && !cartData)
  ) {
    return <LoadingScreen />
  }

  if (!tableData && qrCodeReadSuccessfully) {
    return <LoggedOut restaurantData={restaurantData} hotelData={hotelData} />
  }

  if (!tableData) {
    return <View404 />
  }

  const {token, tableID, tableType, restaurantSelection} = tableData.getTable

  const siteDataAvailableForRestaurantSelectionTable =
    tableType === TableType.RESTAURANT_SELECTION && (hotelData || (restaurantData && restaurantFeatures))
  const siteDataAvailableForNormalTable =
    tableType !== TableType.RESTAURANT_SELECTION && restaurantData && restaurantFeatures
  if (!siteDataAvailableForRestaurantSelectionTable && !siteDataAvailableForNormalTable) {
    return <View404 />
  }

  if (tableType === TableType.RESTAURANT_SELECTION) {
    localStorage.setItem(remoteRestaurantSelectionToken, token)
    restaurantSelectionTableTokens(restaurantSelection.map((restaurant) => restaurant.tableToken))
  }

  if (!token && tableType !== TableType.RESTAURANT_SELECTION) {
    localStorage.removeItem(remoteRestaurantSelectionToken)
  }

  const currentCartID = localStorage.getItem(remoteCartID)
  const previousRestaurantIDs = previousCartIDs.flatMap((id) => Object.keys(id))
  const restaurantID = restaurantData?.getRestaurant?.id

  if (token && currentCartID && restaurantID && previousRestaurantIDs.includes(restaurantID)) {
    const restaurantPreviousCartID = Object.values(
      previousCartIDs.find((id) => Object.keys(id).includes(restaurantID)) ?? {}
    )[0]

    if (restaurantPreviousCartID) {
      setCartId(restaurantPreviousCartID)
    }
  }

  const hasRestaurantSelectionToken = !!localStorage.getItem(remoteRestaurantSelectionToken)

  // TODO: use routes instead of this switch(?)
  let mainContent: JSX.Element
  switch (tableType) {
    case TableType.OPEN_ROOM:
      // If table type is OPEN_ROOM, it means the user has scanned a general QR
      // code and needs to enter a room number. After the user has entered the
      // room number and retrieved the "real" table data (tableType = ROOM),
      // they will see the RoomMainContent component.
      mainContent = <RoomNumberPrompt openToken={token} restaurantFeatures={restaurantFeatures!} />
      break
    case TableType.ROOM:
      // this is the content shown after user has entered the room number
      mainContent = (
        <RoomMainContent restaurantData={restaurantData!} tableID={tableID} restaurantFeatures={restaurantFeatures!} />
      )
      break
    case TableType.RESTAURANT_SELECTION:
      // User scanned a code that is connected to a hotel with multiple
      // restaurants. Show the restaurant selection view.
      mainContent = (
        <RestaurantSelectionMainContent restaurantSelection={tableData.getTable.restaurantSelection ?? []} />
      )
      break
    case TableType.PICKUP:
      // User scanned a code that is connected to a takeaway table.
      // Show the TakeawayMainContent or redirect to menu view if no orders have been placed.
      mainContent = <TakeawayMainContent restaurantData={restaurantData!} restaurantFeatures={restaurantFeatures!} />
      break
    default:
      // this covers all the restaurant table types (general, terrace, bar, etc)
      mainContent = (
        <RestaurantMainContent
          restaurantData={restaurantData!}
          restaurantFeatures={restaurantFeatures}
          tableData={tableData}
        />
      )
  }

  return (
    <Main backgroundColor={color.background.strongerGrey}>
      <NotificationBanner />
      <Header isOrderFlow={hasRestaurantSelectionToken && tableType !== TableType.RESTAURANT_SELECTION} />
      <Hero restaurantData={restaurantData} hotelData={hotelData} />
      <MainContent>{mainContent}</MainContent>
    </Main>
  )
}

export default Remote
