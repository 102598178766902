import {Language, remoteLanguages} from '@marketplace/shared-lib/src/types/language'
import {useTranslation} from 'react-i18next'
import * as Sentry from '@sentry/react'
import {SentryTagKeys} from 'utils/sentryUtils'
import {LanguageButtonSelection, LanguageButtonsWrapper} from './LanguageButtonStyles'

const LanguageButtons = () => {
  const {i18n} = useTranslation()
  const languagesWithoutCurrentLanguage = remoteLanguages.filter((language) => language !== i18n.language)

  const changeLanguage = async (language: Language) => {
    // save the language to local storage
    window.localStorage.setItem('i18nextLng', language)
    await i18n.changeLanguage(language)
    Sentry.setTag(SentryTagKeys.Language, language)
    try {
      window?.UC_UI?.updateLanguage(language)
    } catch {
      console.warn('no UC_UI')
    }
  }

  return (
    <LanguageButtonsWrapper>
      {languagesWithoutCurrentLanguage.map((language) => (
        <LanguageButtonSelection
          aria-label='language'
          key={language}
          color='primary'
          sizing='small'
          rounding='small'
          variant='outlined'
          onClick={() => changeLanguage(language)}
          data-testid={`welcome-dialog-language-button-${language}`}
        >
          {language}
        </LanguageButtonSelection>
      ))}
    </LanguageButtonsWrapper>
  )
}

export default LanguageButtons
