import type {GetHotelQuery, GetRestaurantQuery} from '@marketplace/shared-lib/graphql/graphql'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {useTranslation} from 'react-i18next'
import {HeroArea, HeroBlender, HeroWrapper, Logo, LogoContainer, LogoContainerWithBackground} from './HeroStyles'

export const heroImageTestId = 'hero-image'
export const heroLogoTestId = 'hero-logo'

type HeroProps = {
  restaurantData?: GetRestaurantQuery
  hotelData?: GetHotelQuery['getHotel']
}

/**
 * Hero image for restaurant or hotel.
 */
export const Hero = ({restaurantData, hotelData}: HeroProps) => {
  const {i18n} = useTranslation()
  const locale = i18n.language

  const {visualContent} = restaurantData?.getRestaurant ?? {}
  const restaurantLogo = visualContent?.logo?.url
  const restaurantLogoAlt = getLocalized(visualContent?.logo?.text, locale)
  let heroImage = visualContent?.hero?.url
  let heroImageAlt = getLocalized(visualContent?.hero?.text, locale)

  // if no logo or image for restaurant, use hotel data
  let hotelLogo: string | undefined
  let hotelLogoAlt: string | undefined
  if (!restaurantLogo) {
    hotelLogo = hotelData?.negativeLogoImage?.url ?? hotelData?.logoImage?.url
    if (hotelLogo && !hotelLogo?.match(/\.webp$/)) {
      hotelLogo += '.webp'
    }
    hotelLogoAlt = getLocalized(hotelData?.name, locale)
  }
  if (!heroImage) {
    heroImage = hotelData?.heroImage?.url
    heroImageAlt = getLocalized(hotelData?.name, locale)
  }
  // strip out possible file extension
  heroImage = heroImage?.replace(/\.(jpg|webp|jpeg|png)$/, '')

  return (
    <HeroWrapper>
      <HeroArea data-testid={heroImageTestId}>
        <source media='(min-width: 600px)' srcSet={`${heroImage}.webp?width=1100`} type='image/webp' />
        <source media='(min-width: 100px)' srcSet={`${heroImage}.webp?width=900`} type='image/webp' />
        <source media='(min-width: 600px)' srcSet={`${heroImage}.jpg?width=1100`} type='image/jpeg' />
        <source media='(min-width: 100px)' srcSet={`${heroImage}.jpg?width=900`} type='image/jpeg' />
        <img
          srcSet={`${heroImage}.jpg 769w,
          ${heroImage}.jpg 768w,
          ${heroImage}.jpg 500w`}
          sizes='(min-width: 769px) 769px,
            (max-width: 768px) 768px,
            (max-width: 500px) 500px'
          src={`${heroImage}.jpg`}
          alt={heroImageAlt ?? ''}
        />
      </HeroArea>
      <HeroBlender />
      {restaurantLogo && (
        <LogoContainerWithBackground>
          <Logo data-testid={heroLogoTestId} src={restaurantLogo} alt={restaurantLogoAlt} />
        </LogoContainerWithBackground>
      )}
      {!restaurantLogo && hotelLogo && (
        <LogoContainer>
          <Logo data-testid={heroLogoTestId} src={hotelLogo} alt={hotelLogoAlt} />
        </LogoContainer>
      )}
    </HeroWrapper>
  )
}
