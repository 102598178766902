import {useEffect, useRef} from 'react'
import {mapPortionToEventItem} from 'utils/analytics'
import {PortionFieldsFragment} from '@marketplace/shared-lib/graphql/graphql'
import {EventItemContext} from 'types/analyticsTypesNew'
import useTriggerEventNew from './useTriggerEventNew'

const useTriggerViewPromotionEvent = (portions: PortionFieldsFragment[], analytics: EventItemContext) => {
  const triggerEventNew = useTriggerEventNew()
  const viewPromotionEventTriggered = useRef(false)

  useEffect(() => {
    if (portions.length > 0 && triggerEventNew && !viewPromotionEventTriggered.current) {
      // view_promotion event only supports single item in list, thereby sending multiple events
      portions.forEach((portion) => {
        triggerEventNew({
          event: 'view_promotion',
          ecommerce: {
            items: [{...mapPortionToEventItem(portion), ...analytics}],
          },
        })
      })
      viewPromotionEventTriggered.current = true
    }
  }, [portions, triggerEventNew, analytics])
}

export default useTriggerViewPromotionEvent
