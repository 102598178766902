import {IconNavigationClose} from '@s-group/design-system-icons'
import {useEffect} from 'react'
import FocusLock from 'react-focus-lock'
import {useModalFeatures} from '../../hooks'
import {ModalBottomCloseButton, ModalBottomContentWrapper, StyledModalBottom} from './ModalStyles'
import {ModalProps} from './modalTypes'

const ModalBottom = (props: ModalProps) => {
  const {
    isOpen,
    noPadding,
    setIsOpen,
    small = false,
    children,
    showCloseButton = true,
    maxWidth,
    onClose,
    background,
  } = props
  const {handleClose, afterOpen, animate} = useModalFeatures({setIsOpen, onClose})

  useEffect(() => {
    if (isOpen) {
      afterOpen()
    }
  }, [isOpen])

  return (
    <StyledModalBottom
      position='bottom'
      small={small}
      open={isOpen}
      maxWidth={maxWidth}
      background={background}
      animate={animate}
      closeOnOutsideClick
      onClose={handleClose}
      noPadding={noPadding}
    >
      <FocusLock autoFocus={isOpen}>
        {showCloseButton && (
          <ModalBottomCloseButton
            color='neutral'
            variant='filled'
            sizing='small'
            rounding='pill'
            onClick={handleClose}
            data-testid='modal-close-button'
            iconOnly
          >
            <IconNavigationClose aria-label='close-modal' size='24' />
          </ModalBottomCloseButton>
        )}
        <ModalBottomContentWrapper>{children}</ModalBottomContentWrapper>
      </FocusLock>
    </StyledModalBottom>
  )
}

export default ModalBottom
