import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {areGlobalTableActionsEnabled} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Text} from '@s-group/design-system-components'
import {ActionButton} from 'Components/Product/ProductStyles'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {getCartValues} from 'utils/cart'
import {ButtonWrapper, CartSummary, CartSummaryItems, FloatingCartContainer, StatusWrapper} from './FloatingCartStyles'

const {Menu, Home, Summary} = NavigationRoute

const FloatingCart = () => {
  const {restaurantFeatures, cartData, restaurantData, tableData} = useRestaurantContext()
  const {t} = useTranslation('order')
  const {pathname, search} = useLocation()
  const navigate = useNavigate()
  const [handleNavigate, setHandleNavigate] = useState(false)

  const isRootPath = pathname === Home.toString()

  // Show floating cart only on Home and Menu pages,
  // and when table data is available: Session is active
  const showComponent = useMemo(() => {
    const validPaths = [Home, Menu]
    return validPaths.some((path) => path.toString() === pathname) && tableData
  }, [pathname, tableData])

  const {totalCount, totalPrice} = getCartValues(cartData?.getCart)
  const currentTableType = tableData?.getTable.tableType

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions: restaurantFeatures?.tableActions,
    tableActionsAutomatic: restaurantFeatures?.tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer: restaurantFeatures?.lastCallBuffer,
  })

  const roomServiceClosed =
    (currentTableType === TableType.OPEN_ROOM || currentTableType === TableType.ROOM) &&
    !restaurantFeatures?.roomService.deliveryEnabled &&
    !restaurantFeatures?.roomService.pickupEnabled

  // Do not show floating cart if portion details is open
  // Floating cart blocks add-to-cart button
  const hasPortionParam = new URLSearchParams(search).has('portion')

  const showCart =
    !hasPortionParam &&
    (restaurantFeatures?.quickOrder || currentTableType === TableType.ROOM) &&
    tableActionsEnabled &&
    !roomServiceClosed &&
    showComponent &&
    totalCount > 0

  useEffect(() => {
    if (handleNavigate) {
      if (pathname === Home.toString()) {
        navigate(Menu)
      } else {
        navigate(Summary)
      }
    }

    return () => {
      setHandleNavigate(false)
    }
  }, [navigate, handleNavigate, pathname])

  if (!showCart) return null

  return (
    <FloatingCartContainer data-testid='floating-cart-container'>
      <CartSummary variant='body' sizing='xsmall' transform='caption'>
        {t('cart.cart')}:
        <CartSummaryItems>
          <StatusWrapper data-testid='floating-cart-total-item-count'>
            <Text variant='body' sizing='medium' weight='bold'>
              {totalCount}{' '}
            </Text>
            <Text variant='body' sizing='medium' weight='light'>
              {totalCount === 1 ? t('cart.item') : t('cart.items')}:
            </Text>
          </StatusWrapper>
          <Text variant='body' sizing='medium' weight='bold' data-testid='floating-cart-total-price'>
            {totalPrice}
          </Text>
        </CartSummaryItems>
      </CartSummary>
      <ButtonWrapper>
        <ActionButton
          rounding='small'
          variant='tonal'
          color='neutral'
          sizing='small'
          onClick={() => setHandleNavigate(true)}
          data-testid='go-to-cart'
          paddingSide='1rem'
        >
          {t(isRootPath ? 'cart.continueOrder' : 'cart.goToCart')}
        </ActionButton>
      </ButtonWrapper>
    </FloatingCartContainer>
  )
}

export default FloatingCart
