import {medium} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Text} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ListItemContainer = styled.article`
  margin: 0;
  padding: 0 ${medium};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: ${medium};
`

export const FoodImage = styled.img`
  flex: 0; // image should not grow
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover; // fill the area while keeping aspect ratio
`

export const TextContainer = styled.div`
  flex: 1; // text should grow and take up all available space
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({theme}) => theme.spacing.xsmall};
`

export const RestaurantName = styled(Text)`
  text-align: left;
`
