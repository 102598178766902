import {PriceType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import {RadioButtonLabel, RadioButtonLabelExtraInfo, RadioButtonWrapper} from './SelectionBoxStyles'
import {RadioButtonProps} from './SelectionBoxTypes'

const RadioButton = ({
  id,
  label,
  labelExtraInfo,
  name,
  value,
  onChange,
  checked,
  disabled = false,
  portionId,
  coopMemberPrice,
  priceType,
}: RadioButtonProps) => {
  const buttonId = portionId ? `${portionId}-${id}` : id
  const formatSignDisplay = priceType === PriceType.ADDITIONAL

  return (
    <RadioButtonWrapper
      sizing='xsmall'
      id={buttonId}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-testid={buttonId}
      labelProps={{'data-testid': `${buttonId}-label`}}
    >
      <RadioButtonLabel>
        <Text variant='body' sizing='small'>
          {label}
        </Text>
        {labelExtraInfo && (
          <RadioButtonLabelExtraInfo>
            <Text variant='body' sizing='small'>
              {`, ${labelExtraInfo}`}
            </Text>
            <CoopMemberPrice
              alignItems='center'
              textSize='small'
              coopMemberPrice={coopMemberPrice}
              logoSize='1rem'
              formatSignDisplay={formatSignDisplay}
            />
          </RadioButtonLabelExtraInfo>
        )}
      </RadioButtonLabel>
    </RadioButtonWrapper>
  )
}

export default RadioButton
