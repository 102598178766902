import {useEffect} from 'react'
import TagManager from 'react-gtm-module'
import config from 'config'
import {useHasServiceConsent} from '@s-group/react-usercentrics'

export const useGTM = () => {
  const googleTagManagerHasConsent = useHasServiceConsent(config.GTM_ID_FOR_USERCENTRICS)
  useEffect(() => {
    if (config.GTM && googleTagManagerHasConsent) {
      TagManager.initialize({gtmId: 'GTM-W4H5MJD'})
    }
  }, [googleTagManagerHasConsent])
}
