import PortionOptionsString from '@marketplace/shared-lib/src/components/PortionOptionsString'
import {deliveryPortionPickup, deliveryPortionRoom} from '@marketplace/shared-lib/src/utils/cartUtils'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {getPortionOptionsText} from '@marketplace/shared-lib/src/utils/portionOptions'
import {getPortionSize} from '@marketplace/shared-lib/src/utils/portionSize'
import {Text} from '@s-group/design-system-components'
import {IconCommunicationMessage} from '@s-group/design-system-icons'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import config from 'config'
import {useTranslation} from 'react-i18next'
import {OrderProps} from 'utils/order'
import {
  AdditionInfo,
  Multiplier,
  OrderHeading,
  OrderSummaryRow,
  OrderSummaryWrapper,
  PortionOptionWrapper,
  PriceRow,
  QuantityAndUnitPriceContainer,
  TotalRowFont,
} from './ThankYouStyles'

const OrderConfirmation = ({order}: {order: OrderProps}) => {
  const {products, productTotals, formattedDateString} = order
  const totalPrice = formatPrice({price: productTotals?.normal ?? 0})
  const {t} = useTranslation('order')
  const {i18n} = useTranslation()
  const currentLanguage = i18n.language

  return (
    <OrderSummaryWrapper>
      <OrderHeading>
        <Text variant='heading' sizing='xxsmall' weight='bold'>
          {t('confirmation.order')}
        </Text>
        <Text variant='heading' sizing='xxsmall'>
          {formattedDateString}
        </Text>
      </OrderHeading>
      {products?.map(({quantity, portion, rowTotal, unitPrice, additionalInformation}, index) => {
        const itemSum = formatPrice({price: rowTotal.normal})
        const itemPrice = formatPrice({price: unitPrice.normal})
        const portionName = getLocalized(portion.name, i18n.language)
        const portionSize = getPortionSize(portion, currentLanguage)
        const portionOptions = getPortionOptionsText(portion, currentLanguage)

        return (
          <OrderSummaryRow borderTop='small' key={`${portion.id}${index}`} data-testid={portion.id}>
            {portion.id !== deliveryPortionRoom && portion.id !== deliveryPortionPickup && (
              <Text variant='body' sizing='small' weight='bold'>
                {portionName} {portionSize}
              </Text>
            )}

            {!!portionOptions.length && (
              <PortionOptionWrapper>
                <PortionOptionsString
                  portionOptions={portionOptions}
                  showPortionOptionName={config.SHOW_PORTION_OPTION_NAME}
                />
              </PortionOptionWrapper>
            )}

            <QuantityAndUnitPriceContainer>
              {portion.id !== deliveryPortionRoom && portion.id !== deliveryPortionPickup ? (
                <PriceRow>
                  <Text variant='body' sizing='small' weight='bold'>
                    {quantity}
                  </Text>
                  <Multiplier variant='body' sizing='small'>
                    x
                  </Multiplier>
                  <Text variant='body' sizing='small' weight='bold'>
                    {itemPrice}
                  </Text>
                  <CoopMemberPrice
                    logoSize='0.75rem'
                    coopMemberPrice={unitPrice.coopMember}
                    testId='coop-member-price'
                    textSize='small'
                  />
                </PriceRow>
              ) : (
                <PriceRow>
                  <Text variant='body' sizing='small' weight='bold'>
                    {portionName} {portionSize}
                  </Text>
                </PriceRow>
              )}
              <Text variant='body' sizing='small' weight='bold'>
                {itemSum}
              </Text>
            </QuantityAndUnitPriceContainer>
            {additionalInformation && (
              <AdditionInfo
                data-testid='additional-info-text'
                sizing='small'
                status='warning'
                close={false}
                icon={<IconCommunicationMessage />}
              >
                <Text variant='body' sizing='small'>
                  {additionalInformation}
                </Text>
              </AdditionInfo>
            )}
          </OrderSummaryRow>
        )
      })}
      <OrderSummaryRow borderTop='large'>
        <TotalRowFont variant='body' sizing='small' weight='bold' transform='uppercase'>
          {t('summary.orderTotal')} {totalPrice}
        </TotalRowFont>
      </OrderSummaryRow>
    </OrderSummaryWrapper>
  )
}

export default OrderConfirmation
