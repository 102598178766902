import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {Text} from '@s-group/design-system-components'
import {CoopMemberPriceWrapper, SBonusIcon} from './PriceStyles'

const CoopMemberPrice = ({
  coopMemberPrice,
  logoSize = '1.5rem',
  formatSignDisplay,
  testId,
  textSize,
  alignItems,
}: {
  coopMemberPrice?: number | null
  logoSize?: string
  formatSignDisplay?: boolean
  testId?: string
  textSize?: string
  alignItems?: string
}) => {
  if (!coopMemberPrice) {
    return null
  }
  const coomMemberIconSrc = '/s-bonus-icon.svg'

  return (
    <CoopMemberPriceWrapper logoSize={logoSize} data-testid={testId && testId} alignItems={alignItems}>
      <SBonusIcon src={coomMemberIconSrc} alt='s-asiakashinta' />
      <Text variant='body' sizing={textSize} weight='bold'>
        {formatPrice({price: coopMemberPrice, signDisplay: formatSignDisplay})}
      </Text>
    </CoopMemberPriceWrapper>
  )
}

export default CoopMemberPrice
