import {useCallback, useEffect, useRef, useState} from 'react'

/**
 * Hook for detecting if network connection is down, and triggers reconnect state change if certain offline interval has exceeded.
 * @param {number} interval - interval is the threshold for when reconnected is triggered. default 10000ms (10s)
 * @returns {object} - Object with isOnline and reconnected status which resets after being true.
 */

const useNetworkConnection = (interval = 10000) => {
  const [isOnline, setIsOnline] = useState(navigator?.onLine)
  const [reconnected, setReconnected] = useState(false)
  const timeOffline = useRef<number | undefined>()
  const stateResetTimer = useRef<NodeJS.Timeout | undefined>()

  const handleOnline = useCallback(
    (event: Event) => {
      if (event.type === 'offline') {
        timeOffline.current = event.timeStamp
      }
      if (event.type === 'online' && timeOffline.current && event.timeStamp - timeOffline.current > interval) {
        setReconnected(true)
        timeOffline.current = 0
      }
      setIsOnline(event.type === 'online')
    },
    [interval]
  )

  // reset state after it has been true
  useEffect(() => {
    if (reconnected) {
      // If we change the value to true and back to false too quickly, React
      // might batch the state updates and the value might remain false the
      // whole time.
      // Let's wait a bit before resetting the state.
      clearTimeout(stateResetTimer.current)
      stateResetTimer.current = setTimeout(() => {
        setReconnected(false)
      }, 100)
    }
    return () => clearTimeout(stateResetTimer.current)
  }, [reconnected])

  useEffect(() => {
    window?.addEventListener('offline', handleOnline)
    window?.addEventListener('online', handleOnline)
    return () => {
      window?.removeEventListener('offline', handleOnline)
      window?.removeEventListener('online', handleOnline)
    }
  })

  return {isOnline, reconnected}
}

export default useNetworkConnection
