import {Menu} from '@marketplace/shared-lib/graphql/graphql'
import {useMemo} from 'react'
import {getMenusByAvailability, getOnlyDrinksMenus} from 'utils/menuUtils'
import {isRestaurantKitchenOpen} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import useRestaurantContext from './useRestaurantContext'

/**
 * Returns only the menus and portions that are available for the current table,
 * at the current time, and with the current kitchen status.
 */
const useFilteredMenus = (): Menu[] => {
  const {restaurantData, restaurantFeatures, tableData} = useRestaurantContext()
  const menus = useMemo(() => {
    const availableMenus =
      getMenusByAvailability(restaurantData?.getRestaurant.menus ?? [], tableData?.getTable.tableType) ?? []

    const isKitchenOpen = isRestaurantKitchenOpen({
      kitchenClosed: restaurantFeatures?.kitchenClosed,
      kitchenOpenAutomatic: restaurantFeatures?.kitchenOpenAutomatic,
      weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    })

    if (!isKitchenOpen) {
      return getOnlyDrinksMenus(availableMenus) ?? []
    }

    return availableMenus
  }, [
    restaurantFeatures?.kitchenOpenAutomatic,
    restaurantData?.getRestaurant.menus,
    restaurantFeatures?.kitchenClosed,
    tableData?.getTable.tableType,
    restaurantData?.getRestaurant.weekOpeningTimes,
  ])
  return menus
}

export default useFilteredMenus
