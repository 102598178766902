import {PortionFieldsFragment} from '@marketplace/shared-lib/graphql/graphql'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'

export const getPortionSize = (
  portion: Omit<PortionFieldsFragment, 'images' | 'recommendations'>,
  locale: string
): string => {
  const size = portion.portionOptionSections?.filter((optionSection) => optionSection?.type === 'SIZE')
  if (size && size.length > 0 && size[0]?.name) {
    return getLocalized(size[0]?.name, locale) || ''
  }
  return ''
}
