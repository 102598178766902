import type {RestaurantSelection} from '@marketplace/shared-lib/graphql/graphql'
import {IconStatusWarning} from '@s-group/design-system-icons'
import Divider from 'Components/Divider/Divider'
import {LoaderWrapper} from 'Components/LoadingScreen/LoadingScreenStyles'
import useRestaurantListItemLazy from 'hooks/useRestaurantListItemLazy'
import {Loader} from 'Icons'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {color, spacingCustom} from 'theme'
import type {RestaurantListItemType} from './RestaurantListItem'
import RestaurantListItem from './RestaurantListItem'
import {ContentWrapper, RestaurantSelectionDescription, SubHeader} from './RestaurantMainContentStyles'

type RestaurantSelectionMainContentProps = {
  restaurantSelection: RestaurantSelection[]
}

const RestaurantSelectionMainContent = ({restaurantSelection}: RestaurantSelectionMainContentProps) => {
  const {t} = useTranslation('remotePage')
  const getRestaurantListItem = useRestaurantListItemLazy()

  const [listItems, setListItems] = useState<RestaurantListItemType[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  // TODO: refactor this with some better single query fetch array instead of doing Promise.all queries with useEffect
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const responses = await Promise.all(
          restaurantSelection.map((data) =>
            getRestaurantListItem({variables: {input: {restaurantID: data.restaurantID}}})
          )
        )
        const newListItems: RestaurantListItemType[] = restaurantSelection
          .map((data, index) => ({
            ...data,
            restaurant: responses[index].data?.getRestaurant,
          }))
          .filter((r): r is RestaurantListItemType => !!r.restaurant)
        setError(false)
        setListItems(newListItems)
      } catch (e) {
        console.error('Error fetching restaurant list items', e)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    if (!loading && restaurantSelection.length > 0 && listItems.length === 0) {
      fetchData()
    }
  }, [restaurantSelection, getRestaurantListItem, listItems.length, loading])

  return (
    <ContentWrapper data-testid='restaurant-selection'>
      <SubHeader
        paddingtop={spacingCustom.medium}
        variant='display'
        sizing='xxxxsmall'
        weight='bold'
        transform='uppercase'
      >
        {t('restaurantSelection.welcome')}
      </SubHeader>
      <RestaurantSelectionDescription variant='body' sizing='small'>
        {t('restaurantSelection.instructions')}
      </RestaurantSelectionDescription>
      <SubHeader variant='heading' sizing='xxsmall' weight='bold'>
        {t('restaurantSelection.selectRestaurant')}
      </SubHeader>
      {loading && (
        <LoaderWrapper>
          <Loader dark />
        </LoaderWrapper>
      )}
      {!loading &&
        listItems.map((item) => (
          <React.Fragment key={item.restaurantID}>
            <Divider />
            <RestaurantListItem item={item} />
          </React.Fragment>
        ))}
      {!loading && (error || listItems.length === 0) && (
        <>
          <Divider />
          <div>
            <IconStatusWarning color={color.element.warning} />
            {t('restaurantSelection.noRestaurants')}
          </div>
        </>
      )}
    </ContentWrapper>
  )
}

export default RestaurantSelectionMainContent
