import styled from 'styled-components'

export const CoopMemberPriceWrapper = styled.span<{logoSize: string; alignItems?: string}>`
  justify-content: center;
  display: flex;
  align-items: ${({alignItems}) => alignItems || 'baseline'};
  margin-left: ${({logoSize}) => logoSize};
`

export const SBonusIcon = styled.img`
  margin-right: ${({theme}) => theme.spacing.xxxxsmall};
  align-self: center;
`
